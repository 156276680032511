import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Helmet } from 'react-helmet';

const Termsandcondition = () => {
  return (
    <>
    <Helmet>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="keywords"
          content="SMS marketing, text message campaigns, mobile marketing, customer engagement, SMS solutions"
        />
        <meta
          name="description"
          content="Explore Vilva Business's SMS marketing services. Boost customer engagement and drive results with our targeted SMS marketing strategies."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <title>Terms and Condition</title>
      </Helmet>
    <Navbar/>
   
    <div>
      <div className="container mx-auto">
  <h1 style={{ color: "#040e56", textAlign: "center", paddingTop: "90px" }}>
    Terms and Conditions
  </h1>
  <br />
  <br />
  <p>
    Welcome to Vilva Business! These Terms and Conditions ("Terms") govern your use of our digital services, including e-commerce websites similar to Shopify and WooCommerce, and APIs (the "Services"). By accessing or using our Services, you agree to comply with and be bound by these Terms. If you do not agree with these Terms, please do not use our Services.
  </p>
  <br />
  <br />

  <h3 style={{ color: "#040e56" }}>Services</h3>
  <br />
  <p>
    Vilva Business provides IT and software development services, including but not limited to e-commerce solutions, SaaS platforms, and custom software development. Our services are subject to change, and we reserve the right to modify or discontinue any aspect of our services without notice.
  </p>

  <h3 style={{ color: "#040e56" }}>User Responsibilities</h3>
  <br />
  <p>By using our services, you agree to:</p>
  <ul>
    <li>Provide accurate and up-to-date information when registering or engaging with our services.</li>
    <li>Comply with all applicable laws and regulations.</li>
    <li>Use our services for lawful purposes only and not for any fraudulent, abusive, or otherwise illegal activities.</li>
    <li>Keep your account information secure and notify us immediately of any unauthorized use.</li>
  </ul>

  <h3 style={{ color: "#040e56" }}>Intellectual Property Rights</h3>
  <br />
  <p>
    All content, trademarks, and data on this site, including but not limited to software, text, graphics, and logos, are the property of Vilva Business and are protected by intellectual property laws. You may not reproduce, distribute, or create derivative works from our content without our express written permission.
  </p>

  <h3 style={{ color: "#040e56" }}>Confidentiality</h3>
  <br />
  <p>
    Both parties agree to maintain the confidentiality of proprietary information disclosed during the course of business. Confidential information shall not be disclosed to third parties without prior consent from the disclosing party, except as required by law.
  </p>

  <h3 style={{ color: "#040e56" }}>Limitation of Liability</h3>
  <br />
  <p>
    Vilva Business shall not be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with the use of our services. Our total liability to you for any claim arising from our services shall not exceed the amount paid by you for such services.
  </p>

  <h3 style={{ color: "#040e56" }}>Indemnification</h3>
  <br />
  <p>
    You agree to indemnify and hold harmless Vilva Business, its affiliates, and employees from any claims, damages, losses, or expenses arising from your use of our services, your violation of these Terms and Conditions, or your infringement of any third-party rights.
  </p>

  <h3 style={{ color: "#040e56" }}>Termination</h3>
  <br />
  <p>
    We reserve the right to terminate or suspend your access to our services at our sole discretion, without notice, for conduct that we believe violates these Terms and Conditions or is harmful to other users of our services, us, or third parties, or for any other reason.
  </p>

  <h3 style={{ color: "#040e56" }}>Governing Law</h3>
  <br />
  <p>
    These Terms and Conditions are governed by and construed in accordance with the laws of [Insert Jurisdiction], without regard to its conflict of law principles. You agree to submit to the exclusive jurisdiction of the courts located in [Insert Jurisdiction].
  </p>

  <h3 style={{ color: "#040e56" }}>Amendments</h3>
  <br />
  <p>
    Vilva Business reserves the right to amend these Terms and Conditions at any time. Your continued use of our services after any changes to these terms constitutes your acceptance of the new Terms and Conditions.
  </p>

  <h3 style={{ color: "#040e56" }}>Contact Information</h3>
  <br />
  <p>For any questions or concerns regarding these Terms and Conditions, please contact us at:</p>

  <h5 style={{ color: "#040e56" }}>Vilva Business</h5>
  <br />
  <p>
    <span>Email:</span>
    <a href="mailto:info@vilvabusiness.com">info@vilvabusiness.com</a>
  </p>
  <p>
    <span>Phone:</span>
    <a href="tel:+919176569459">+91 91765 69459</a>
  </p>
</div>

    </div>
    <Footer/>
    </>
  )
}

export default Termsandcondition
