import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import Pagesblog from "../blog/pagesblog/Pagesblog"
import { Helmet } from 'react-helmet';

const Paymentcollection = () => {
  return (
    <div>
      <Helmet>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="keywords"
          content="Payment collection services, online payment solutions, secure payment processing, business payment services, transaction management"
        />
        <meta
          name="description"
          content="Explore Payment Collection Services by Vilva Business, offering secure and efficient solutions to streamline your payment processing and enhance your business transactions."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <title>Payment Collection Services - Vilva Business</title>
      </Helmet>
      <Navbar/>
        <section className="page-title bg-overlay-black-60 jarallax" data-speed="0.6" data-video-src="https://www.youtube.com/watch?v=vRkvdeqqDwA">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="page-title-name">
                <h1>Payment Collection Services</h1>
                <p>We know the secret of your success</p>
              </div>
              <ul className="page-breadcrumb">
                <li><a href="/"><i className="fa fa-home"></i> Home</a> <i className="fa fa-angle-double-right"></i></li>
                <li><span>Payment Collection Services</span></li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <div className="prod-des container">
        <div className="prod-head">
          <h5 style={{ color: '#4a12f0' }}>Accelerate cash flow management</h5>
          <h2 style={{ color: '#001e60' }}>Simplify payment processing efficiently</h2>
        </div>
        <div className="benifits mx-auto">
          <div className="col-md-3 col-12 ben-ai">
            <img src='https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/growsuite.png' alt="" />
            <h3 style={{ color: '#001e60' }}>Streamlined Transactions</h3>
            <p>Simplify and automate payment processes, reducing manual effort and errors.</p>
          </div>
          <div className="col-md-3 col-12 ben-ai">
            <img src='https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/growsuiteimage.png' alt="" />
            <h3 style={{ color: '#001e60' }}>Enhanced Security</h3>
            <p>Implement advanced security measures to protect sensitive payment information and prevent fraud.</p>
          </div>
          <div className="col-md-3 col-12 ben-ai">
            <img src='https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/growsuiteimages.png' alt="" />
            <h3 style={{ color: '#001e60' }}>Improved Cash Flow</h3>
            <p>Accelerate the collection process, leading to faster access to funds and better cash flow management.</p>
          </div>
        </div>
      </div>

      <div className="usecase-all">
        <div className="usecase-head">
          <h5 style={{ color: '#4a12f0' }}>Use Cases</h5>
          <h2 style={{ color: '#001e60' }}>Manage Every Endpoint in One Place</h2>
          <h5 style={{ color: '#28334a' }}>Streamline and secure transactions</h5>
        </div>
        <div className="row container pt-5 mx-auto">
          <div className="col-md-6 col-12">
            <div className="useimg">
              <img src='https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/post.jpg' alt="" style={{ height: '100%', width: '90%' }} />
            </div>
          </div>
          <div className="col-12 col-md-6 mt-5">
            <div className="row" id="row1">
              <h3>E-Commerce Transactions</h3>
              <p>Processing online payments securely and efficiently for online stores.</p>
            </div>
            <div className="row" id="row2">
              <h3>Subscription Billing</h3>
              <p>Managing recurring payments for subscription-based services or memberships.</p>
            </div>
            <div className="row" id="row3">
              <h3>Invoice Payments</h3>
              <p>Facilitating electronic payment for invoices to streamline accounts receivable.</p>
            </div>
            <div className="row" id="row4">
              <h3>Mobile Payments</h3>
              <p>Accepting payments through mobile devices and apps for on-the-go transactions.</p>
            </div>
            <div className="row" id="row5">
              <h3>Donations and Crowdfunding</h3>
              <p>Handling contributions and fundraising payments for non-profits and campaigns.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="ready mt-5 pb-5 container">
        <div className="ready-des">
          <h3 style={{ color: '#001e60' }}>Ready to Get Started?</h3>
          <h5 style={{ color: '#28334a' }}>Experience device management, made easy!</h5>
          <div className="contact-us">
            <button type="button">Contact Us <i className="fa-solid fa-arrow-right"></i></button>
          </div>
        </div>
      </div>
      <Pagesblog/>

      <Footer/>
    </div>
  )
}

export default Paymentcollection
