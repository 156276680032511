import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import Pagesblog from "../blog/pagesblog/Pagesblog"
import { Helmet } from 'react-helmet';

const Whatsappbuisness = () => {
  return (
    <div>
        <Helmet>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="keywords"
          content="WhatsApp marketing, Biz Sender Pro, WhatsApp business, messaging solutions, marketing tools"
        />
        <meta
          name="description"
          content="Discover Vilva Biz Sender Pro for effective WhatsApp marketing. Enhance your business communication and marketing strategies with our advanced WhatsApp messaging solutions."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <title>Vilva Biz Sender Pro - Vilva Business</title>
      </Helmet>
      <Navbar/>
      <div className="banner">
  <div className="heading-about">
    <div className="text-center container">
      <h1>Vilva Biz Sender Pro</h1>
      <p style={{ color: 'black', fontSize: '40px' }}>
        Vilva Biz Sender Pro is a powerful messaging solution designed to
        enhance business communication and customer engagement. With advanced
        features tailored for bulk messaging, Vilva Biz Sender Pro enables
        businesses to send personalized messages, promotional campaigns, and
        important updates to a large audience efficiently.
      </p>
    </div>
  </div>
</div>
<div className="main">
  <h3 className="head-t3">Whatsapp Business API</h3>
  <div className="container">
    <ul className="t3-ul">
      <li className="t3-li">
        <h3 className="heading-t3">Scalable Communication</h3>
        <p>
          Manage high volumes of messages efficiently and automate responses.
        </p>
        <a href="#">Read More ></a>
        <span className="date">Comprehensive tools</span>
        <span className="circle"></span>
      </li>
      <li className="t3-li">
        <h3 className="heading-t3">Enhanced Customer Support</h3>
        <p>
          Provide real-time, personalized support through a preferred messaging
          platform.
        </p>
        <a href="#">Read More ></a>
        <span className="date">Data-Driven Insights</span>
        <span className="circle"></span>
      </li>
      <li className="t3-li">
        <h3 className="heading-t3">Automated Notifications and Updates</h3>
        <p>
          Send automated messages for order confirmations, shipping updates,
          and other notifications.
        </p>
        <a href="#">Read More ></a>
        <span className="date">Scalable Solutions</span>
        <span className="circle"></span>
      </li>
      <li className="t3-li">
        <h3 className="heading-t3">Integration with CRM and Marketing Tools</h3>
        <p>
          Seamlessly integrate with existing systems for better data management
          and targeted marketing.
        </p>
        <a href="#">Read More ></a>
        <span className="date">Marketing Automation</span>
        <span className="circle"></span>
      </li>
    </ul>
  </div>
</div>
<Pagesblog/>

<Footer/>
    </div>
  )
}

export default Whatsappbuisness
