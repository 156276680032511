import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import Pagesblog from "../blog/pagesblog/Pagesblog"
import { Helmet } from "react-helmet";

const Appbotique = () => {
  return (
    <div>
          <Helmet>
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta
        name="keywords"
        content="app for boutiques, boutique app, boutique management, fashion app"
      />
      <meta
        name="description"
        content="Explore our boutique management app designed to streamline operations and enhance customer engagement. Discover how our app can empower your boutique business."
      />
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      <title>App for Boutiques - Vilva Business</title>
    </Helmet>
        <Navbar/>
      <section className="page-title bg-overlay-black-60 jarallax" data-speed="0.6" data-video-src="https://www.youtube.com/watch?v=0n6-5TFgPRA">
    <div className="container">
        <div className="row">
            <div className="col-lg-12">
                <div className="page-title-name">
                    <h1>App for Boutiques</h1>
                    <p>We know the secret of your success</p>
                </div>
                <ul className="page-breadcrumb">
                    <li><a href="/"><i className="fa fa-home"></i> Home</a> <i className="fa fa-angle-double-right"></i></li>
                    <li><span>Event Signup / Ticket Sales Marketing</span></li>
                </ul>
            </div>
        </div>
    </div>
</section>

<div className="con-layout2 container">
    <h1>App for Boutiques</h1>
    <p>Elevate boutique shopping with a custom app <br /> How can a boutique app transform your business?</p>
</div>

<div className="features-layout2">
    <div className="row mx-auto container">
        <div className="col-12 col-md-4 mt-3">
            <div className="layout-col-2 mt-3">
                <div className="top-bg"></div>
                <div className="imgforcol-test">
                    <img src="{{ asset('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/layout-1.png') }}" alt="" />
                </div>
                <h3>Enhanced Customer Engagement</h3>
                <p>An Android app for boutiques can offer personalized features such as exclusive offers, loyalty programs, and notifications about new arrivals, keeping customers engaged and encouraging repeat visits.</p>
            </div>
        </div>

        <div className="col-md-4 col-12 mt-3">
            <div className="layout-col-2 mt-3">
                <div className="top-bg-c"></div>
                <div className="imgforcol">
                    <img src="{{ asset('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/layout-2.png') }}" alt="" />
                </div>
                <h3>Streamlined Shopping Experience</h3>
                <p>With a dedicated app, boutiques can provide a seamless shopping experience, including easy browsing, secure checkout, and real-time inventory updates. This convenience can lead to increased sales and improved customer satisfaction.</p>
            </div>
        </div>

        <div className="col-md-4 col-12 mt-3">
            <div className="layout-col-2 mt-3">
                <div className="top-bg"></div>
                <div className="imgforcol-test">
                    <img src="{{ asset('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/layout-3.png') }}" alt="" />
                </div>
                <h3>Brand Visibility and Loyalty</h3>
                <p>A boutique app enhances brand visibility by keeping your business on customers' home screens. It also builds brand loyalty through features like wish lists, personalized recommendations, and special promotions, strengthening customer relationships.</p>
            </div>
        </div>
    </div>
</div>

<div className="start-project mt-5 container">
    <div className="row mx-auto">
        <div className="col-12 col-md-6 project">
            <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/project.png" alt="" className='img-fluid' />
        </div>
        <div className="col-12 col-md-6 project-des" style={{ color: '#001e60' }}>
            <div className="project-des-con">
                <h2>Unlock New Opportunities – Start Your Success Story!</h2>
                <p>Elevate your boutique business with a custom-built app. Showcase your collections, offer exclusive deals, and create a personalized shopping experience that sets you apart!</p>
                <div className="layout-btn">
                    <button type="button">
                        <a href="/contact" target="_blank" style={{ color: 'white' }}>Create Your Account</a> &nbsp;&nbsp;&nbsp; <i className="ri-arrow-right-line"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<Pagesblog/>

<Footer/>

    </div>
  )
}

export default Appbotique
