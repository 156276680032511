import React from "react";
import "./dashboard.css";

const Payment = () => {
  return (
    <div>
      <div className="route_navigation_all pe-4">
        <div className="route_navigation">
          <p>
            <span>
              Dashboard{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M7.5 15L12.5 10L7.5 5"
                  stroke="#999999"
                  stroke-width="1.25"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            Payments
          </p>
        </div>
        <div className="route_filters_all">
          <div className="route_filters">
            <p>Filter</p>
            <div className="router_icons">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M2.5 5H17.5"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5.83337 10H14.1667"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.33337 15H11.6667"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
          <div className="route_filters">
            <p>This Month</p>
            <div className="router_icons">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M5 7.5L10 12.5L15 7.5"
                  stroke="black"
                  stroke-width="1.25"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className="invoice_table">
        <div className="table-container">
          <table className="data-table">
            <thead>
              <tr>
                <th>Product ID</th>
                <th>Date</th>
                {/* <th>Product</th> */}
                <th>Transaction ID</th>
                <th>Created at</th>
                <th> Amount </th>
                <th>Status </th>
                <th>Payment Method</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>IND-12345</td>
                <td>12 Nov 2024</td>
                <td>Business Growth Software</td>
                <td>₹ 25,000</td>
                <td>₹ 25,000</td>
                <td>₹ 25,000</td>
                <td> Net Banking</td>
                <td>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M9.99984 10.8333C10.4601 10.8333 10.8332 10.4602 10.8332 10C10.8332 9.53976 10.4601 9.16667 9.99984 9.16667C9.5396 9.16667 9.1665 9.53976 9.1665 10C9.1665 10.4602 9.5396 10.8333 9.99984 10.8333Z"
                      stroke="#666666"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.99984 5C10.4601 5 10.8332 4.6269 10.8332 4.16667C10.8332 3.70643 10.4601 3.33333 9.99984 3.33333C9.5396 3.33333 9.1665 3.70643 9.1665 4.16667C9.1665 4.6269 9.5396 5 9.99984 5Z"
                      stroke="#666666"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.99984 16.6667C10.4601 16.6667 10.8332 16.2936 10.8332 15.8333C10.8332 15.3731 10.4601 15 9.99984 15C9.5396 15 9.1665 15.3731 9.1665 15.8333C9.1665 16.2936 9.5396 16.6667 9.99984 16.6667Z"
                      stroke="#666666"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </td>
              </tr>
              <tr>
                <td>IND-12345</td>
                <td>12 Nov 2024</td>
                <td>Business Growth Software</td>
                <td>₹ 25,000</td>
                <td>₹ 25,000</td>
                <td>₹ 25,000</td>
                <td> Net Banking</td>

                <td>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M9.99984 10.8333C10.4601 10.8333 10.8332 10.4602 10.8332 10C10.8332 9.53976 10.4601 9.16667 9.99984 9.16667C9.5396 9.16667 9.1665 9.53976 9.1665 10C9.1665 10.4602 9.5396 10.8333 9.99984 10.8333Z"
                      stroke="#666666"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.99984 5C10.4601 5 10.8332 4.6269 10.8332 4.16667C10.8332 3.70643 10.4601 3.33333 9.99984 3.33333C9.5396 3.33333 9.1665 3.70643 9.1665 4.16667C9.1665 4.6269 9.5396 5 9.99984 5Z"
                      stroke="#666666"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.99984 16.6667C10.4601 16.6667 10.8332 16.2936 10.8332 15.8333C10.8332 15.3731 10.4601 15 9.99984 15C9.5396 15 9.1665 15.3731 9.1665 15.8333C9.1665 16.2936 9.5396 16.6667 9.99984 16.6667Z"
                      stroke="#666666"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </td>
              </tr>
              <tr>
                <td>IND-12345</td>
                <td>12 Nov 2024</td>
                <td>Business Growth Software</td>
                <td>₹ 25,000</td>
                <td>₹ 25,000</td>
                <td>₹ 25,000</td>
                <td> Net Banking</td>

                <td>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M9.99984 10.8333C10.4601 10.8333 10.8332 10.4602 10.8332 10C10.8332 9.53976 10.4601 9.16667 9.99984 9.16667C9.5396 9.16667 9.1665 9.53976 9.1665 10C9.1665 10.4602 9.5396 10.8333 9.99984 10.8333Z"
                      stroke="#666666"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.99984 5C10.4601 5 10.8332 4.6269 10.8332 4.16667C10.8332 3.70643 10.4601 3.33333 9.99984 3.33333C9.5396 3.33333 9.1665 3.70643 9.1665 4.16667C9.1665 4.6269 9.5396 5 9.99984 5Z"
                      stroke="#666666"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.99984 16.6667C10.4601 16.6667 10.8332 16.2936 10.8332 15.8333C10.8332 15.3731 10.4601 15 9.99984 15C9.5396 15 9.1665 15.3731 9.1665 15.8333C9.1665 16.2936 9.5396 16.6667 9.99984 16.6667Z"
                      stroke="#666666"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </td>
              </tr>
              <tr>
                <td>IND-12345</td>
                <td>12 Nov 2024</td>
                <td>Business Growth Software</td>
                <td>₹ 25,000</td>
                <td>₹ 25,000</td>
                <td>₹ 25,000</td>
                <td> Net Banking</td>

                <td>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M9.99984 10.8333C10.4601 10.8333 10.8332 10.4602 10.8332 10C10.8332 9.53976 10.4601 9.16667 9.99984 9.16667C9.5396 9.16667 9.1665 9.53976 9.1665 10C9.1665 10.4602 9.5396 10.8333 9.99984 10.8333Z"
                      stroke="#666666"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.99984 5C10.4601 5 10.8332 4.6269 10.8332 4.16667C10.8332 3.70643 10.4601 3.33333 9.99984 3.33333C9.5396 3.33333 9.1665 3.70643 9.1665 4.16667C9.1665 4.6269 9.5396 5 9.99984 5Z"
                      stroke="#666666"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.99984 16.6667C10.4601 16.6667 10.8332 16.2936 10.8332 15.8333C10.8332 15.3731 10.4601 15 9.99984 15C9.5396 15 9.1665 15.3731 9.1665 15.8333C9.1665 16.2936 9.5396 16.6667 9.99984 16.6667Z"
                      stroke="#666666"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </td>
              </tr>{" "}
              <tr>
                <td>IND-12345</td>
                <td>12 Nov 2024</td>
                <td>Business Growth Software</td>
                <td>₹ 25,000</td>
                <td>₹ 25,000</td>
                <td>₹ 25,000</td>
                <td> Net Banking</td>

                <td>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M9.99984 10.8333C10.4601 10.8333 10.8332 10.4602 10.8332 10C10.8332 9.53976 10.4601 9.16667 9.99984 9.16667C9.5396 9.16667 9.1665 9.53976 9.1665 10C9.1665 10.4602 9.5396 10.8333 9.99984 10.8333Z"
                      stroke="#666666"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.99984 5C10.4601 5 10.8332 4.6269 10.8332 4.16667C10.8332 3.70643 10.4601 3.33333 9.99984 3.33333C9.5396 3.33333 9.1665 3.70643 9.1665 4.16667C9.1665 4.6269 9.5396 5 9.99984 5Z"
                      stroke="#666666"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.99984 16.6667C10.4601 16.6667 10.8332 16.2936 10.8332 15.8333C10.8332 15.3731 10.4601 15 9.99984 15C9.5396 15 9.1665 15.3731 9.1665 15.8333C9.1665 16.2936 9.5396 16.6667 9.99984 16.6667Z"
                      stroke="#666666"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </td>
              </tr>{" "}
              <tr>
                <td>IND-12345</td>
                <td>12 Nov 2024</td>
                <td>Business Growth Software</td>
                <td>₹ 25,000</td>
                <td>₹ 25,000</td>
                <td>₹ 25,000</td>
                <td> Net Banking</td>

                <td>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M9.99984 10.8333C10.4601 10.8333 10.8332 10.4602 10.8332 10C10.8332 9.53976 10.4601 9.16667 9.99984 9.16667C9.5396 9.16667 9.1665 9.53976 9.1665 10C9.1665 10.4602 9.5396 10.8333 9.99984 10.8333Z"
                      stroke="#666666"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.99984 5C10.4601 5 10.8332 4.6269 10.8332 4.16667C10.8332 3.70643 10.4601 3.33333 9.99984 3.33333C9.5396 3.33333 9.1665 3.70643 9.1665 4.16667C9.1665 4.6269 9.5396 5 9.99984 5Z"
                      stroke="#666666"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.99984 16.6667C10.4601 16.6667 10.8332 16.2936 10.8332 15.8333C10.8332 15.3731 10.4601 15 9.99984 15C9.5396 15 9.1665 15.3731 9.1665 15.8333C9.1665 16.2936 9.5396 16.6667 9.99984 16.6667Z"
                      stroke="#666666"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </td>
              </tr>{" "}
              <tr>
                <td>IND-12345</td>
                <td>12 Nov 2024</td>
                <td>Business Growth Software</td>
                <td>₹ 25,000</td>
                <td>₹ 25,000</td>
                <td>₹ 25,000</td>
                <td> Net Banking</td>

                <td>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M9.99984 10.8333C10.4601 10.8333 10.8332 10.4602 10.8332 10C10.8332 9.53976 10.4601 9.16667 9.99984 9.16667C9.5396 9.16667 9.1665 9.53976 9.1665 10C9.1665 10.4602 9.5396 10.8333 9.99984 10.8333Z"
                      stroke="#666666"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.99984 5C10.4601 5 10.8332 4.6269 10.8332 4.16667C10.8332 3.70643 10.4601 3.33333 9.99984 3.33333C9.5396 3.33333 9.1665 3.70643 9.1665 4.16667C9.1665 4.6269 9.5396 5 9.99984 5Z"
                      stroke="#666666"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.99984 16.6667C10.4601 16.6667 10.8332 16.2936 10.8332 15.8333C10.8332 15.3731 10.4601 15 9.99984 15C9.5396 15 9.1665 15.3731 9.1665 15.8333C9.1665 16.2936 9.5396 16.6667 9.99984 16.6667Z"
                      stroke="#666666"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Payment;
