import React from "react";
import "./homepage.css";
import { FaFacebook } from "react-icons/fa";
import { FaXTwitter, FaSquareInstagram } from "react-icons/fa6";

const Footer = () => {
  return (
    <footer
      className="footer black-bg"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <div className="page-section-pt">
        <div className="container mx-auto" style={{ maxWidth: "90%" }}>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6 sm-mb-30">
              <div className="footer-logo">
                <img
                  id="logo-footer"
                  className="mb-20"
                  src="https://vilvabusiness.com/assets/images/vilvabusiness-logo-footer.webp"
                  alt="Vilva Business"
                />
                <p className="pb-10">
                  Vilva Business is a B2B all-in-one service Portal with
                  solutions ranging from Marketing, Accounting, HR Management,
                  Business Printing Supplies, Technology Services such as
                  Website Development, Hosting, and everything else a successful
                  business needs for its existence and growth.
                </p>
              </div>
              <div className="social-icons color-hover ">
                <ul className="ps-0 d-flex gap-3">
                  <li className="social-facebook">
                    <a
                      href="https://www.facebook.com/vilvanetworks/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {/* <i className="fa fa-facebook"></i> */}
                      {/* <FontAwesomeIcon icon={faFacebook} className="i" /> */}
                      <FaFacebook className="fa fa-facebook" />
                    </a>
                  </li>
                  <li className="social-twitter">
                    <a
                      href="https://twitter.com/NetworksVilva"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {/* <i className="fa fa-twitter"></i> */}
                      <FaXTwitter />
                    </a>
                  </li>
                  <li className="social-instagram">
                    <a
                      href="https://www.instagram.com/vilvanetworks/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {/* <i className="fa fa-instagram"></i> */}
                      <FaSquareInstagram />
                    </a>
                  </li>
                  <li className="social-linkedin">
                    <a
                      href="https://in.linkedin.com/company/vilvanetworks"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {/* <i className="fa fa-linkedin"></i> */}
                      {/* <BsLinkedin /> */}
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-2 col-md-6 col-sm-6 sm-mb-30">
              <div className="footer-useful-link footer-hedding">
                <h6 className="text-white mb-30 mt-10 text-uppercase ps-0">
                  Navigation
                </h6>
                <ul className="ps-0">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/about">About Us</a>
                  </li>
                  <li>
                    <a href="/blog">Blog</a>
                  </li>
                  <li>
                    <a href="/contactus">Contact Us</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-2 col-md-6 col-sm-6 xs-mb-30">
              <div className="footer-useful-link footer-hedding">
                <h6 className="text-white mb-30 mt-10 text-uppercase ps-0">
                  Partnership
                </h6>
                <ul className="ps-0">
                  <li>
                    <a href="#">Loyalty Discounts</a>
                  </li>
                  <li>
                    <a href="#">Affiliate Programme</a>
                  </li>
                  <li>
                    <a href="#">Reseller Partnership</a>
                  </li>
                  <li>
                    <a href="#">White Label Partnership</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-2 col-md-6 col-sm-6 xs-mb-30">
              <div className="footer-useful-link footer-hedding">
                <h6 className="text-white mb-30 mt-10 text-uppercase ps-0">
                  Policies
                </h6>
                <ul className="ps-0">
                  <li>
                    <a href="#">Investors</a>
                  </li>
                  <li>
                    <a href="/privacypolicy">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="/return">Return & Refund Policy</a>
                  </li>
                  <li>
                    <a href="/termsandcondition">Terms of Use</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
              <h6 className="text-white mb-30 mt-10 text-uppercase ps-0">
                Contact Us
              </h6>
              <ul className="addresss-info ps-0">
                <li>
                  <i className="fa fa-map-marker"></i>
                  <p>Chennai, Tamil Nadu, India.</p>
                </li>
                <li>
                  <i className="fa fa-phone"></i>
                  <a href="tel:919791136945">
                    <span>+91 91765 69459</span>
                  </a>
                </li>
                <li>
                  <i className="fa fa-envelope-o"></i>info@vilvabusiness.com
                </li>
              </ul>
            </div>
          </div>

          <div className="footer-widget mt-20">
            <div className="row">
              <div className="col-md-12 text-center">
                <p className="mt-15">
                  &copy; Copyright{" "}
                  <span id="copyright">{new Date().getFullYear()}</span>{" "}
                  <a href="/"> Vilva Business </a> All Rights Reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
