import React from "react";
import Navbar from '../Navbar';
import Footer from '../Footer';
import Pagesblog from "../blog/pagesblog/Pagesblog"
import { Helmet } from 'react-helmet'; // Add this line


const Apivoicecalling = () => {
  return (
    <div>
          <Helmet>
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta
        name="keywords"
        content="API voice calling, voice call API, business communication, automated calls, Vilva Business"
      />
      <meta
        name="description"
        content="Explore Vilva Business's API voice calling solutions for seamless business communication. Enhance your outreach with our reliable and automated voice call API services."
      />
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      <title>API Voice Calling - Vilva Business</title>
    </Helmet>
        <Navbar/>
      <section
        className="page-title bg-overlay-black-60 jarallax"
        data-speed="0.6"
        data-video-src="https://www.youtube.com/watch?v=fQHlN_-Gwrc"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="page-title-name">
                <h1>API based Voice Calling</h1>
                <p>Faster Time to Market</p>
              </div>
              <ul className="page-breadcrumb">
                <li>
                  <a href="/">
                    <i className="fa fa-home"></i> Home
                  </a>{" "}
                  <i className="fa fa-angle-double-right"></i>
                </li>
                <li>
                  <span>API based Voice Calling</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <div className="prod-des container">
        <div className="prod-head">
          <h5 style={{ color: "#4a12f0" }}>API-Based Voice Calling</h5>
          <h2 style={{ color: "#001e60" }}>Voice Integration, Redefined</h2>
        </div>
        <div className="benefits row mx-auto">
          <div className="col-md-3 col-12 ben-ai">
            <img
              src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/growsuite.png"
              alt=""
            />
            <h3 style={{ color: "#001e60" }}>Customizable Integration</h3>
            <p>
              Our voice integration solution is designed to be incredibly
              flexible and adaptable, allowing you to seamlessly incorporate
              voice capabilities into any app or platform.
            </p>
          </div>
          <div className="col-md-3 col-12 ben-ai">
            <img
              src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/growsuiteimage.png"
              alt=""
            />
            <h3 style={{ color: "#001e60" }}>Real-Time Communication</h3>
            <p>
              Our platform supports real-time voice communication, enabling
              instant and high-quality interactions between users.
            </p>
          </div>
          <div className="col-md-3 col-12 ben-ai">
            <img
              src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/growsuiteimages.png"
              alt=""
            />
            <h3 style={{ color: "#001e60" }}>Global Reach</h3>
            <p>
              Our voice solution is designed with global connectivity in mind,
              supporting multiple languages and accents.
            </p>
          </div>
        </div>
      </div>

      <div className="usecase-all">
        <div className="usecase-head">
          <h5 style={{ color: "#4a12f0" }}>Key Use Cases</h5>
          <h2 style={{ color: "#001e60" }}>
            Manage Every Endpoint in One Place
          </h2>
          <h5 style={{ color: "#28334a" }}>
            Target your audience to reach them
          </h5>
        </div>
        <div className="row container pt-5 mx-auto">
          <div className="col-md-6 col-12">
            <div className="useimg">
              <img
                src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/post.jpg"
                alt=""
                style={{ height: "100%", width: "90%" }}
              />
            </div>
          </div>
          <div className="col-12 col-md-6 mt-5">
            <div className="row" id="row1">
              <h3>Support Automation</h3>
              <p>Automate customer support with intelligent voice systems.</p>
            </div>
            <div className="row" id="row3">
              <h3>Appointment Scheduling</h3>
              <p>
                Simplify scheduling with voice-based reminders and
                confirmations.
              </p>
            </div>
            <div className="row" id="row4">
              <h3>Feedback Collection</h3>
              <p>Gather user feedback efficiently through automated calls.</p>
            </div>
            <div className="row" id="row5">
              <h3>Verification Processes</h3>
              <p>Securely authenticate users through voice recognition.</p>
            </div>
            <div className="row" id="row6">
              <p>Drive sales with automated, personalized voice outreach.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="ready mt-5 pb-5 container">
        <div className="ready-des">
          <h3 style={{ color: "#001e60" }}>Ready to Get Started?</h3>
          <h5 style={{ color: "#28334a" }}>
            Experience device management, made easy!
          </h5>
          <div className="contact-us">
            <button type="button">
              Contact Us <i className="fa-solid fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
      <Pagesblog/>

      <Footer/>
    </div>
  );
};

export default Apivoicecalling;
