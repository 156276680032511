import React, { useEffect, useState } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Helmet } from 'react-helmet';

const OurPartner = () => {
  const [activeContentId, setActiveContentId] = useState(1); // Default to show first content
  const [partnerLogoClass, setPartnerLogoClass] = useState('partner-logo');

  useEffect(() => {
    // Add a slight delay to ensure the animation is visible
    const timer = setTimeout(() => {
      setPartnerLogoClass('partner-logo animate');
    }, 100);

    return () => clearTimeout(timer); // Cleanup the timer
  }, []);

  const showContents = (id) => {
    setActiveContentId(id);
  };

  return (
    <div>
        <Helmet>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="keywords"
          content="partner with us, business partnerships, collaboration opportunities, Vilva Business"
        />
        <meta
          name="description"
          content="Partner with Vilva Business for collaboration opportunities, business partnerships, and mutual growth. Contact us to explore how we can work together to achieve success in our respective fields."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <title>Partner with Us - Vilva Business</title>
      </Helmet>
      <Navbar/>
      <div className="heading-about">
        <h2 style={{ textAlign: 'center' }} className={partnerLogoClass}>Our Well-known Partner</h2>
        <p>We are proud to collaborate with a diverse group of well-known partners who share our commitment to environmental sustainability and community engagement. Our partners play a crucial role in helping us achieve our mission by providing resources, expertise, and support.</p>
      </div>

      <div className="greencon">
        <div>
          <h1>Benefits of Partnership</h1>
          <ul style={{ fontSize: '14px' }}>
            <li>Unlocking Mutual Growth Opportunities</li>
            <li>Exclusive Benefits for Partners</li>
            <li>Maximizing Synergies</li>
            <li>Innovation and Creativity</li>
          </ul>
        </div>
        <div>
          <h1>Value Proposition</h1>
          <ul style={{ fontSize: '14px' }}>
            <li>Elevate Your Business with Vilva Business</li>
            <li>Driving Innovation Through Partnership</li>
            <li>Experience the Power of Partnership</li>
            <li>Partnering for Sustainable Success</li>
          </ul>
        </div>
        <div>
          <h1>Call to Action</h1>
          <ul style={{ fontSize: '14px' }}>
            <li>Partner with Us Today!</li>
            <li>Let's Grow Together</li>
            <li>Start Your Partnership Journey</li>
            <li>Become Our Partner</li>
          </ul>
        </div>
      </div>

      <div className="maincan">
        <div className="container">
          <h2 style={{ textAlign: 'center' }} className="partner-logo mb-3">Empowering Partnerships: Join Forces with Vilva Business</h2>
          <p style={{ textAlign: 'center', fontSize: '16px' }}>At Vilva Business, collaborate with us and gain access to cutting-edge document management and secure data storage solutions, while contributing to environmental sustainability and community engagement. Together, let's make a difference.</p>

          <div className="our-products">
            {[1, 2, 3, 4, 5].map((id) => (
              <div className="content" id={`content${id}`} style={{ display: activeContentId === id ? 'block' : 'none' }} key={id}>
                <div className="content-con row">
                  {/* Content for each partner here */}
                  {id === 1 && (
                    <>
                      <div className="col-12 col-md-6">
                        <p className="p-0 pb-5 mb-5" style={{ fontSize: '18px' }}>We specialize in providing top-tier document management and secure data storage solutions for businesses of all sizes...</p>
                        <h2>Why Partner with Us?</h2>
                        <ul style={{ fontSize: '18px' }}>
                          <li>Advanced Security</li>
                          <li>Seamless Integration</li>
                          <li>Scalability</li>
                          <li>Exceptional Support</li>
                        </ul>
                      </div>
                      <div className="col-12 col-md-6 second-con">
                        <h2>Papertrail</h2>
                        <p style={{ fontSize: '18px' }}>Papertrail is a cloud-hosted log management service...</p>
                      </div>
                    </>
                  )}
                  {id === 2 && (
                    <>
                      <div className="col-6 col-12 col-md-6" style={{ fontSize: '18px' }}>
                        <p className="p-0 pb-5 mb-5">Sentry is a comprehensive application monitoring and error tracking tool...</p>
                        <h2>Why Partner with Us?</h2>
                        <ul style={{ fontSize: '18px' }}>
                          <li>Real-Time Error Tracking</li>
                          <li>Detailed Error Reports</li>
                          <li>Performance Monitoring</li>
                          <li>Seamless Integrations</li>
                        </ul>
                      </div>
                      <div className="col-6 col-12 col-md-6 second-con">
                        <h2>Sentry</h2>
                        <p style={{ fontSize: '18px' }}>Sentry integrates with a wide range of tools and platforms...</p>
                      </div>
                    </>
                  )}
                  {id === 3 && (
                    <>
                      <div className="col-6 col-12 col-md-6" style={{ fontSize: '18px' }}>
                        <p className="p-0 pb-5 mb-5">We are integrating WhatsApp’s powerful messaging capabilities...</p>
                        <h2>Why Partner with Us?</h2>
                        <ul style={{ fontSize: '18px' }}>
                          <li>24/7 Availability</li>
                          <li>Quick Response Times</li>
                          <li>End-to-End Encryption</li>
                          <li>Seamless Integration</li>
                        </ul>
                      </div>
                      <div className="col-6 col-12 col-md-6 second-con">
                        <h2>WhatsApp</h2>
                        <p style={{ fontSize: '18px' }}>WhatsApp, the world’s leading messaging platform...</p>
                      </div>
                    </>
                  )}
                  {id === 4 && (
                    <>
                      <div className="col-6 col-12 col-md-6">
                        <p className="p-0 pb-5 mb-5" style={{ fontSize: '18px' }}>Laravel, a popular PHP web application framework...</p>
                        <h2>Why Partner with Us?</h2>
                        <ul style={{ fontSize: '18px' }}>
                          <li>Access to Advanced Development Tools</li>
                          <li>Community and Support</li>
                          <li>Scalability and Flexibility</li>
                          <li>Innovative Features</li>
                        </ul>
                      </div>
                      <div className="col-6 col-12 col-md-6 second-con">
                        <h2>Laravel</h2>
                        <p style={{ fontSize: '18px' }}>Laravel offers a rich ecosystem...</p>
                      </div>
                    </>
                  )}
                  {id === 5 && (
                    <>
                      <div className="col-6 col-12 col-md-6" style={{ fontSize: '18px' }}>
                        <p className="p-0 pb-5 mb-5">Wazuh is a comprehensive and open-source security monitoring platform...</p>
                        <h2>Why Partner with Us?</h2>
                        <ul style={{ fontSize: '18px' }}>
                          <li>Advanced Security Features</li>
                          <li>Customizable and Scalable Solutions</li>
                          <li>Open-Source Flexibility</li>
                          <li>Integrations and Ecosystem</li>
                        </ul>
                      </div>
                      <div className="col-6 col-12 col-md-6 second-con">
                        <h2>Wazuh</h2>
                        <p style={{ fontSize: '18px' }}>Wazuh integrates seamlessly with a wide range of third-party tools...</p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            ))}

            <div className="con-2">
              <div className={`headings ${activeContentId === 1 ? 'selecteds' : ''}`} data-id="1" onClick={() => showContents(1)}>Papertrail</div>
              <div className={`headings ${activeContentId === 2 ? 'selecteds' : ''}`} data-id="2" onClick={() => showContents(2)}>Sentry</div>
              <div className={`headings ${activeContentId === 3 ? 'selecteds' : ''}`} data-id="3" onClick={() => showContents(3)}>WhatsApp</div>
              <div className={`headings ${activeContentId === 4 ? 'selecteds' : ''}`} data-id="4" onClick={() => showContents(4)}>Laravel</div>
              <div className={`headings ${activeContentId === 5 ? 'selecteds' : ''}`} data-id="5" onClick={() => showContents(5)}>Wazuh</div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default OurPartner;
