import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import Pagesblog from "../blog/pagesblog/Pagesblog"
import { Helmet } from 'react-helmet';

const Shopify = () => {
  return (
    <div>
        <Helmet>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="keywords"
          content="Shopify solutions, e-commerce, business solutions, online store, Shopify support"
        />
        <meta
          name="description"
          content="Explore Shopify Solutions by Vilva Business, offering comprehensive support and services to optimize your e-commerce platform for success."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <title>Shopify Solutions - Vilva Business</title>
      </Helmet>
      <Navbar/>
        <div>
      <section
        className="page-title bg-overlay-black-60 jarallax"
        data-speed="0.6"
        data-video-src="https://www.youtube.com/watch?v=OiHJKG14GdU"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="page-title-name">
                <h1>Shopify Ecommerce Solutions</h1>
                <p>We know the secret of your success</p>
              </div>
              <ul className="page-breadcrumb">
                <li>
                  <a href="/">
                    <i className="fa fa-home"></i> Home
                  </a>
                  <i className="fa fa-angle-double-right"></i>
                </li>
                <li>
                  <span>Shopify Ecommerce Solutions</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <div className="prod-des container">
        <div className="prod-head">
          <h5 style={{ color: '#4a12f0' }}>Enjoy seamless e-commerce</h5>
          <h2 style={{ color: '#001e60' }}>Effortless management, scalable growth and secure protection</h2>
        </div>
        <div className="benifits mx-auto">
          <div className="col-md-3 col-12 ben-ai">
            <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/growsuite.png" alt="" />
            <h3 style={{ color: '#001e60' }}>Ease of Use</h3>
            <p>
              Shopify offers a user-friendly interface with drag-and-drop functionality, making it easy for even those without
              technical skills to set up and manage their online store. This streamlined process reduces the learning curve and
              allows business owners to focus on growing their business rather than dealing with complex technology.
            </p>
          </div>
          <div className="col-md-3 col-12 ben-ai">
            <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/growsuiteimage.png" alt="" />
            <h3 style={{ color: '#001e60' }}>Scalability and Flexibility</h3>
            <p>
              Shopify supports a wide range of business sizes and types. It can easily scale with the growth of your business,
              from small startups to large enterprises. With a vast selection of apps and integrations, Shopify allows for
              customization to meet specific business needs, making it a versatile platform for various e-commerce requirements.
            </p>
          </div>
          <div className="col-md-3 col-12 ben-ai">
            <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/growsuiteimages.png" alt="" />
            <h3 style={{ color: '#001e60' }}>Secure and Reliable</h3>
            <p>
              Shopify provides robust security features, including SSL certification and PCI compliance, ensuring that customer
              data and transactions are protected. Its reliable hosting and 24/7 customer support minimize downtime and
              technical issues, giving you peace of mind that your store is secure and always accessible.
            </p>
          </div>
        </div>
      </div>

      <div className="usecase-all">
        <div className="usecase-head">
          <h5 style={{ color: '#4a12f0' }}>Use Cases</h5>
          <h2 style={{ color: '#001e60' }}>Perfect for e-commerce subscriptions, drop shipping and multi-channel sales</h2>
          <h5 style={{ color: '#28334a' }}>
            Unify the management of every endpoint regardless of platform or ownership model.
          </h5>
        </div>
        <div className="row container pt-5 mx-auto">
          <div className="col-md-6 col-12">
            <div className="useimg">
              <img
                src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/post.jpg"
                alt=""
                style={{ height: '76%', width: '90%' }}
              />
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row" id="row1">
              <h3>E-commerce Stores</h3>
              <p>Ideal for creating and managing online retail shops selling physical or digital products.</p>
            </div>
            <div className="row" id="row2">
              <h3>Subscription Services</h3>
              <p>Perfect for businesses offering subscription boxes, memberships, or recurring billing services.</p>
            </div>
            <div className="row" id="row3">
              <h3>Dropshipping</h3>
              <p>
                Enables easy integration with dropshipping apps, allowing businesses to sell products without holding
                inventory.
              </p>
            </div>
            <div className="row" id="row4">
              <h3>Custom Product Sales</h3>
              <p>
                Supports businesses that offer customizable or made-to-order products, with tools for managing
                customizations and orders.
              </p>
            </div>
            <div className="row" id="row5">
              <h3>Multi-channel Sales</h3>
              <p>
                Allows businesses to sell across multiple channels, including social media platforms, online marketplaces,
                and in-person through point-of-sale systems.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="ready mt-5 pb-5 container">
        <div className="ready-des">
          <h3 style={{ color: '#001e60' }}>Dream Big Achieve Bigger – Partner with Vilva Business!</h3>
          <h5 style={{ color: '#28334a' }}>Experience device management, made easy!</h5>
          <div className="contact-us">
            <button type="button">
              <a href="/contact" target="_blank" style={{ color: 'white' }}>
                Explore the Benefits
              </a>
              <i className="fa-solid fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <Pagesblog/>

    <Footer/>
    </div>
  )
}

export default Shopify
