import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import Pagesblog from "../blog/pagesblog/Pagesblog"
import { Helmet } from "react-helmet";

const Appshops = () => {
  return (
    <div>
       <Helmet>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="keywords"
          content="app for shops, retail app, shop management app, store app"
        />
        <meta
          name="description"
          content="Discover our shop management app designed to streamline operations and enhance customer satisfaction. Explore how our app can empower your retail business."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <title>App for Shops - Vilva Business</title>
      </Helmet>
      <Navbar/>
      <section className="page-title bg-overlay-black-60 jarallax" data-speed="0.6" data-video-src="https://www.youtube.com/watch?v=BePoG2ONkTw">
  <div className="container">
    <div className="row">
      <div className="col-lg-12">
        <div className="page-title-name">
          <h1>Apps for Shop</h1>
          <p>We know the secret of your success</p>
        </div>
        <ul className="page-breadcrumb">
          <li><a href="/"><i className="fa fa-home"></i> Home</a> <i className="fa fa-angle-double-right"></i></li>
          <li><span>Event Signup / Ticket Sales Marketing</span></li>
        </ul>
      </div>
    </div>
  </div>
</section>

<div className="prod-des container">
  <div className="prod-head">
    <h5 style={{ color: "#4a12f0" }}>Streamlined shopping experience</h5>
    <h2 style={{ color: "#001e60" }}>Enhance engagement, streamline management, and simplify shopping</h2>
  </div>
  <div className="benifits mx-auto">
    <div className="col-md-3 col-12 ben-ai">
      <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/growsuite.png" alt="" />
      <h3 style={{ color: "#001e60" }}>Enhanced Customer Engagement</h3>
      <p>An app can boost customer interaction through personalized promotions, loyalty rewards, and push notifications, fostering stronger relationships and repeat business.</p>
    </div>
    <div className="col-md-3 col-12 ben-ai">
      <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/growsuiteimage.png" alt="" />
      <h3 style={{ color: "#001e60" }}>Streamlined Sales and Inventory Management</h3>
      <p>Features like real-time inventory tracking, order management, and integrated payment systems simplify shop operations and reduce manual errors.</p>
    </div>
    <div className="col-md-3 col-12 ben-ai">
      <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/growsuiteimages.png" alt="" />
      <h3 style={{ color: "#001e60" }}>Convenient Shopping Experience</h3>
      <p>Customers can enjoy a seamless shopping experience with easy browsing, online ordering, and quick checkout options, making it easier to shop anytime, anywhere.</p>
    </div>
  </div>
</div>

<div className="usecase-all">
  <div className="usecase-head">
    <h5 style={{ color: "#4a12f0" }}>Use Cases</h5>
    <h2 style={{ color: "#001e60" }}>Manage Every Endpoint in One Place</h2>
    <h5 style={{ color: "#28334a" }}>Enhance shopping convenience digitally</h5>
  </div>
  <div className="row container pt-5 mx-auto">
    <div className="col-md-6 col-12">
      <div className="useimg">
        <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/post.jpg" alt="" style={{ height: "75%", width: "90%" }} />
      </div>
    </div>
    <div className="col-12 col-md-6">
      <div className="row" id="row1">
        <h3>Product Catalog</h3>
        <p>Displaying an organized and searchable list of products with detailed descriptions and images.</p>
      </div>
      <div className="row" id="row2">
        <h3>Online Purchasing</h3>
        <p>Enabling customers to buy products directly through the app, with various payment options.</p>
      </div>
      <div className="row" id="row3">
        <h3>Inventory Management</h3>
        <p>Tracking stock levels, managing orders, and updating product availability in real-time.</p>
      </div>
      <div className="row" id="row4">
        <h3>Customer Loyalty Programs</h3>
        <p>Offering rewards, discounts, and special promotions to encourage repeat purchases.</p>
      </div>
      <div className="row" id="row5">
        <h3>Personalized Recommendations</h3>
        <p>Providing tailored product suggestions based on user behavior and preferences.</p>
      </div>
    </div>
  </div>
</div>

<div className="ready mt-5 pb-5 container">
  <div className="ready-des">
    <h3 style={{ color: "#001e60" }}>Build Your Legacy – Start with a Free Consultation!</h3>
    <h5 style={{ color: "#28334a" }}>Experience device management, made easy!</h5>
    <div className="contact-us">
      <button type="button">
        <a href="/contact" target="_blank" style={{ color: "white" }}>Test It Out Today</a>
        <i className="fa-solid fa-arrow-right"></i>
      </button>
    </div>
  </div>
</div>
<Pagesblog/>

<Footer/>
    </div>
  )
}

export default Appshops
