import React from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import Pagesblog from "../blog/pagesblog/Pagesblog"
import { Helmet } from "react-helmet";

const Androidsolution = () => {
  return (
    <>
     <Helmet>
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta
        name="keywords"
        content="android solutions, mobile app development, android development services"
      />
      <meta
        name="description"
        content="Explore our Android solutions page for comprehensive mobile app development services. Contact us for custom Android app development tailored to your business needs."
      />
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      <title>Android Solutions - Vilva Business</title>
    </Helmet>
    <Navbar/>
    <div className="prod-main-cont">
      <div className="product-banner">
        <div className="banner-img">
          <div className="prod-des">
            <h2 data-aos="fade-down" data-aos-duration="1000">
              Android App Solutions
            </h2>
            <p style={{color:'white'}}>
              Android has the largest market share among mobile operating
              systems, providing access to a vast audience. Developing an
              Android app allows you to tap into this extensive user base,
              maximizing your app's visibility and potential downloads. Android's
              open-source nature offers a high level of customization and
              flexibility, allowing developers to create unique and tailored app
              experiences. This ensures that your app can be designed to meet
              specific business needs and provide a standout user experience.
            </p>
          </div>
        </div>
      </div>
      <div className="prod-head container mx-auto py-5">
        <div
          className="prod-head-img"
          data-aos="zoom-in"
          data-aos-duration="1500"></div>
        <div className="prod-head-content">
          <h2>
            <span data-aos="fade-up-right" data-aos-duration="1200">
              Transform Your Business
            </span>
            with
            <span data-aos="fade-up-right" data-aos-duration="1200"> Innovative Android App Solutions</span>
          </h2>
          <p>
            Android apps can seamlessly integrate with various Google services
            and APIs, such as Google Maps, Google Pay, and Firebase. This
            enhances functionality, improves user experience, and leverages
            Google’s powerful tools to add valuable features to your app.
          </p>
          <p>
            Android app solutions provide robust and flexible options for
            developing dynamic mobile applications. With access to a broad
            range of Google services and APIs, such as Google Maps and Google
            Pay, your app can integrate advanced features seamlessly. These
            solutions enhance user experience, improve functionality, and
            ensure your app is equipped with the latest technology. Whether
            you're aiming for superior performance or innovative features,
            Android app solutions offer the tools needed to bring your vision
            to life.
          </p>

          <p><b>01.</b> Highly Customizable and Scalable</p>
          <p><b>02.</b> Wide Market Reach</p>
          <p><b>03.</b> Customizable and Flexible Development</p>
          <p><b>04.</b> Integration with Google Services</p>
          <p><b>05.</b> Robust Performance and Security</p>

          <p>
            Android app solutions offer powerful tools for creating
            high-performance, feature-rich applications. By leveraging Android's
            extensive libraries and APIs, including support for real-time data
            syncing and in-app messaging, your app can deliver an engaging and
            responsive user experience. These solutions enable seamless
            integration with various services and devices, ensuring your app
            remains adaptable and scalable as technology evolves. Embrace the
            potential of Android to build apps that stand out and meet the
            needs of today's mobile users.
          </p>
        </div>
      </div>

      <div className="client-testimonial container mx-auto py-5">
        <div className="client-testimonial-des">
          <h2 data-aos="fade-left" data-aos-duration="1200">
            What our clients say about Android App Services.
          </h2>
        </div>
        <div className="client-testimonial">
          <div className="test-img">
            <div className="test-img-des">
              <p>
                "Android app solutions have been fantastic for us. The seamless integration with Google services and the platform's flexibility have enhanced our app’s performance and user experience."
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="news-letter container mx-auto my-5 pt-4">
        <div className="news-letter-img pt-5">
          <div className="row">
            <div className="col-md-6 col-12">
              <h3>Start your 7-day free trial</h3>
              <p>
                Experience seamless functionality with our platform.
                Effortlessly customize your workflow.
              </p>
              <div
                className="news-input"
                data-aos="fade-right"
                data-aos-duration="1200">
                <input
                  type="text"
                  name="email"
                  placeholder="Enter your email"
                  required />
                <button type="button">Start</button>
              </div>
              <div className="radio-btn pt-5">
                <p>
                  <i className="fa-solid fa-circle-dot"></i> &nbsp;Free 7-day trial
                </p>
                <p>
                  <i className="fa-solid fa-circle-dot"></i>&nbsp;No credit card
                  required
                </p>
                <p>
                  <i className="fa-solid fa-circle-dot"></i>&nbsp;Cancel anytime
                </p>
              </div>
            </div>
            <div
              className="col-6 d-none d-md-block"
              data-aos="fade-left"
              data-aos-duration="1500">
              <img className="news-img" src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/Image (3).png" alt="News" />
            </div>
          </div>
        </div>
      </div>

      <div
        className="get-in-touch container mx-auto pt-5"
        data-aos="zoom-in"
        data-aos-duration="1500">
        <h2>Get in touch</h2>
        <form action="#" method="POST">
          <div className="row">
            <div className="col-md-6 col-12">
              <label htmlFor="name">Full Name *</label> <br />
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Your full name"
                required />
            </div>
            <div className="col-md-6 col-12">
              <label htmlFor="email">Your email *</label> <br />
              <input
                type="email"
                id="email"
                name="email"
                placeholder="example@yourmail.com"
                required />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-6 col-12">
              <label htmlFor="company">Company *</label> <br />
              <input
                type="text"
                id="company"
                name="company"
                placeholder="Your company name here"
                required />
            </div>
            <div className="col-md-6 col-12">
              <label htmlFor="subject">Subject *</label> <br />
              <input
                type="text"
                id="subject"
                name="subject"
                placeholder="How can we Help"
                required />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-12 col-12">
              <label htmlFor="message">Message *</label> <br />
              <textarea
                id="message"
                name="message"
                placeholder="Hello there, I would like to talk about how to..."
                required></textarea>
            </div>
          </div>
          <button type="submit">Send Message</button>
        </form>
      </div>
    </div>
    <Pagesblog/>

    <Footer/>
    </>
  );
};

export default Androidsolution;
