import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import Pagesblog from "../blog/pagesblog/Pagesblog"
import { Helmet } from 'react-helmet';

const Gstregistration = () => {
  return (
    <div>
       <Helmet>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="keywords"
          content="GST registration, GST application, GST eligibility, GST compliance"
        />
        <meta
          name="description"
          content="Register for GST with Vilva Business. Our experts guide you through the GST registration process, ensuring compliance and efficiency for your business. Contact us today to get started with GST registration."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <title>GST Registration - Vilva Business</title>
      </Helmet>
      <Navbar/>
      <section className="page-title bg-overlay-black-60 jarallax" data-speed="0.6" data-video-src="https://www.youtube.com/watch?v=OZCiUkYDWK8">
  <div className="container">
    <div className="row">
      <div className="col-lg-12">
        <div className="page-title-name">
          <h1>GST Registration</h1>
          <p>We know the secret of your success</p>
        </div>
        <ul className="page-breadcrumb">
          <li><a href="/"><i className="fa fa-home"></i> Home</a> <i className="fa fa-angle-double-right"></i></li>
          <li><span>GST Registration</span></li>
        </ul>
      </div>
    </div>
  </div>
</section>

<div className="features-layout2">
  <div className="row mx-auto container">
    <div className="col-12 col-md-4 mt-3">
      <div className="layout-col-2 mt-3">
        <div className="top-bg"></div>
        <div className="imgforcol-test">
          <img src="{{ asset('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/layout-1.png') }}" alt="" />
        </div>
        <h3>Legal Recognition and Credibility</h3>
        <p>
          GST registration formally recognizes your business as a legitimate supplier, enhancing its credibility and trustworthiness in the marketplace.
        </p>
      </div>
    </div>

    <div className="col-md-4 col-12 mt-3">
      <div className="layout-col-2 mt-3">
        <div className="top-bg-c"></div>
        <div className="imgforcol">
          <img src="{{ asset('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/layout-2.png') }}" alt="" />
        </div>
        <h3>Input Tax Credit Benefits</h3>
        <p>
          By claiming input tax credits on purchases, your business can lower its tax liability, leading to improved financial efficiency and cash flow.
        </p>
      </div>
    </div>

    <div className="col-md-4 col-12 mt-3">
      <div className="layout-col-2 mt-3">
        <div className="top-bg"></div>
        <div className="imgforcol-test">
          <img src="{{ asset('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/layout-3.png') }}" alt="" />
        </div>
        <h3>Streamlined Tax Compliance</h3>
        <p>
          GST simplifies the tax structure by merging multiple indirect taxes into one, making tax management and compliance easier and more efficient.
        </p>
      </div>
    </div>
  </div>
</div>

<div className="start-project mt-5 container">
  <div className="row mx-auto">
    <div className="col-12 col-md-6 project">
      <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/project.png" alt="" className="img-fluid" />
    </div>
    <div className="col-12 col-md-6 project-des" style={{ color: '#001e60' }}>
      <div className="project-des-con">
        <h2>Realize Your Dreams – Start Your Journey with Vilva Business!</h2>
        <p>
          Simplify your business compliance with our hassle-free GST registration services. Get started today and stay ahead with quick, reliable, and cost-effective solutions!
        </p>
        <div className="layout-btn">
          <button type="button">
            <a href="/contact" target="_blank" style={{ color: 'white' }}>
              Enroll Now
            </a>
            &nbsp;&nbsp;&nbsp; <i className="ri-arrow-right-line"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<Pagesblog/>

<Footer/>
    </div>
  )
}

export default Gstregistration
