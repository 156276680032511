import React, { useEffect } from 'react';
import './partner.css';
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Helmet } from 'react-helmet';

const PartnershipPage = () => {
  useEffect(() => {
    // Show the first content by default on load
    showContents(1);
    const partnerLogo = document.querySelector('.partner-logo');
    setTimeout(() => {
      if (partnerLogo) {
        partnerLogo.classList.add('animate');
      }
    }, 100);
  }, []);

  const showContents = (id) => {
    const contents = document.querySelectorAll('.content');
    contents.forEach(content => {
      content.style.display = 'none';
    });

    const headings = document.querySelectorAll('.headings');
    headings.forEach(heading => {
      heading.classList.remove('selecteds');
    });

    const selectedContent = document.getElementById(`content${id}`);
    if (selectedContent) {
      selectedContent.style.display = 'block';
    }

    const selectedHeading = document.querySelector(`[data-id="${id}"]`);
    if (selectedHeading) {
      selectedHeading.classList.add('selecteds');
    }
  };

  return (
    <div>
       <Helmet>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="keywords"
          content="partner with us, business partnerships, collaboration opportunities, Vilva Business"
        />
        <meta
          name="description"
          content="Partner with Vilva Business for collaboration opportunities, business partnerships, and mutual growth. Contact us to explore how we can work together to achieve success in our respective fields."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <title>Partner with Us - Vilva Business</title>
      </Helmet>
      <Navbar/>
      <div className="heading-about">
        <h2 style={{ textAlign: 'center' }} className="partner-logo">Our Well-known Partner</h2>
        <p>
          We are proud to collaborate with a diverse group of well-known partners who share our commitment to environmental sustainability and community engagement. Our partners play a crucial role in helping us achieve our mission by providing resources, expertise, and support.
        </p>
      </div>
      <div className="greencon">
        <div>
          <h1>Benefits of Partnership</h1>
          <ul style={{ fontSize: '14px' }}>
            <li>Unlocking Mutual Growth Opportunities</li>
            <li>Exclusive Benefits for Partners</li>
            <li>Maximizing Synergies</li>
            <li>Innovation and Creativity</li>
          </ul>
        </div>
        <div>
          <h1>Value Proposition</h1>
          <ul style={{ fontSize: '14px' }}>
            <li>Elevate Your Business with Vilva Business</li>
            <li>Driving Innovation Through Partnership</li>
            <li>Experience the Power of Partnership</li>
            <li>Partnering for Sustainable Success</li>
          </ul>
        </div>
        <div>
          <h1>Call to Action</h1>
          <ul style={{ fontSize: '14px' }}>
            <li>Partner with Us Today!</li>
            <li>Let's Grow Together</li>
            <li>Start Your Partnership Journey</li>
            <li>Become Our Partner</li>
          </ul>
        </div>
      </div>
      <div className="maincan">
        <div className="container">
          <h2 style={{ textAlign: 'center' }} className="partner-logo mb-3">
            Empowering Partnerships: Join Forces with Vilva Business
          </h2>
          <p style={{ textAlign: 'center', fontSize: '16px' }}>
            At Vilva Business, collaborate with us and gain access to cutting-edge document management and secure data storage solutions, while contributing to environmental sustainability and community engagement. Together, let's make a difference.
          </p>

          {/* <div className="our-products">
            <div>
              <div className="content sm-12" id="content1">
                <div className="content-con row">
                  <div className="col-12 col-md-6">
                    <p className="p-0 pb-5 mb-5" style={{ fontSize: '18px' }}>
                      We specialize in providing top-tier document management and secure data storage solutions for businesses of all sizes. Our robust and user-friendly platform ensures that your critical information is always organized, accessible, and protected.
                    </p>
                    <h2>Why Partner with Us?</h2>
                    <ul style={{ fontSize: '18px' }}>
                      <li>Advanced Security</li>
                      <li>Seamless Integration</li>
                      <li>Scalability</li>
                      <li>Exceptional Support</li>
                    </ul>
                  </div>
                  <div className="col-12 col-md-6 sm-12 second-con">
                    <h2>Paper Trail</h2>
                    <p style={{ fontSize: '18px' }}>
                      Papertrail is a cloud-hosted log management service designed to aggregate, monitor, and analyze log data from various sources. It is widely used for its simplicity, ease of use, and powerful features that help in troubleshooting, debugging, and understanding application performance.
                    </p>
                  </div>
                </div>
              </div>

              {['Sentry', 'WhatsApp', 'Laravel', 'Wazuh'].map((section, idx) => (
                <div className="content" id={`content${idx + 2}`} key={idx}>
                  <div className="content-con row">
                    <div className="col-6 col-12 col-md-6" style={{ fontSize: '18px' }}>
                      <p className="p-0 pb-5 mb-5">
                      </p>
                      <h2>Why Partner with Us?</h2>
                      <ul style={{ fontSize: '18px' }}>
                      </ul>
                    </div>
                    <div className="col-6 col-12 col-md-6 second-con">
                      <h2>{section}</h2>
                      <p style={{ fontSize: '18px' }}>
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div> */}
          <div className="our-products">
  <div>
    <div className="content sm-12" id="content1">
      <div className="content-con row">
        <div className="col-12 col-md-6">
          <p className="p-0 pb-5 mb-5" style={{ fontSize: '18px' }}>
            We specialize in providing top-tier document management and secure data storage solutions for businesses of all sizes. Our robust and user-friendly platform ensures that your critical information is always organized, accessible, and protected.
          </p>
          <h2>Why Partner with Us?</h2>
          <ul style={{ fontSize: '18px' }}>
            <li>Advanced Security</li>
            <li>Seamless Integration</li>
            <li>Scalability</li>
            <li>Exceptional Support</li>
          </ul>
        </div>
        <div className="col-12 col-md-6 sm-12 second-con">
          <h2>Paper trail</h2>
          <p style={{ fontSize: '18px' }}>
            Papertrail is a cloud-hosted log management service designed to aggregate, monitor, and analyze log data from various sources. It is widely used for its simplicity, ease of use, and powerful features that help in troubleshooting, debugging, and understanding application performance.
          </p>
        </div>
      </div>
    </div>

    <div className="content" id="content2">
      <div className="content-con row">
        <div className="col-6 col-12 col-md-6" style={{ fontSize: '18px' }}>
          <p className="p-0 pb-5 mb-5">
            Sentry is a comprehensive application monitoring and error tracking tool designed to help developers identify, diagnose, and fix issues in real-time. By partnering with Sentry, companies can enhance their software development lifecycle, improve application performance, and provide superior user experiences.
          </p>
          <h2>Why Partner with Us?</h2>
          <ul style={{ fontSize: '18px' }}>
            <li>Real-Time Error Tracking</li>
            <li>Detailed Error Reports</li>
            <li>Performance Monitoring</li>
            <li>Seamless Integrations</li>
          </ul>
        </div>
        <div className="col-6 col-12 col-md-6 second-con">
          <h2>Sentry</h2>
          <p style={{ fontSize: '18px' }}>
            Sentry integrates with a wide range of tools and platforms, such as GitHub, Jira, Slack, and more. These integrations facilitate streamlined workflows and better collaboration among development teams.
          </p>
        </div>
      </div>
    </div>

    <div className="content" id="content3">
      <div className="content-con row">
        <div className="col-6 col-12 col-md-6" style={{ fontSize: '18px' }}>
          <p className="p-0 pb-5 mb-5">
            We are integrating WhatsApp’s powerful messaging capabilities directly into our services, offering our customers a seamless, secure, and efficient way to connect with us. WhatsApp’s extensive user base allows us to connect with our customers globally, breaking down geographical barriers and making communication easier and more accessible.
          </p>
          <h2>Why Partner with Us?</h2>
          <ul style={{ fontSize: '18px' }}>
            <li>24/7 Availability</li>
            <li>Quick Response Times</li>
            <li>End-to-End Encryption</li>
            <li>Seamless Integration</li>
          </ul>
        </div>
        <div className="col-6 col-12 col-md-6 second-con">
          <h2>WhatsApp</h2>
          <p style={{ fontSize: '18px' }}>
            WhatsApp, the world’s leading messaging platform with over 2 billion users worldwide. This collaboration marks a significant milestone for our company as we strive to enhance our customer communication and engagement strategies.
          </p>
        </div>
      </div>
    </div>

    <div className="content" id="content4">
      <div className="content-con row">
        <div className="col-6 col-12 col-md-6">
          <p className="p-0 pb-5 mb-5" style={{ fontSize: '18px' }}>
            Laravel, a popular PHP web application framework, offers robust features and an elegant syntax to facilitate web development. Partnering with Laravel can be highly beneficial for businesses aiming to enhance their web development capabilities or integrate advanced solutions into their offerings.
          </p>
          <h2>Why Partner with Us?</h2>
          <ul style={{ fontSize: '18px' }}>
            <li>Access to Advanced Development Tools</li>
            <li>Community and Support</li>
            <li>Scalability and Flexibility</li>
            <li>Innovative Features</li>
          </ul>
        </div>
        <div className="col-6 col-12 col-md-6 second-con">
          <h2>Laravel</h2>
          <p style={{ fontSize: '18px' }}>
            Laravel offers a rich ecosystem, including Laravel Forge, Envoyer, and Vapor, for server management and deployment. Additionally, it integrates seamlessly with other technologies and platforms, enhancing its versatility.
          </p>
        </div>
      </div>
    </div>

    <div className="content" id="content5">
      <div className="content-con row">
        <div className="col-6 col-12 col-md-6" style={{ fontSize: '18px' }}>
          <p className="p-0 pb-5 mb-5">
            Wazuh is a comprehensive and open-source security monitoring platform that offers a wide array of capabilities including threat detection, integrity monitoring, incident response, and compliance management. Partnering with Wazuh can be highly beneficial for companies seeking to enhance their security.
          </p>
          <h2>Why Partner with Us?</h2>
          <ul style={{ fontSize: '18px' }}>
            <li>Advanced Security Features</li>
            <li>Customizable and Scalable Solutions</li>
            <li>Open-Source Flexibility</li>
            <li>Integrations and Ecosystem</li>
          </ul>
        </div>
        <div className="col-6 col-12 col-md-6 second-con">
          <h2>Wazuh</h2>
          <p style={{ fontSize: '18px' }}>
            Wazuh integrates seamlessly with a wide range of third-party tools and platforms, such as cloud services (AWS, Azure, Google Cloud), security information and event management (SIEM) systems, and other IT infrastructure components.
          </p>
        </div>
      </div>
    </div>

    <div className="con-2">
      <div className="headings selecteds" data-id="1" onClick={() => showContents(1)}>Paper trail</div>
      <div className="headings" data-id="2" onClick={() => showContents(2)}>Sentry</div>
      <div className="headings" data-id="3" onClick={() => showContents(3)}>WhatsApp</div>
      <div className="headings" data-id="4" onClick={() => showContents(4)}>Laravel</div>
      <div className="headings" data-id="5" onClick={() => showContents(5)}>Wazuh</div>
    </div>
  </div>
</div>


          {/* <div className="con-2">
            <div className="headings selecteds" data-id="1" onClick={() => showContents(1)}>Paper Trail</div>
            <div className="headings" data-id="2" onClick={() => showContents(2)}>Sentry</div>
            <div className="headings" data-id="3" onClick={() => showContents(3)}>WhatsApp</div>
            <div className="headings" data-id="4" onClick={() => showContents(4)}>Laravel</div>
            <div className="headings" data-id="5" onClick={() => showContents(5)}>Wazuh</div>
          </div> */}
        </div>
      </div>

      <section>
        <div className="buisness">
          <div className="b-content">
            <h1 className="title-effect" style={{ fontSize: '32px', color: '#007E60' }}>
              Join Us and Elevate Your eCommerce Business
            </h1>
            <p>
              Our product is designed to simplify the process of launching and managing an online store, making it accessible for businesses of all sizes and industries. With an intuitive drag-and-drop website builder, customizable templates, and powerful features, you can create a professional and responsive online store without needing extensive technical knowledge.
            </p>
          </div>
        </div>
      </section>
      <Footer/>
    </div>
  );
};

export default PartnershipPage;
