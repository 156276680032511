import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import Pagesblog from "../blog/pagesblog/Pagesblog"
import { Helmet } from "react-helmet";

const Apphotels = () => {
  return (
    <div>
        <Helmet>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="keywords"
          content="app for hotels, hotel management app, hospitality app, accommodation app"
        />
        <meta
          name="description"
          content="Discover our hotel management app tailored to streamline operations and enhance guest experiences. Explore how our app can optimize your hotel's efficiency and service quality."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <title>App for Hotels - Vilva Business</title>
      </Helmet>
      <Navbar/>
      <section className="page-title bg-overlay-black-60 jarallax" data-speed="0.6" data-video-src="https://www.youtube.com/watch?v=0n6-5TFgPRA">
  <div className="container">
    <div className="row">
      <div className="col-lg-12">
        <div className="page-title-name">
          <h1>App for Hotels</h1>
          <p>We know the secret of your success</p>
        </div>
        <ul className="page-breadcrumb">
          <li><a href="/"><i className="fa fa-home"></i> Home</a> <i className="fa fa-angle-double-right"></i></li>
          <li><span>App for Hotels</span></li>
        </ul>
      </div>
    </div>
  </div>
</section>

<div className="features-layout2">
  <div className="row mx-auto container">
    <div className="col-12 col-md-4 mt-3">
      <div className="layout-col-2 mt-3">
        <div className="top-bg"></div>
        <div className="imgforcol-test">
          <img src="{{ asset('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/layout-1.png') }}" alt="" />
        </div>
        <h3>Enhanced Guest Experience</h3>
        <p>An Android app can provide guests with convenient features like mobile check-in/check-out, room service requests, and real-time booking management. This streamlines their stay and improves overall satisfaction.</p>
      </div>
    </div>
    <div className="col-md-4 col-12 mt-3">
      <div className="layout-col-2 mt-3">
        <div className="top-bg-c"></div>
        <div className="imgforcol">
          <img src="{{ asset('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/layout-2.png') }}" alt="" />
        </div>
        <h3>Efficient Booking Management</h3>
        <p>With an app, hotels can offer a seamless booking experience, including instant reservations, special promotions, and personalized offers. This helps drive direct bookings and reduces reliance on third-party platforms.</p>
      </div>
    </div>
    <div className="col-md-4 col-12 mt-3">
      <div className="layout-col-2 mt-3">
        <div className="top-bg"></div>
        <div className="imgforcol-test">
          <img src="{{ asset('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/layout-3.png') }}" alt="" />
        </div>
        <h3>Improved Operational Efficiency</h3>
        <p>The app can integrate with hotel management systems to streamline operations, manage guest requests, and optimize room allocation. This leads to better resource management and increased operational efficiency.</p>
      </div>
    </div>
  </div>
</div>

<div className="start-project mt-5">
  <div className="row container mx-auto">
    <div className="col-12 col-md-6 project">
      <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/project.png" alt="" className='img-fluid' />
    </div>
    <div className="col-12 col-md-6 project-des" style={{ color: "#001e60" }}>
      <div className="project-des-con">
        <h2>Your Future is Bright – Start Shining with Vilva Business!</h2>
        <p>Enhance guest satisfaction with our tailored hotel app solutions. Simplify bookings, improve service, and create a memorable stay for your customers with just a tap!</p>
        <div className="layout-btn">
          <button type="button">
            <a href="/contact" target="_blank" style={{ color: "white" }}>Become a Member</a>&nbsp;&nbsp;&nbsp; 
            <i className="ri-arrow-right-line"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<Pagesblog/>

<Footer/>
    </div>
  )
}

export default Apphotels
