import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import Pagesblog from "../blog/pagesblog/Pagesblog"
import { Helmet } from 'react-helmet';

const Cybersecurity = () => {
  
  return (
    <div>
        <Helmet>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="keywords"
          content="CyberSecurity, information security, data protection, threat management, security solutions, cybersecurity services"
        />
        <meta
          name="description"
          content="Enhance your digital security with Vilva Business's CyberSecurity solutions, providing comprehensive protection against threats and ensuring robust data security."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <title>CyberSecurity Solutions - Vilva Business</title>
      </Helmet>
      <Navbar/>
      <div>
  <div className="product-banner">
    <div className="banner-img">
      <div className="prod-des">
        <h2 data-aos="fade-right" data-aos-duration="1500">Cyber Security</h2>
        <p style={{color:'white'}}>
        Safeguards against malware, ransomware, phishing, and other cyber
          attacks that can compromise sensitive data and operations. With
          robust cybersecurity measures in place, organizations can detect and
          neutralize threats before they cause significant harm.
        </p>
      </div>
    </div>
  </div>

  <div className="service-head container mx-auto py-5">
    <div
      className="service-head-img"
      data-aos="zoom-in"
      data-aos-duration="1500"></div>
    <div className="service-head-content">
      <h2 data-aos="fade-right" data-aos-duration="1500">Features of Our Cyber Security</h2>
      <p>
        Our cybersecurity services are designed to protect your business from
        ever-evolving threats. We offer comprehensive solutions, including
        threat detection, risk assessment, and data protection, ensuring your
        systems remain secure and resilient. Our expert team stays ahead of
        the latest security trends to safeguard your digital assets, giving
        you peace of mind in a constantly changing landscape. Cybersecurity
        protocols maintain the integrity of data by using encryption,
        authentication, and authorization methods, ensuring that only
        authorized users can access and modify critical information.
      </p>
      <p>
        Helps businesses meet industry standards and legal requirements,
        avoiding fines and legal issues. Strong cybersecurity practices ensure
        that organizations adhere to data protection regulations such as GDPR,
        HIPAA, and other relevant frameworks, reducing the risk of
        non-compliance penalties. Ensures the accuracy and reliability of data,
        preventing unauthorized access or alterations.
      </p>

      <p><b>01.</b> Protection from Cyber Threats</p>
      <p><b>02.</b> Data Integrity</p>
      <p><b>03.</b> Regulatory Compliance</p>
      <p><b>04.</b> Threat detection</p>
      <p><b>05.</b> Fraud Detection</p>

      <p>
        Our cybersecurity services provide robust protection against digital
        threats, safeguarding your business with advanced security measures.
        We specialize in vulnerability assessments, threat monitoring, and
        incident response, ensuring your data and systems are secure. With our
        proactive approach, we help you stay ahead of cyber risks, allowing
        you to operate with confidence in today’s digital world.
      </p>
    </div>
  </div>

  <div className="technology-we-use container">
    <div className="technology-des">
      <h3>What technology we use for this service</h3>
      <p>
        We leverage cutting-edge technologies such as firewalls, intrusion
        detection systems (IDS), and encryption protocols for our cybersecurity
        services. Our use of advanced tools like SIEM (Security Information and
        Event Management) and AI-driven threat intelligence ensures
        comprehensive protection against cyber threats. These technologies
        allow us to detect, prevent, and respond to security breaches in real
        time, keeping your business secure.
      </p>
    </div>
    <div className="technology-img">
      <img
        src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/technology.webp"
        alt=""
        className="img-fluid"
        data-aos="fade-right"
        data-aos-duration="1500"
      />
    </div>
  </div>

  <div className="recent-project container mx-auto">
    <h2>Our recent Projects</h2>

    <div className="row container mx-auto">
      <div className="col-md-6 col-12">
        <div className="recent-img">
          <img
            src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/1.webp"
            alt=""
            className="img-fluid"
            data-aos="zoom-in"
            data-aos-duration="1500"
          />
          <div className="recent-img-des">
            <h4>Project Name</h4>
            <p>Description about the project</p>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-12">
        <div className="recent-img">
          <img
            src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/2.webp"
            alt=""
            className="img-fluid"
            data-aos="zoom-in"
            data-aos-duration="1500"
          />
          <div className="recent-img-des">
            <h4>Project Name</h4>
            <p>Description about the project</p>
          </div>
        </div>
      </div>
    </div>

    <div className="row container mx-auto">
      <div className="col-md-6 col-12">
        <div className="recent-img">
          <img
            src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/3.webp"
            alt=""
            className="img-fluid"
            data-aos="zoom-in"
            data-aos-duration="1500"
          />
          <div className="recent-img-des">
            <h4>Project Name</h4>
            <p>Description about the project</p>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-12">
        <div className="recent-img">
          <img
            src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/4.webp"
            alt=""
            className="img-fluid"
            data-aos="zoom-in"
            data-aos-duration="1500"
          />
          <div className="recent-img-des">
            <h4>Project Name</h4>
            <p>Description about the project</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div className="client-testimonial container mx-auto py-5">
    <div className="client-testimonial-des">
      <h2>What our clients say about our services</h2>
    </div>
    <div className="client-testimonial">
      <div className="test-img">
        <div className="test-img-des">
          <p>
            Their cybersecurity services have been top-notch, identifying
            vulnerabilities and implementing strong protections. We now feel
            confident that our business is secure against potential threats.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div
    className="get-in-touch container mx-auto pt-5"
    data-aos="zoom-in"
    data-aos-duration="1500">
    <h2>Get in touch</h2>

    <form
      action="{{ url('data') }}"
      method="POST"
     >
      @csrf
      <input
        type="text"
        name="honeypot"
        id="honeypot"
        style={{ display: "none" }}
      />
      <input type="hidden" name="source" value="5" />
      <div className="row">
        <div className="col-md-6 col-12">
          <label htmlFor="name">Full Name </label> <br />
          <input
            type="text"
            id="name"
            name="name"
            placeholder="  &nbsp; &nbsp;Your full name"
            required
          />
        </div>

        <div className="col-md-6 col-12">
          <label htmlFor="email">Your email </label> <br />
          <input
            type="text"
            id="email"
            name="email"
            placeholder=" &nbsp; &nbsp;example@yourmail.com"
            required
          />
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-md-6 col-12">
          <label htmlFor="company">Company </label> <br />
          <input
            type="text"
            id="company"
            name="company"
            placeholder="  &nbsp; &nbsp;your company name here"
            required
          />
        </div>
        <div className="col-md-6 col-12">
          <label htmlFor="Subject">Subject </label> <br />
          <input
            type="text"
            id="Subject"
            name="Subject"
            placeholder="  &nbsp; &nbsp;How can we Help"
            required
          />
        </div>
        <div className="col-md-6 col-12">
          <label htmlFor="phone">Mobile Number </label> <br />
          <input
            type="text"
            id="phone"
            name="phone"
            placeholder=" &nbsp; &nbsp;Your Mobile Number"
            required
          />
        </div>
        <div className="col-md-6 col-12">
          <label htmlFor="city">City</label> <br />
          <input
            type="text"
            id="city"
            name="city"
            placeholder=" &nbsp; &nbsp;Your City"
            required
          />
        </div>
        <div className="col-md-6 col-12">
          <label htmlFor="postcode">PostCode</label> <br />
          <input
            type="text"
            id="postcode"
            name="postcode"
            placeholder=" &nbsp; &nbsp;PostCode"
            required
          />
        </div>
      </div>
      <div className="col-md-6 col-12">
        <label htmlFor="message">How can we help you</label> <br />
        <textarea
          id="message"
          name="message"
          rows="4"
          cols="50"
          placeholder=" &nbsp; &nbsp;your message here"
          required></textarea>
      </div>

      <div className="g-recaptcha" data-sitekey="your-site-key-here"></div>
      <button type="submit" id="btn-submit">Send Message</button>
    </form>
  </div>
</div>
<Pagesblog/>

<Footer/>
    </div>
  )
}

export default Cybersecurity
