import React from 'react'
import Navbar from '../Navbar'
import Footer from '../Footer'

const Privacypolicy = () => {
  return (
    <div>
        <Navbar/>
        <div>
      <head>
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="eCommerce SaaS, online store builder, eCommerce solutions, custom websites, business growth, online retail platform" />
        <meta name="description" content="Transform your business with our eCommerce SaaS platform. Create and manage your online store effortlessly with custom websites tailored to your brand. Drive growth and success in the digital marketplace." />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <title>Privacy Policy</title>
        <style>{`
          h4, h1 {
            color: #040e56;
          }
        `}</style>
      </head>

      <div className="privacy-header container mx-auto pt-5">
        <h1 style={{ paddingTop: '45px', color: '#040e56' }}>Privacy Policy For Vilva Business</h1>
        <p>
          Vilva Business is committed to protecting the privacy of our customers, partners and other stakeholders. This Privacy Policy explains how we collect, use, disclose and protect information that we obtain from you through our website and other services.
        </p>
        <br />
        <p>
          This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Vilva Business. This policy is not applicable to any information collected offline or via channels other than this website. Our Privacy Policy was created with the help of the H-supertools Privacy Policy Generator.
        </p>

        <h4>Consent</h4>
        <p>
          By using our website, you hereby consent to our Privacy Policy and agree to its terms.
        </p>
      </div>

      <div className="collection container mx-auto">
        <h4>Collection of Information</h4>
        <p>
          The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.
        </p>
        <br />
        <p>
          If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.
        </p>
        <br />
        <p>
          When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.
        </p>
      </div>

      <div className="useofinformation container mx-auto">
        <h4>Use Of Information</h4>
        <p>We use the information we collect in various ways, including to:</p>
        <p>Provide, operate, and maintain our website</p>
      </div>

      <div className="logfiles container mx-auto">
        <h4>Log Files</h4>
        <br />
        <p>
          Vilva Business follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services’ analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users’ movement on the website, and gathering demographic information.
        </p>
      </div>

      <div className="cookies container mx-auto">
        <h4>Cookies and Web Beacons</h4>
        <br />
        <p>
          Like any other website, Vilva Business uses ‘cookies’. These cookies are used to store information including visitors’ preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users’ experience by customizing our web page content based on visitors’ browser type and/or other information.
        </p>
      </div>

      <div className="dart container mx-auto">
        <h4>Google DoubleClick DART Cookie</h4>
        <br />
        <p>
          Google is one of a third-party vendor on our site. It also uses cookies, known as DART cookies, to serve ads to our site visitors based upon their visit to www.website.com and other sites on the internet. However, visitors may choose to decline the use of DART cookies by visiting the Google ad and content network Privacy Policy at the following URL – <a href="https://policies.google.com/technologies/ads" target="_blank" rel="noopener noreferrer">https://policies.google.com/technologies/ads</a>
        </p>
      </div>

      <div className="ourpartners container mx-auto">
        <h4>Our Advertising Partners</h4>
        <p>
          Some of advertisers on our site may use cookies and web beacons. Our advertising partners are listed below. Each of our advertising partners has their own Privacy Policy for their policies on user data. For easier access, we hyperlinked to their Privacy Policies below.
        </p>
      </div>

      <div className="advertisingpolicies container mx-auto">
        <h4>Advertising Partners Privacy Policies</h4>
        <br />
        <p>
          You may consult this list to find the Privacy Policy for each of the advertising partners of Vilva Business.
        </p>
        <br />
        <p>
          Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on Vilva Business, which are sent directly to users’ browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.
        </p>
      </div>
    </div>
    <Footer/>
    </div>
  )
}

export default Privacypolicy
