import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import Pagesblog from "../blog/pagesblog/Pagesblog"
import { Helmet } from 'react-helmet';

const Gstfilling = () => {
  return (
    <div>
       <Helmet>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="keywords"
          content="GST filings, GST returns, GST registration, indirect taxes"
        />
        <meta
          name="description"
          content="File your GST returns and manage GST compliance with Vilva Business. Our experts help you navigate GST regulations and ensure timely filings for your business. Contact us today for professional GST services."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <title>GST Filings - Vilva Business</title>
      </Helmet>
      <Navbar/>
      <section className="page-title bg-overlay-black-60 jarallax" data-speed="0.6" data-video-src="https://www.youtube.com/watch?v=OiHJKG14GdU">
  <div className="container">
    <div className="row">
      <div className="col-lg-12">
        <div className="page-title-name">
          <h1>GST Fillings</h1>
          <p>We know the secret of your success</p>
        </div>
        <ul className="page-breadcrumb">
          <li><a href="/"><i className="fa fa-home"></i> Home</a> <i className="fa fa-angle-double-right"></i></li>
          <li><span>GST Fillings</span></li>
        </ul>
      </div>
    </div>
  </div>
</section>

<div className="prod-des container">
  <div className="prod-head">
    <h5 style={{ color: '#4a12f0' }}>Unlock Your Business Potential with GST Fillings</h5>
    <h2 style={{ color: '#001e60' }}>How often do businesses need to file GST returns</h2>
  </div>
  <div className="benifits mx-auto">
    <div className="col-md-3 col-12 ben-ai">
      <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/growsuite.png" alt="" />
      <h3 style={{ color: '#001e60' }}>Ensure Compliance</h3>
      <p>
        Regular GST filing helps your business adhere to legal requirements, avoiding potential penalties and legal issues.
      </p>
    </div>
    <div className="col-md-3 col-12 ben-ai">
      <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/growsuiteimage.png" alt="" />
      <h3 style={{ color: '#001e60' }}>Accurate Tax Returns</h3>
      <p>
        Timely and precise filings reflect your business’s true tax obligations, maintaining financial transparency.
      </p>
    </div>
    <div className="col-md-3 col-12 ben-ai">
      <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/growsuiteimages.png" alt="" />
      <h3 style={{ color: '#001e60' }}>Claim Input Tax Credit</h3>
      <p>
        Efficient filing allows you to claim input tax credits, reducing tax liability and enhancing cash flow.
      </p>
    </div>
  </div>
</div>

<div className="usecase-all">
  <div className="usecase-head">
    <h5 style={{ color: '#4a12f0' }}>Key Use Cases</h5>
    <h2 style={{ color: '#001e60' }}>Manage Every Endpoint in One Place</h2>
    <h5 style={{ color: '#28334a' }}>Manage all your Billing Here</h5>
  </div>
  <div className="row container pt-5 mx-auto">
    <div className="col-md-6 col-12">
      <div className="useimg">
        <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/post.jpg" alt="" style={{ height: '80%', width: '90%' }} />
      </div>
    </div>
    <div className="col-12 col-md-6">
      <div className="row" id="row1">
        <h3>Monthly/Quarterly Tax Returns</h3>
        <p>
          Businesses must file GSTR-1 (sales) and GSTR-3B (summary of inward and outward supplies) on a monthly or quarterly basis to report their tax liability and claim input tax credits.
        </p>
      </div>
      <div className="row" id="row2">
        <h3>Annual Return Filing</h3>
        <p>
          GSTR-9 is an annual return that consolidates all monthly/quarterly returns, providing a comprehensive overview of your business’s tax activities for the financial year.
        </p>
      </div>
      <div className="row" id="row3">
        <h3>Tax Reconciliation</h3>
        <p>
          Regular filing helps reconcile your input tax credit with the GST paid on purchases and sales, ensuring accuracy and avoiding discrepancies.
        </p>
      </div>
      <div className="row" id="row4">
        <h3>Refund Claims</h3>
        <p>
          GST filings facilitate the process of claiming refunds for excess tax payments or unutilized input tax credits.
        </p>
      </div>
    </div>
  </div>
</div>

<div className="ready mt-5 pb-5 container">
  <div className="ready-des">
    <h3 style={{ color: '#001e60' }}>Your Next Success Story Begins Here – Let’s Get Started!</h3>
    <h5 style={{ color: '#28334a' }}>Experience device management, made easy!</h5>
    <div className="contact-us">
      <button type="button">
        <a href="/contact" target="_blank" style={{ color: 'white' }}>
          Subscribe to Our Service
        </a>
        <i className="fa-solid fa-arrow-right"></i>
      </button>
    </div>
  </div>
</div>
<Pagesblog/>

<Footer/>
    </div>
  )
}

export default Gstfilling
