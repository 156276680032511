import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import Pagesblog from "../blog/pagesblog/Pagesblog"
import { Helmet } from 'react-helmet';

const Bulkwhatsapp = () => {
  return (
    <div>
        <Helmet>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="keywords"
          content="bulk WhatsApp marketing, WhatsApp messaging, business communication, marketing tools, Vilva Business"
        />
        <meta
          name="description"
          content="Explore Vilva Business's bulk WhatsApp marketing solutions to enhance your business communication and marketing strategies. Reach a wider audience effectively with our advanced messaging tools."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <title>Bulk WhatsApp Marketing - Vilva Business</title>
      </Helmet>
      <Navbar/>
      <section 
  className="page-title bg-overlay-black-60 jarallax" 
  data-speed="0.6" 
  data-video-src="https://www.youtube.com/watch?v=yIOR2Vkmu-0"
>
  <div className="container">
    <div className="row">
      <div className="col-lg-12">
        <div className="page-title-name">
          <h1>Bulk WhatsApp Marketing</h1>
          <p>to create and maintain WhatsApp marketing campaigns</p>
        </div>
        <ul className="page-breadcrumb">
          <li>
            <a href="/"><i className="fa fa-home"></i> Home</a>
            <i className="fa fa-angle-double-right"></i>
          </li>
          <li><span>Bulk WhatsApp Marketing</span></li>
        </ul>
      </div>
    </div>
  </div>
</section>

<div className="features-layout2">
  <div className="row mx-auto container">
    <div className="col-12 col-md-4">
      <div className="layout-col-2 mt-3">
        <div className="top-bg"></div>
        <div className="imgforcol-test">
          <img src="{{ asset('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/layout-1.png') }}" alt="" />
        </div>
        <h3>Direct and Immediate Communication</h3>
        <p>Reach your audience instantly with messages delivered directly to their smartphones, ensuring higher visibility and quicker responses.</p>
      </div>
    </div>

    <div className="col-md-4 col-12">
      <div className="layout-col-2 mt-3">
        <div className="top-bg-c"></div>
        <div className="imgforcol">
          <img src="{{ asset('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/layout-2.png') }}" alt="" />
        </div>
        <h3>High Engagement Rates</h3>
        <p>WhatsApp messages have high open and read rates compared to traditional email or SMS marketing, making it a highly effective channel for engagement.</p>
      </div>
    </div>

    <div className="col-md-4 col-12">
      <div className="layout-col-2 mt-3">
        <div className="top-bg"></div>
        <div className="imgforcol-test">
          <img src="{{ asset('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/layout-3.png') }}" alt="" />
        </div>
        <h3>Rich Media Support</h3>
        <p>Send not only text but also images, videos, and documents to create more engaging and interactive campaigns.</p>
      </div>
    </div>
  </div>
</div>

<div className="start-project mt-5">
  <div className="row mx-auto">
    <div className="col-12 col-md-6 project">
      <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/project.png" alt="" className="img-fluid" />
    </div>
    <div className="col-12 col-md-6 project-des" style={{ color: "#001e60" }}>
      <div className="project-des-con">
        <h2>Start your project with Us today</h2>
        <p>Get started with our cutting-edge e-commerce solutions today and elevate your online business with tailored strategies and innovative technology. Transform your customer experience and drive growth effortlessly!</p>

        <div className="layout-btn">
          <button type="button">Start a Project&nbsp;&nbsp;&nbsp;<i className="ri-arrow-right-line"></i></button>
        </div>
      </div>
    </div>
  </div>
</div>
<Pagesblog/>

<Footer/>
    </div>
  )
}

export default Bulkwhatsapp
