import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import Pagesblog from "../blog/pagesblog/Pagesblog"
import { Helmet } from 'react-helmet';

const Appwholesaler = () => {
  return (
    <div>
       <Helmet>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="keywords"
          content="app for wholesalers, wholesale app, bulk order app, distributor app"
        />
        <meta
          name="description"
          content="Explore our wholesale management app designed to streamline bulk orders and enhance business efficiency. Discover how our app can optimize your wholesale operations and increase profitability."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <title>App for Wholesalers - Vilva Business</title>
      </Helmet>
      <Navbar/>
      <section className="page-title bg-overlay-black-60 jarallax" data-speed="0.6" data-video-src="https://www.youtube.com/watch?v=BePoG2ONkTw">
  <div className="container">
    <div className="row">
      <div className="col-lg-12">
        <div className="page-title-name">
          <h1>Apps for Wholesalers</h1>
          <p>We know the secret of your success</p>
        </div>
        <ul className="page-breadcrumb">
          <li><a href="/"><i className="fa fa-home"></i> Home</a> <i className="fa fa-angle-double-right"></i></li>
          <li><span>Apps for Wholesalers</span></li>
        </ul>
      </div>
    </div>
  </div>
</section>

<div className="prod-des container">
  <div className="prod-head">
    <h5 style={{ color: "#4a12f0" }}>Streamline Order processing seamlessly</h5>
    <h2 style={{ color: "#001e60" }}>Optimize wholesale operations efficiently.</h2>
  </div>
  <div className="benifits mx-auto">
    <div className="col-md-3 col-12 ben-ai">
      <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/growsuite.png" alt="" />
      <h3 style={{ color: "#001e60" }}>Streamlined Order Processing</h3>
      <p>An app can automate and simplify the order management process, reducing errors and saving time.</p>
    </div>
    <div className="col-md-3 col-12 ben-ai">
      <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/growsuiteimage.png" alt="" />
      <h3 style={{ color: "#001e60" }}>Real-Time Inventory Management</h3>
      <p>It provides up-to-date inventory tracking, helping wholesalers manage stock levels and avoid shortages or overstock situations.</p>
    </div>
    <div className="col-md-3 col-12 ben-ai">
      <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/growsuiteimages.png" alt="" />
      <h3 style={{ color: "#001e60" }}>Improved Customer Relationship Management (CRM)</h3>
      <p>The app can help manage customer interactions and data more effectively, fostering better relationships and improving customer satisfaction.</p>
    </div>
  </div>
</div>

<div className="usecase-all">
  <div className="usecase-head">
    <h5 style={{ color: "#4a12f0" }}>Use Cases</h5>
    <h2 style={{ color: "#001e60" }}>Manage Every Endpoint in One Place</h2>
    <h5 style={{ color: "#28334a" }}>Automate and track orders efficiently</h5>
  </div>
  <div className="row container pt-5 mx-auto">
    <div className="col-md-6 col-12">
      <div className="useimg">
        <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/post.jpg" alt="" style={{ height: "100%", width: "90%" }} />
      </div>
    </div>
    <div className="col-12 col-md-6 mt-5">
      <div className="row" id="row1">
        <h3>Order Management</h3>
        <p>Automating order processing, tracking, and fulfillment.</p>
      </div>
      <div className="row" id="row2">
        <h3>Inventory Control</h3>
        <p>Real-time tracking of stock levels and automatic restocking alerts.</p>
      </div>
      <div className="row" id="row3">
        <h3>Customer Management</h3>
        <p>Managing customer data, order history, and personalized offers.</p>
      </div>
      <div className="row" id="row4">
        <h3>Sales Analytics</h3>
        <p>Providing insights and reports on sales performance and trends.</p>
      </div>
     
    </div>
  </div>
</div>

<div className="ready mt-5 pb-5 container">
  <div className="ready-des">
    <h3 style={{ color: "#001e60" }}>Ready to Get Started?</h3>
    <h5 style={{ color: "#28334a" }}>Experience device management, made easy!</h5>
    <div className="contact-us">
      <button type="button">Contact Us <i className="fa-solid fa-arrow-right"></i></button>
    </div>
  </div>
</div>
<Pagesblog/>

<Footer/>
    </div>
  )
}

export default Appwholesaler
