import React from 'react'

const Pagesblog = () => {
  return (
    <div className="container mx-auto">
       <div className="latest-news pt-5 mb-5">
      <h3 style={{ color: '#4a12f0', textAlign: 'center', paddingBottom: '30px' }}>Latest News</h3>
      <div className="container">
        <div className="row">
          <div className="col-md-7 col-12">
            <div className="latest-img-all">
              <img
                src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/post.jpg" // Use require or an absolute URL for images
                alt="Latest News Post"
                style={{ height: '85%', width: '50%' }}
              />
              <div className="img-des">
                <h5 style={{ color: '#4a12f0' }}>Blog</h5>
                <h3 style={{ color: '#001e60' }}>Why Your Business Needs a Website Development Company in 2024</h3>
                <p style={{ color: '#28334a' }}></p>

                <div className="read-more">
                  <button type="button">Read More &nbsp; &nbsp; <i className="fa-solid fa-arrow-right"></i></button>
                </div>
              </div>
            </div>
          </div>
          
          <div className="col-md-5 col-12">
            <div className="row">
              <div className="blog">
                <h5 style={{ color: '#4a12f0' }}>Blog</h5>
                <h6>Unlock E-Commerce Success: The Ultimate Benefits of Choosing Vilva Ecom SaaS 🔓.</h6>
                <div className="b-btn">
                  <a href="/unlockecom">
                    <button type="button">Read More &nbsp; &nbsp; <i className="fa-solid fa-arrow-right"></i></button>
                  </a>
                  <i className="fa-solid fa-newspaper" style={{ color: '#616899', fontSize: '20px' }}></i>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="blog">
                <h5 style={{ color: '#4a12f0' }}>Blog</h5>
                <h6>The All-in-One E-Commerce Solution: Why Vilva Ecom SaaS is Your Best Choice 🛒</h6>
                <div className="b-btn">
                  <a href="/bestecomsolution">
                    <button type="button">Read More &nbsp; &nbsp; <i className="fa-solid fa-arrow-right"></i></button>
                  </a>
                  <i className="fa-solid fa-newspaper" style={{ color: '#616899', fontSize: '20px' }}></i>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="blog">
                <h5 style={{ color: '#4a12f0' }}>Blog</h5>
                <h6>Vilva Ecom SaaS vs Shopify: Discover the Superior E-Commerce Solution for Your Business ⚖️</h6>
                <div className="b-btn">
                  <a href="/vilvaecomvsshopify">
                    <button type="button">Read More &nbsp; &nbsp; <i className="fa-solid fa-arrow-right"></i></button>
                  </a>
                  <i className="fa-solid fa-newspaper" style={{ color: '#616899', fontSize: '20px' }}></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default Pagesblog
