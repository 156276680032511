import React, { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "./registration.css";
import vbreg from "./images/vbreg.png";

import OTPInput, { ResendOTP } from "otp-input-react";

const Verifyaccount = () => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault(); 

    // Prepare the FormData payload
    const formData = new FormData();
    formData.append("email", email);
    formData.append("otp", otp);

    try {
      const response = await axios.post(
        "https://vilvabusiness.com/api/verify-otp",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log(response.data); 
    } catch (error) {
      console.error(error); 
    }
  };

  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    if (/^\d$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (index < otp.length - 1) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    } else if (value === "") {
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);
    }
  };

  const handleOtpKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "") {
      if (index > 0) {
        document.getElementById(`otp-input-${index - 1}`).focus();
      }
    }
  };

  return (
    <div>
      <Navbar />
      <div className="row container mx-auto py-5">
        <div className="col-md-6 col-12">
          <div className="verify-account">
            <div className="otp-form">
              <img src={vbreg} alt="" srcSet="" />

              <h2>Start Your Account Today!</h2>
              <form onSubmit={handleSubmit}>
                <div>
                  <label>
                    Email{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="9"
                      viewBox="0 0 10 9"
                      fill="none"
                    >
                      <path
                        d="M6.39133 0.0171509L5.96201 3.50769L9.28922 2.05542L9.96002 3.99178L6.39133 4.60326L8.83307 7.12562L7.1158 8.34858L5.34487 5.2657L3.57394 8.34858L1.82984 7.12562L4.27158 4.60326L0.702881 3.99178L1.37369 2.05542L4.70089 3.50769L4.27158 0.0171509H6.39133Z"
                        fill="#E71111"
                      />
                    </svg>
                  </label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    placeholder="Enter Your Email "
                  />
                </div>
                <div>
                  <label>
                    OTP{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="9"
                      viewBox="0 0 10 9"
                      fill="none"
                    >
                      <path
                        d="M6.39133 0.0171509L5.96201 3.50769L9.28922 2.05542L9.96002 3.99178L6.39133 4.60326L8.83307 7.12562L7.1158 8.34858L5.34487 5.2657L3.57394 8.34858L1.82984 7.12562L4.27158 4.60326L0.702881 3.99178L1.37369 2.05542L4.70089 3.50769L4.27158 0.0171509H6.39133Z"
                        fill="#E71111"
                      />
                    </svg>
                  </label>

               
                  <OTPInput
  value={otp} 
  onChange={setOtp}
  inputStyles={{
    height: "35px",
    width: "35px",
    marginRight: "5px", 
    color: "black",
  }}
  autoFocus
  OTPLength={6}
  otpType="number"
  disabled={false}
  secure={false} 
/>

                </div>
                <button type="submit">Verify OTP</button>
              </form>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-12">
          <div className="form-img">
            <div className="form-img-des">
              <h2>Sign Up to connect with Vilva Business</h2>
              <p>
                Join the Vilva Business community today to unlock exclusive
                features, enjoy personalized services, and stay ahead with the
                latest updates. Sign up now and take the first step towards
                effortless business solutions!
              </p>
            </div>
          </div>
        </div>
        </div>
        <Footer/>
    </div>
  );
};

export default Verifyaccount;
