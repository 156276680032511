import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import Pagesblog from "../blog/pagesblog/Pagesblog"
import { Helmet } from 'react-helmet';

const Kycverification = () => {
  return (
    <div>
       <Helmet>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="keywords"
          content="KYC verification, Know Your Customer, identity verification, compliance solutions, secure verification, digital KYC"
        />
        <meta
          name="description"
          content="Streamline your compliance processes with Vilva Business's KYC Verification solutions, providing secure and efficient identity verification for customer onboarding."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <title>KYC Verification Solutions - Vilva Business</title>
      </Helmet>
      <Navbar/>
      <div className="product-banner">
  <div className="banner-img">
    <div className="prod-des">
      <h2 data-aos="fade-right" data-aos-duration="1500">
        KYC Verification Solutions
      </h2>
      <p style={{color:'white'}}>
      Our KYC Verification Solutions streamline the process of verifying
        customer identities, ensuring compliance with regulatory
        requirements. We offer secure, automated verification methods that
        reduce fraud and enhance trust. With real-time processing and
        accurate data checks, our service provides a seamless experience for
        both businesses and customers. Protect your business while meeting
        compliance standards efficiently with our KYC solutions.
      </p>
    </div>
  </div>
</div>

<div className="service-head container mx-auto py-5">
  <div className="service-head-img" data-aos="zoom-in" data-aos-duration="1500"></div>
  <div className="service-head-content">
    <h2 data-aos="fade-right" data-aos-duration="1500">
      Features of Our KYC Verification Solutions
    </h2>
    <p>
      Our KYC Verification Solutions offer robust features, including
      automated identity checks that ensure quick and accurate verification.
      We provide multi-layered security to protect sensitive customer data,
      reducing the risk of fraud. Real-time processing allows for instant
      verification, enhancing the user experience. Our solution is also
      fully compliant with global regulatory standards, helping businesses
      meet legal requirements effortlessly. Additionally, the system
      integrates seamlessly with existing platforms, making implementation
      smooth and efficient.
    </p>
    <p>
      Our KYC Verification Solutions include advanced biometric verification
      for accurate identity checks and enhanced security. We offer flexible
      integration options that fit seamlessly into your existing systems.
      With comprehensive document validation and real-time fraud detection,
      our service ensures reliable and efficient customer onboarding.
      Customizable workflows streamline the verification process, adapting
      to your specific needs. Our solutions are designed to meet global
      compliance standards while providing a user-friendly experience.
    </p>

    <p><b>01.</b> Biometric Verification</p>
    <p><b>02.</b> Document Validation</p>
    <p><b>03.</b> Real-Time Fraud Detection</p>
    <p><b>04.</b> Seamless Integration</p>
    <p><b>05.</b> Compliance Assurance</p>

    <p>
      Builds confidence among customers by demonstrating a commitment to
      secure and responsible business practices. Effective KYC processes
      show that a business prioritizes the protection of customer
      information. This trust can lead to stronger customer relationships
      and increased loyalty. Ensures adherence to legal and regulatory
      requirements, minimizing the risk of fines and legal issues. By
      following KYC protocols, businesses can avoid penalties associated
      with non-compliance. This adherence helps maintain operational
      integrity and fosters a positive reputation.
    </p>
  </div>
</div>

<div className="technology-we-use container">
  <div className="technology-des">
    <h3>What technology do we use for this service</h3>
    <p>
      Our KYC Verification Solutions leverage cutting-edge technologies,
      including biometric recognition systems, Optical Character Recognition
      (OCR) for document scanning, and artificial intelligence for fraud
      detection. We use secure cloud-based platforms to ensure real-time
      processing and data protection. Additionally, our solutions
      incorporate machine learning algorithms to continuously improve
      verification accuracy and compliance.
    </p>
  </div>
  <div className="technology-img">
    <img
      src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/technology.webp"
      alt=""
      className="img-fluid"
      data-aos="fade-right"
      data-aos-duration="1500"
    />
  </div>
</div>

<div className="recent-project container mx-auto">
  <h2>Our Recent Projects</h2>

  <div className="row container mx-auto">
    <div className="col-md-6 col-12">
      <div className="recent-img">
        <img
          src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/1.webp"
          alt=""
          className="img-fluid"
          data-aos="zoom-in"
          data-aos-duration="1500"
        />
        <div className="recent-img-des">
          <h4>Project Name</h4>
          <p>Description about the project</p>
        </div>
      </div>
    </div>
    <div className="col-md-6 col-12">
      <div className="recent-img">
        <img
          src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/2.webp"
          alt=""
          className="img-fluid"
          data-aos="zoom-in"
          data-aos-duration="1500"
        />
        <div className="recent-img-des">
          <h4>Project Name</h4>
          <p>Description about the project</p>
        </div>
      </div>
    </div>
  </div>

  <div className="row container mx-auto">
    <div className="col-md-6 col-12">
      <div className="recent-img">
        <img
          src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/3.webp"
          alt=""
          className="img-fluid"
          data-aos="zoom-in"
          data-aos-duration="1500"
        />
        <div className="recent-img-des">
          <h4>Project Name</h4>
          <p>Description about the project</p>
        </div>
      </div>
    </div>
    <div className="col-md-6 col-12">
      <div className="recent-img">
        <img
          src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/4.webp"
          alt=""
          className="img-fluid"
          data-aos="zoom-in"
          data-aos-duration="1500"
        />
        <div className="recent-img-des">
          <h4>Project Name</h4>
          <p>Description about the project</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div className="client-testimonial container mx-auto py-5">
  <div className="client-testimonial-des">
    <h2>What our clients say about our services</h2>
  </div>
  <div className="client-testimonial">
    <div className="test-img">
      <div className="test-img-des">
        <p>
          The KYC verification service has streamlined our onboarding process with accurate, real-time identity checks. The integration was seamless, and the added security has significantly reduced our risk of fraud.
        </p>
      </div>
    </div>
  </div>
</div>

<div className="get-in-touch container mx-auto pt-5" data-aos="zoom-in" data-aos-duration="1500">
  <h2>Get in touch</h2>
  <form action="{{ url('data') }}" method="POST" onSubmit="return validateRecaptcha()">
    @csrf
    <input type="text" name="honeypot" id="honeypot" style={{ display: 'none' }} />
    <input type="hidden" name="source" value="6" />
    <div className="row">
      <div className="col-md-6 col-12">
        <label htmlFor="name">Full Name </label> <br />
        <input
          type="text"
          id="name"
          name="name"
          placeholder="Your full name"
          required
        />
      </div>

      <div className="col-md-6 col-12">
        <label htmlFor="email">Your email </label> <br />
        <input
          type="text"
          id="email"
          name="email"
          placeholder="example@yourmail.com"
          required
        />
      </div>
    </div>
    <div className="row mt-2">
      <div className="col-md-6 col-12">
        <label htmlFor="company"> Company </label> <br />
        <input
          type="text"
          id="company"
          name="company"
          placeholder="Your company name here"
          required
        />
      </div>
      <div className="col-md-6 col-12">
        <label htmlFor="subject">Subject </label> <br />
        <input
          type="text"
          id="subject"
          name="subject"
          placeholder="How can we Help"
          required
        />
      </div>
      <div className="col-md-6 col-12">
        <label htmlFor="phone">Mobile Number </label> <br />
        <input
          type="text"
          id="phone"
          name="phone"
          placeholder="Your Mobile Number"
          required
        />
      </div>
      <div className="col-md-6 col-12">
        <label htmlFor="city">City</label> <br />
        <input
          type="text"
          id="city"
          name="city"
          placeholder="Your City"
          required
        />
      </div>
      <div className="col-md-6 col-12">
        <label htmlFor="postcode">PostCode</label> <br />
        <input
          type="text"
          id="postcode"
          name="postcode"
          placeholder="Your PostCode"
          required
        />
      </div>
    </div>
    <div className="row mt-2">
      <div className="col-md-12 col-12">
        <label htmlFor="message">Message </label> <br />
        <textarea
          id="message"
          name="description"
          placeholder="Hello there, I would like to talk about how to..."
          required
        ></textarea>
      </div>
    </div>
    <div className="g-recaptcha" data-sitekey="6Lf9iEYqAAAAAGBkHMfKHyrXoDP3tyX_yZ8cL9b-"></div>

    <button type="submit">Send Message</button>
  </form>
</div>
<Pagesblog/>

<Footer/>
    </div>
  )
}

export default Kycverification
