import React, { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "./registration.css";
import vbreg from "./images/vbreg.png";

const Registration = () => {
  // Define state variables for each input field
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevents the page from reloading

    // Prepare the data payload
    const data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      mobile: mobile,
      password: password,
    };

    try {
      // Send a POST request using axios
      const response = await axios.post(
        "https://clientconnect.vilvabusiness.com/api/customer/register",
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log(response.data); // Handle the response data
    } catch (error) {
      console.error(error); // Handle error
    }
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const [otp, setOtp] = useState(["", "", "", "", "", ""]);

  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    if (/^\d$/.test(value)) {
      // Allow only a single digit
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move to the next input if it's not the last one
      if (index < otp.length - 1) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    } else if (value === "") {
      // Clear input and stay on the current box if input is empty
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);
    }
  };

  const handleOtpKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "") {
      // Move focus to the previous input if empty and backspace is pressed
      if (index > 0) {
        document.getElementById(`otp-input-${index - 1}`).focus();
      }
    }
  };

  return (
    <div>
      <Navbar />
      <div className="registration row container mx-auto ">
        <div className="col-md-6 col-12">
          <div className="reg-form">
            <div className="reg-form-all">
              <img src={vbreg} alt="" srcSet="" />

              <h2>Start Your Account Today!</h2>
              <form onSubmit={handleSubmit}>
                {/* Name Field */}
                <div>
                  <label htmlFor="name">
                    First Name{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="9"
                      viewBox="0 0 10 9"
                      fill="none"
                    >
                      <path
                        d="M6.39133 0.0171509L5.96201 3.50769L9.28922 2.05542L9.96002 3.99178L6.39133 4.60326L8.83307 7.12562L7.1158 8.34858L5.34487 5.2657L3.57394 8.34858L1.82984 7.12562L4.27158 4.60326L0.702881 3.99178L1.37369 2.05542L4.70089 3.50769L4.27158 0.0171509H6.39133Z"
                        fill="#E71111"
                      />
                    </svg>
                  </label>
                  <input
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                    placeholder="Enter Your First Name"
                  />
                </div>
                <div>
                  <label htmlFor="name">
                    Last Name{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="9"
                      viewBox="0 0 10 9"
                      fill="none"
                    >
                      <path
                        d="M6.39133 0.0171509L5.96201 3.50769L9.28922 2.05542L9.96002 3.99178L6.39133 4.60326L8.83307 7.12562L7.1158 8.34858L5.34487 5.2657L3.57394 8.34858L1.82984 7.12562L4.27158 4.60326L0.702881 3.99178L1.37369 2.05542L4.70089 3.50769L4.27158 0.0171509H6.39133Z"
                        fill="#E71111"
                      />
                    </svg>
                  </label>
                  <input
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                    placeholder="Enter Your Last Name"
                  />
                </div>

                {/* Email Field */}
                <div>
                  <label htmlFor="email">
                    Email{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="9"
                      viewBox="0 0 10 9"
                      fill="none"
                    >
                      <path
                        d="M6.39133 0.0171509L5.96201 3.50769L9.28922 2.05542L9.96002 3.99178L6.39133 4.60326L8.83307 7.12562L7.1158 8.34858L5.34487 5.2657L3.57394 8.34858L1.82984 7.12562L4.27158 4.60326L0.702881 3.99178L1.37369 2.05542L4.70089 3.50769L4.27158 0.0171509H6.39133Z"
                        fill="#E71111"
                      />
                    </svg>
                  </label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    placeholder="Enter Your Email"
                  />
                </div>

                {/* Mobile Field */}
                <div>
                  <label htmlFor="mobile">
                    Mobile{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="9"
                      viewBox="0 0 10 9"
                      fill="none"
                    >
                      <path
                        d="M6.39133 0.0171509L5.96201 3.50769L9.28922 2.05542L9.96002 3.99178L6.39133 4.60326L8.83307 7.12562L7.1158 8.34858L5.34487 5.2657L3.57394 8.34858L1.82984 7.12562L4.27158 4.60326L0.702881 3.99178L1.37369 2.05542L4.70089 3.50769L4.27158 0.0171509H6.39133Z"
                        fill="#E71111"
                      />
                    </svg>
                  </label>
                  <input
                    type="tel"
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                    required
                    placeholder="Enter Your Mobile Number"
                  />
                </div>

                {/* Password Field */}
                <div>
                  <label htmlFor="password">
                    Password{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="9"
                      viewBox="0 0 10 9"
                      fill="none"
                    >
                      <path
                        d="M6.39133 0.0171509L5.96201 3.50769L9.28922 2.05542L9.96002 3.99178L6.39133 4.60326L8.83307 7.12562L7.1158 8.34858L5.34487 5.2657L3.57394 8.34858L1.82984 7.12562L4.27158 4.60326L0.702881 3.99178L1.37369 2.05542L4.70089 3.50769L4.27158 0.0171509H6.39133Z"
                        fill="#E71111"
                      />
                    </svg>
                  </label>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    placeholder="Enter Your Passsword"
                  />
                </div>
                <p>
                  * Choose Password Atleast 8 Digits (Upper case, Lower Case,
                  Special Characters)
                </p>

                <button type="submit">Sign Up</button>
              </form>
              <div className="account-creation">
                <h5>
                  Already have an account?{" "}
                  <span>
                    <a href="/sigin">Login Here</a>
                  </span>{" "}
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-12 mb-3 mb-md-0 ">
          <div className="form-img">
            <div className="form-img-des">
              <h2>Sign Up to connect with Vilva Business</h2>
              <p>
                Join the Vilva Business community today to unlock exclusive
                features, enjoy personalized services, and stay ahead with the
                latest updates. Sign up now and take the first step towards
                effortless business solutions!
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default Registration;
