import React from "react";
import "./blog.css";
import { Link } from "react-router-dom";
import Navbar from '../Navbar';
import Footer  from '../Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';


const Blogindex = () => {
  return (
    <div>
      <>
      <Navbar/>
        <head>
          <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
          <meta
            name="keywords"
            content="Vilva Business, e-commerce platform, SaaS solutions, Vilva Ecom SaaS, KYC technology, API integration, secure verification, advanced integrations, mobile responsiveness, free upgrades"
          />
          <meta
            name="description"
            content="Explore the cutting-edge technology solutions by Vilva Business, including the Vilva Ecom SaaS platform. Discover features like Aadhaar and PAN verification APIs, advanced integrations, mobile responsiveness, and continuous free upgrades for a seamless e-commerce experience."
          />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1"
          />
          <title>Vilva Blog</title>
        </head>
        <body>
          <div className="blog-all">
            {/* Header */}
            <header>
              <div className="banner">
                <div className="container">
                  <h1 className="banner-title">
                    <span>Latest Tech Buzz .</span> Exploring the Tech World
                  </h1>
                  <p>
                    Everything You Need to Know About Technology, News, and
                    Science
                  </p>
                  <form>
                    <input
                      type="text"
                      className="search-input"
                      placeholder="find your related news . . ."
                    />
                    <button type="submit" className="search-btn">
                      <i className="fas fa-search"></i>
                    </button>
                  </form>
                </div>
              </div>
            </header>

            {/* Design Section */}
            <section className="design" id="design">
              <div className="container">
                <div className="title">
                  <h2>Recent AI, Tech & Science World News</h2>
                  <p>recent AI & Tech on the blog</p>
                </div>
                <div className="row">
                  <div className="col-md-4 col-12">
                    <div className="design-content">
                      <div className="design-item">
                        <div className="design-img">
                          <Link to="blog/unlock-ecom">
                            <img
                              src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/art-design-5.webp"
                              alt=""
                            />
                          </Link>
                          <span>
                          <FontAwesomeIcon icon={faHeart} /> &nbsp;22
                          </span>
                          <span>Art & Design</span>
                        </div>
                        <div className="design-title">
                          <Link to="unlock-ecom">
                            E-commerce Domination: 10 Vilva Ecom SaaS Features
                            That Will Transform Your Business.
                          </Link>
                          <button
                            className="learnmore-blog"
                            onClick={() =>
                              (window.location.href =
                                "blog/ecommerce-domination-vilva-ecom-saas-features")
                            }
                          >
                            Learn More
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-12">
                    <div className="design-content">
                      <div className="design-item">
                        <div className="design-img">
                          <Link to="blog/unlock-ecom">
                            <img
                              src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/art-design-5.webp"
                              alt=""
                            />
                          </Link>
                          <span>
                          <FontAwesomeIcon icon={faHeart} /> &nbsp;22
                          </span>
                          <span>Art & Design</span>
                        </div>
                        <div className="design-title">
                          <Link to="/blog/ecommerce-domination-vilva-ecom-saas-features">
                          The All-in-One E-Commerce Solution: Why Vilva Ecom SaaS is Your Best Choice 🛒
                          </Link>
                          <button
                            className="learnmore-blog"
                            onClick={() =>
                              (window.location.href =
                                "blog/ecommerce-domination-vilva-ecom-saas-features")
                            }
                          >
                            Learn More
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                  <div className="col-md-4 col-12">
                    <div className="design-content">
                      <div className="design-item">
                        <div className="design-img">
                          <Link to="blog/unlock-ecom">
                            <img
                              src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/art-design-5.webp"
                              alt=""
                            />
                          </Link>
                          <span>
                          <FontAwesomeIcon icon={faHeart} /> &nbsp;22
                          </span>
                          <span>Art & Design</span>
                        </div>
                        <div className="design-title">
                          <Link to="/blog/vilva-ecom-saas-vs-shopify-best-ecommerce-solution">
                          Vilva Ecom SaaS vs. Shopify: Discover the Superior Solution for Your Business⚖️
                          </Link>
                          <button
                            className="learnmore-blog"
                            onClick={() =>
                              (window.location.href =
                                "blog/ecommerce-domination-vilva-ecom-saas-features")
                            }
                          >
                            Learn More
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 col-12">
                    <div className="design-content">
                      <div className="design-item">
                        <div className="design-img">
                          <Link to="blog/unlock-ecom">
                            <img
                              src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/art-design-5.webp"
                              alt=""
                            />
                          </Link>
                          <span>
                          <FontAwesomeIcon icon={faHeart} /> &nbsp;22
                          </span>
                          <span>Art & Design</span>
                        </div>
                        <div className="design-title">
                          <Link to="/blog/vilva-ecom-saas-vs-magento-ultimate-e-commerce-showdown">
                          Vilva Ecom SaaS vs. Magento: The Ultimate Battle for E-Commerce Dominance 🥇
                          </Link>
                          <button
                            className="learnmore-blog"
                            onClick={() =>
                              (window.location.href =
                                "blog/ecommerce-domination-vilva-ecom-saas-features")
                            }
                          >
                            Learn More
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-12">
                    <div className="design-content">
                      <div className="design-item">
                        <div className="design-img">
                          <Link to="blog/unlock-ecom">
                            <img
                              src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/art-design-5.webp"
                              alt=""
                            />
                          </Link>
                          <span>
                          <FontAwesomeIcon icon={faHeart} /> &nbsp;22
                          </span>
                          <span>Art & Design</span>
                        </div>
                        <div className="design-title">
                          <Link to="/blog/break-free-magento-limitations-vilva-ecom-saas">
                          Is Magento Holding You Back? Discover How Vilva Ecom SaaS Leads the Way 🚀
                          </Link>
                          <button
                            className="learnmore-blog"
                            onClick={() =>
                              (window.location.href =
                                "blog/ecommerce-domination-vilva-ecom-saas-features")
                            }
                          >
                            Learn More
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                  <div className="col-md-4 col-12">
                    <div className="design-content">
                      <div className="design-item">
                        <div className="design-img">
                          <Link to="blog/unlock-ecom">
                            <img
                              src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/art-design-5.webp"
                              alt=""
                            />
                          </Link>
                          <span>
                          <FontAwesomeIcon icon={faHeart} /> &nbsp;22
                          </span>
                          <span>Art & Design</span>
                        </div>
                        <div className="design-title">
                          <Link to="/blog/break-free-woocommerce-limitations-vilva-ecom-saas">
                          From WooCommerce to Vilva Ecom SaaS: Why the Switch Makes Sense 🔄 <br />
                          </Link>
                          <button
                            className="learnmore-blog"
                            onClick={() =>
                              (window.location.href =
                                "blog/ecommerce-domination-vilva-ecom-saas-features")
                            }
                          >
                            Learn More
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* Additional Section */}
            <section className="design" id="design">
              <div className="container">
                <div className="title">
                  <h2>Latest Blog</h2>
                  <p>recent AI & Tech on the blog</p>
                </div>
                <div className="row container mx-auto">
                  <div className="col-md-4 col-12">
                    <div className="design-content">
                      <div className="design-item">
                        <div className="design-img">
                          <img
                            src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/art-design-5.webp"
                            alt=""
                          />
                          <span>
                          <FontAwesomeIcon icon={faHeart} /> &nbsp;22
                          </span>
                          <span>Art & Design</span>
                        </div>
                        <div className="design-title">
                          <Link to="/blog/integrate-meta-commerce-manager-ecommerce-vilva-ecom">
                            Maximize Your Ecommerce Potential: Integrating Meta
                            Commerce Manager with Your Website
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-12">
                    <div className="design-content">
                      <div className="design-item">
                        <div className="design-img">
                          <img
                            src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/art-design-5.webp"
                            alt=""
                          />
                          <span>
                          <FontAwesomeIcon icon={faHeart} /> &nbsp;22
                          </span>
                          <span>Art & Design</span>
                        </div>
                        <div className="design-title">
                          <Link to="/blog/vilva-ecom-saas-ecommerce-game-changer">
                          Vilva Ecom SaaS is the E-Commerce Game-Changer You've Been Waiting For
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div><div className="col-md-4 col-12">
                    <div className="design-content">
                      <div className="design-item">
                        <div className="design-img">
                          <img
                            src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/art-design-5.webp"
                            alt=""
                          />
                          <span>
                          <FontAwesomeIcon icon={faHeart} /> &nbsp;22
                          </span>
                          <span>Art & Design</span>
                        </div>
                        <div className="design-title">
                          <Link to="/blog/how-meesaikarin-achieved-lakhs-in-sales-using-vilva-ecom-saas">
                          From Zero to Lakhs in 5 Days: How Meesakar.in Skyrocketed Sales with Vilva Ecom SaaS
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row container mx-auto">
                  <div className="col-md-4 col-12">
                    <div className="design-content">
                      <div className="design-item">
                        <div className="design-img">
                          <img
                            src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/art-design-5.webp"
                            alt=""
                          />
                          <span>
                          <FontAwesomeIcon icon={faHeart} /> &nbsp;22
                          </span>
                          <span>Art & Design</span>
                        </div>
                        <div className="design-title">
                          <Link to="/blog/break-free-woocommerce-limitations-vilva-ecom-saas">
                          From WooCommerce to Vilva Ecom SaaS: Why the Switch Makes Sense 🔄
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-12">
                    <div className="design-content">
                      <div className="design-item">
                        <div className="design-img">
                          <img
                            src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/art-design-5.webp"
                            alt=""
                          />
                          <span>
                          <FontAwesomeIcon icon={faHeart} /> &nbsp;22
                          </span>
                          <span>Art & Design</span>
                        </div>
                        <div className="design-title">
                          <Link to="/blog/vilva-ecom-saas-vs-magento-ultimate-e-commerce-showdown">
                          Vilva Ecom SaaS vs. Magento: The Ultimate Battle for E-Commerce Dominance 🥇
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div><div className="col-md-4 col-12">
                    <div className="design-content">
                      <div className="design-item">
                        <div className="design-img">
                          <img
                            src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/art-design-5.webp"
                            alt=""
                          />
                          <span>
                          <FontAwesomeIcon icon={faHeart} /> &nbsp;22
                          </span>
                          <span>Art & Design</span>
                        </div>
                        <div className="design-title">
                          <Link to="/blog/break-free-magento-limitations-vilva-ecom-saas">
                          Is Magento Holding You Back? Discover How Vilva Ecom SaaS Leads the Way 🚀
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* About Section */}
            <div className="row blog-about container mb-3">
              <div className="col-md-6 col-12">
                <div className="about-img">
                  <img
                    src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/about-bg.webp"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="about-text-blog">
                  <div className="title">
                    <h2>Art & design is my passion</h2>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Id
                    totam voluptatem saepe eius ipsum nam provident sapiente,
                    natus et vel eligendi laboriosam odit eos temporibus impedit
                    veritatis ut, illo deserunt illum voluptate quis beatae
                    quod. Necessitatibus provident dicta consectetur labore!
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Ipsam corrupti natus, eos quia recusandae voluptatem veniam
                    modi officiis minima provident rem sint porro fuga quos
                    tempora ea suscipit vero velit sed laudantium eaque
                    necessitatibus maxime!
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Scripts */}
          <script src="https://code.jquery.com/jquery-3.5.1.slim.min.js"></script>
          <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js"></script>
          <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>
        </body>
        <Footer/>
      </>
    </div>
  );
};

export default Blogindex;
