import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import Pagesblog from "../blog/pagesblog/Pagesblog"
import { Helmet } from 'react-helmet';

const Wordpress = () => {
  return (
    <div>
            <Helmet>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="keywords"
          content="WordPress solutions, business solutions, website development, WordPress support"
        />
        <meta
          name="description"
          content="Explore WordPress Solutions by Vilva Business, offering comprehensive support and services to enhance your website development and business success."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <title>WordPress Solutions - Vilva Business</title>
      </Helmet>
      <Navbar/>
      <section
  className="page-title bg-overlay-black-60 jarallax"
  data-speed="0.6"
  data-video-src="https://www.youtube.com/watch?v=sJTN0g4Bx0I"
>
  <div className="container">
    <div className="row">
      <div className="col-lg-12">
        <div className="page-title-name">
          <h1>Wordpress Ecommerce Solutions</h1>
          <p>We know the secret of your success</p>
        </div>
        <ul className="page-breadcrumb">
          <li>
            <a href="/">
              <i className="fa fa-home"></i> Home
            </a>
            <i className="fa fa-angle-double-right"></i>
          </li>
          <li>
            <span>Wordpress Ecommerce Solutions</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
<div className="features-layout2">
  <div className="row mx-auto container">
    <div className="col-12 col-md-4 mt-3">
      <div className="layout-col-2">
        <div className="top-bg"></div>
        <div className="imgforcol-test">
          <img
            src="{{ asset('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/layout-1.png') }}"
            alt=""
            srcSet=""
          />
        </div>
        <h3>User-Friendly and Highly Customizable</h3>
        <p>
          WordPress is renowned for its user-friendly interface, making it
          accessible even for those with minimal technical expertise. With
          thousands of themes and plugins available, businesses can easily
          customize their websites to match their brand identity and specific
          needs, ensuring a unique and engaging online presence.
        </p>
      </div>
    </div>
    <div className="col-md-4 col-12">
      <div className="layout-col-2 mt-3">
        <div className="top-bg-c"></div>
        <div className="imgforcol">
          <img
            src="{{ asset('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/layout-2.png') }}"
            alt=""
            srcSet=""
          />
        </div>
        <h3>SEO-Friendly and Performance Optimized</h3>
        <p>
          WordPress is designed with SEO best practices in mind, helping
          websites rank higher in search engine results. Its clean code,
          responsive design, and a variety of SEO plugins ensure that your site
          is optimized for speed and visibility, driving more organic traffic
          and improving user experience.
        </p>
      </div>
    </div>

    <div className="col-md-4 col-12">
      <div className="layout-col-2 mt-3">
        <div className="top-bg"></div>
        <div className="imgforcol-test">
          <img
            src="{{ asset('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/layout-3.png') }}"
            alt=""
            srcSet=""
          />
        </div>
        <h3>Cost-Effective and Scalable</h3>
        <p>
          WordPress offers a cost-effective solution for businesses of all
          sizes, with numerous free and premium themes and plugins available to
          enhance functionality. Its scalability means that as your business
          grows, your website can easily grow with it, accommodating increased
          traffic and more complex requirements without needing a complete
          overhaul.
        </p>
      </div>
    </div>
  </div>
</div>

<div className="start-project mt-5">
  <div className="row mx-auto">
    <div className="col-12 col-md-6 project">
      <img
        src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/project.png"
        alt=""
        className="img-fluid"
        srcSet=""
      />
    </div>
    <div className="col-12 col-md-6 project-des" style={{ color: '#001e60' }}>
      <div className="project-des-con">
        <h2>Why Choose Us?</h2>
        <h4>Custom Design</h4>
        <p>
          Unique, mobile-responsive designs that align with your brand
          identity.
        </p>
        <h4>SEO Optimization</h4>
        <p>
          Built-in SEO best practices to enhance your visibility and rank
          higher on search engines.
        </p>
        <h4>Ongoing Support</h4>
        <p>
          Reliable maintenance and support to ensure your website remains at
          its best.
        </p>
        <div className="layout-btn">
          <button type="button">
            <a href="/contact" target="_blank" style={{ color: 'white' }}>
              Free Consultation
            </a>
            &nbsp;&nbsp;&nbsp; <i className="ri-arrow-right-line"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<Pagesblog/>

<Footer/>
    </div>
  )
}

export default Wordpress
