import React, { useState } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "./registration.css";
import vbreg from "./images/vbreg.png";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify"; // Ensure react-toastify is imported
import "react-toastify/dist/ReactToastify.css";

const Welcomeback = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [isToastDisplayed, setIsToastDisplayed] = useState(false); // Flag for toast display
  const navigate = useNavigate();

  const handleLogin = async () => {
    const data = {
      username: username,
      password: password,
    };

    try {
      const result = await axios.post(
        "https://clientconnect.vilvabusiness.com/api/customer/login",
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setResponse(result.data);
      navigate("/customerpannel");

      const token = result.data.token; // Extract the token
      localStorage.setItem("authToken", token); // Store token in localStorage

      if (!isToastDisplayed) {
        toast.success("Login successful!",{ autoClose: 2500 });
        setIsToastDisplayed(true);
      }
    } catch (err) {
      setError(err);

      if (!isToastDisplayed) {
        toast.error("Login failed. Please check your credentials.",{ autoClose: 2500 });
        setIsToastDisplayed(true);
      }
    }
  };

  return (
    <div>
      <Navbar />
      <div className="welcome_all my-5">
        <div className="row container mx-auto">
          <div className="col-md-6 col-12">
            <div className="welcome-back">
              <img src={vbreg} alt="Welcome Back" />
              <h2>Welcome Back Leader!</h2>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleLogin();
                }}
              >
                <div>
                  <label htmlFor="username">Email / Mobile</label>
                  <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Enter Your Email / Mobile"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter Your Password"
                    required
                  />
                </div>
                <button type="submit" className="loginn">
                  Login
                </button>
                <a href="/verifyaccount">
                <button type="button" className="otp-btn">
                  Login With OTP
                </button>
                </a>
              </form>
              {error && <p style={{ color: 'red' }}>Error: {error.message}</p>}
              {response && (
                <p style={{ color: 'green' }}>Response: {JSON.stringify(response)}</p>
              )}
              <div className="sign-up-sec">
                <h5>
                  Don’t have an account?{" "}
                  <span>
                    <a href="/registration">SignUp Here</a>
                  </span>
                </h5>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="form-img">
              <div className="form-img-des">
                <h2>Sign In to connect with Vilva Business</h2>
                <p>
                  Join the Vilva Business community today to unlock exclusive
                  features, enjoy personalized services, and stay ahead with the
                  latest updates. Sign up now and take the first step towards
                  effortless business solutions!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Welcomeback;
