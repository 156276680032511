import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import Pagesblog from "../blog/pagesblog/Pagesblog"
import { Helmet } from 'react-helmet';

const Smsmarketing = () => {
  return (
    <div>
       <Helmet>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="keywords"
          content="SMS marketing, text message campaigns, mobile marketing, customer engagement, SMS solutions"
        />
        <meta
          name="description"
          content="Explore Vilva Business's SMS marketing services. Boost customer engagement and drive results with our targeted SMS marketing strategies."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <title>SMS Marketing - Vilva Business</title>
      </Helmet>
      <Navbar/>
      <div className="product-banner">
  <div className="banner-img">
    <div className="prod-des">
      <h2 data-aos="fade-right" data-aos-duration="1500">SMS Marketing</h2>
      <p style={{color:'white'}}>
        Our SMS marketing service helps you connect directly with your audience through targeted, timely messages. We offer personalized campaigns that drive engagement, increase conversions, and build customer loyalty. With advanced analytics and segmentation, you can track performance and optimize your outreach. Reach your customers instantly and effectively with our powerful SMS marketing solutions.
      </p>
    </div>
  </div>
</div>

<div className="service-head container mx-auto py-5">
  <div className="service-head-img" data-aos="zoom-in" data-aos-duration="1500"></div>
  <div className="service-head-content">
    <h2 data-aos="fade-right" data-aos-duration="1500">Features of Our SMS Marketing</h2>
    <p>
      Our SMS marketing service offers a range of powerful features to enhance your customer outreach. With targeted campaigns, you can deliver personalized messages to specific audience segments, ensuring higher engagement. Real-time delivery and automated messaging make it easy to maintain consistent communication with your customers. Plus, advanced analytics provide detailed insights into campaign performance, allowing you to optimize your strategy for maximum impact.
    </p>
    <p>
      Our service is designed to help you connect with your audience more effectively. We offer targeted campaigns that deliver personalized messages to the right people at the right time, driving higher engagement rates. With real-time delivery and automated messaging, you can ensure your communications are always timely and relevant. Our advanced analytics provide deep insights into your campaign performance, enabling you to refine your approach for better results. Experience the power of direct, impactful communication with our comprehensive SMS marketing solutions.
    </p>

    <p><b>01.</b> Targeted Campaigns</p>
    <p><b>02.</b> Real-Time Delivery</p>
    <p><b>03.</b> Advanced Analytics</p>
    <p><b>04.</b> Automated Messaging</p>
    <p><b>05.</b> High Engagement</p>

    <p>
      Tailor your messages to specific segments of your audience based on their preferences, behaviors, and demographics. This personalized approach increases relevance and engagement. Use email to build relationships with your audience by providing valuable content, updates, and personalized interactions. This helps establish trust and loyalty. Use email campaigns to drive traffic to your website and boost sales with targeted promotions, product recommendations, and exclusive offers.
    </p>
  </div>
</div>

<div className="technology-we-use container">
  <div className="technology-des">
    <h3>What technology do we use for this service</h3>
    <p>
      Our SMS marketing service utilizes advanced technologies like cloud-based messaging platforms, APIs for seamless integration, and automated scheduling tools. These technologies enable real-time message delivery, precise targeting, and the ability to scale campaigns effortlessly. Additionally, our use of data analytics and segmentation tools ensures that each campaign is optimized for maximum effectiveness and engagement.
    </p>
  </div>
  <div className="technology-img">
    <img
      src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/technology.webp"
      alt=""
      className="img-fluid"
      data-aos="fade-right"
      data-aos-duration="1500"
    />
  </div>
</div>

<div className="recent-project container mx-auto">
  <h2>Our Recent Projects</h2>

  <div className="row container mx-auto">
    <div className="col-md-6 col-12">
      <div className="recent-img">
        <img
          src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/1.webp"
          alt=""
          className="img-fluid"
          data-aos="zoom-in"
          data-aos-duration="1500"
        />
        <div className="recent-img-des">
          <h4>Project Name</h4>
          <p>Description about the project</p>
        </div>
      </div>
    </div>
    <div className="col-md-6 col-12">
      <div className="recent-img">
        <img
          src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/2.webp"
          alt=""
          className="img-fluid"
          data-aos="zoom-in"
          data-aos-duration="1500"
        />
        <div className="recent-img-des">
          <h4>Project Name</h4>
          <p>Description about the project</p>
        </div>
      </div>
    </div>
  </div>

  <div className="row container mx-auto">
    <div className="col-md-6 col-12">
      <div className="recent-img">
        <img
          src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/3.webp"
          alt=""
          className="img-fluid"
          data-aos="zoom-in"
          data-aos-duration="1500"
        />
        <div className="recent-img-des">
          <h4>Project Name</h4>
          <p>Description about the project</p>
        </div>
      </div>
    </div>
    <div className="col-md-6 col-12">
      <div className="recent-img">
        <img
          src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/4.webp"
          alt=""
          className="img-fluid"
          data-aos="zoom-in"
          data-aos-duration="1500"
        />
        <div className="recent-img-des">
          <h4>Project Name</h4>
          <p>Description about the project</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div className="client-testimonial container mx-auto py-5">
  <div className="client-testimonial-des">
    <h2>What Our Clients Say About Our Services</h2>
  </div>
  <div className="client-testimonial">
    <div className="test-img">
      <div className="test-img-des">
        <p>
          Their SMS marketing service has significantly boosted our customer engagement and sales. The process is seamless, and the support team is always responsive and helpful.
        </p>
      </div>
    </div>
  </div>
</div>

<div className="get-in-touch container mx-auto pt-5" data-aos="zoom-in" data-aos-duration="1500">
  <h2>Get in Touch</h2>
  <form action="#" method="POST">
    <div className="row">
      <div className="col-md-6 col-12">
        <label htmlFor="name">Full Name *</label> <br />
        <input
          type="text"
          id="name"
          name="name"
          placeholder="Your full name"
          required
        />
      </div>
      <div className="col-md-6 col-12">
        <label htmlFor="email">Your Email *</label> <br />
        <input
          type="email"
          id="email"
          name="email"
          placeholder="example@yourmail.com"
          required
        />
      </div>
    </div>
    <div className="row mt-2">
      <div className="col-md-6 col-12">
        <label htmlFor="company">Company *</label> <br />
        <input
          type="text"
          id="company"
          name="company"
          placeholder="Your company name here"
          required
        />
      </div>
      <div className="col-md-6 col-12">
        <label htmlFor="subject">Subject *</label> <br />
        <input
          type="text"
          id="subject"
          name="subject"
          placeholder="How can we help?"
          required
        />
      </div>
    </div>
    <div className="row mt-2">
      <div className="col-md-12 col-12">
        <label htmlFor="message">Message *</label> <br />
        <textarea
          id="message"
          name="message"
          placeholder="Hello there, I would like to talk about how to..."
          required
        ></textarea>
      </div>
    </div>
    <button type="submit">Send Message</button>
  </form>
</div>
<Pagesblog/>

<Footer/>
    </div>
  )
}

export default Smsmarketing
