import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./homepage.css";
import Navbar from './Navbar';
import Footer from './Footer';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Helmet } from 'react-helmet';

// import required modules
import {  Pagination,Navigation,Autoplay } from "swiper/modules";
import iconCar from "../pages/images/Iconcar.png"; // Update paths as needed
import sivaImage from "../pages/images/siva.jpg";
import aadhyarImage from "../pages/images/aadhyar.jpg";
import agImage from "../pages/images/ag.jpg";
import ramImage from "../pages/images/ram.jpg";
import thaithingalImage from "../pages/images/thaithingal.jpg";

import { FaCheck } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

const Homepage = () => {
  return (
    <>
    <Helmet>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="keywords"
          content="our product, business products, customer solutions, product support"
        />
        <meta
          name="description"
          content="Explore Vilva Business products designed to enhance your business efficiency. Discover solutions tailored to meet your specific needs."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <title>Vilva Business</title>
      </Helmet>

    <div style={{ overflow: "hidden" }}>
    <Navbar/>

      <div className="banner mt-5">
        <div className="banner-img">
          <div className="ban-des pt-5">
            <h3>
              Innovative <br /> future of digital <br /> world
            </h3>
            <p>
              The innovative future of the digital world promises transformative
              AI-driven experiences and seamless automation, revolutionizing
              connectivity and engagement.
            </p>
            <div className="input">
              <a href="/contactus">
                <button type="button" className="ban-btn">
                  Contact Now
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="welcome row container mx-auto mt-5">
        <div
          className="tech-img col-12 col-md-6"
          data-aos="zoom-in"
          data-aos-duration="1500"
        >
          <div className="tech-img-des">
            <p>The best tech company in the year</p>
          </div>
        </div>
        <div className="tech-des pt-5 mt-4 col-12 col-md-6">
          <p style={{ color: "#497DFF", fontWeight: "600" }} className="v">
            Welcome to Vilva Business
          </p>
          <h1>The best tech company</h1>
          <p>
            Vilva Business stands at the forefront of technology innovation,
            delivering cutting-edge solutions that drive business success.
            Renowned for its excellence, Vilva Business offers unparalleled
            expertise and a comprehensive suite of IT services, solidifying its
            reputation as the best tech company.
          </p>
          <button type="button" className="Read">
            Read more
          </button>
        </div>
      </div>

      <div className="whatwedo pt-1 pt-md-5">
        <div className="container mx-auto pt-1 pt-md-5 row">
          <div className="choose col-12 col-md-5">
            <p style={{ color: "#56FFBD", fontSize: "24px" }}>Why Choose us</p>
            <h1
              data-aos="fade-right"
              data-aos-duration="1000"
              style={{ color: "white" }}
            >
              What we do ?
            </h1>
            <p>
              At Vilva Business, we’re dedicated to shaping the future of
              technology for small and mid-sized businesses. Our commitment to
              providing secure, cost-effective platforms ensures your business
              is protected and empowered with cutting-edge solutions.
            </p>
            <div className="tick" style={{ color: "white" }}>
              <ul className="whatwedo-tick">
                <li>
                  <FaCheck className="check-top" />
                  Secure, Cost-Effective{" "}
                </li>
                <li>
                  <FaCheck className="check-top" />
                  Tech-Enabled Growth
                </li>
                <li>
                  <FaCheck className="check-top" />
                  Tailored Solutions
                </li>
              </ul>
              <ul className="whatwedo-tick ps-0 ps-md-3">
                <li>
                  <FaCheck className="check-top" />
                  Future-Ready{" "}
                </li>
                <li>
                  <FaCheck className="check-top" />
                  Cost-Effective Services
                </li>
                <li>
                  <FaCheck className="check-top" />
                  AI Enhanced
                </li>
              </ul>
            </div>
            <button
              type="button"
              className="see"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              See More
            </button>
          </div>

          <div className="col-12 col-md-7">
            <div className="service-all">
              <div className="service1">
                <div
                  className="service-dif p-4"
                  style={{ background: "#497DFF !important", color: "white" }}
                >
                  <h2>Website Development</h2>
                  <p>
                    At Vilva Networks, we specialize in creating custom,
                    responsive websites tailored to your business needs. Our
                    expert team uses the latest technologies to ensure fast,
                    secure solutions.
                  </p>
                </div>

                <div className="service p-4">
                  <h2>Android/IOS</h2>
                  <p>
                    We offer comprehensive Android and iOS app development
                    services to bring your mobile vision to life. Our skilled
                    developers create seamless, high-performance apps tailored
                    to your requirements.
                  </p>
                </div>
              </div>

              <div className="service2">
                <div className="service p-4">
                  <h2>Digital Marketing</h2>
                  <p>
                    Vilva Networks provides expert digital marketing services
                    designed to boost your online presence and drive business
                    growth using data-driven strategies.
                  </p>
                </div>

                <div className="service p-4">
                  <h2>Ecommerce Solutions</h2>
                  <p>
                    Vilva Networks delivers secure, customizable e-commerce
                    platforms that streamline operations, integrate payment
                    gateways, and enhance customer engagement for business
                    growth.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="visionn row container mx-auto mt-2">
        <div
          className="laptop col-12 col-md-6"
          data-aos="zoom-in"
          data-aos-duration="1500"
        >
          <p className="cooldes" style={{ color: "white" }}>
            Cool <br /> Design
          </p>
          <p className="uiux" style={{ color: "white" }}>
            UI/UX <br /> Design
          </p>
        </div>

        <div className="future-con col-12 col-md-6">
          <p
            style={{
              color: "#497DFF",
              fontFamily: "Urbanist",
              fontSize: "25px",
              fontWeight: 600,
            }}
          >
            Our Vision
          </p>
          <h2 data-aos="fade-left" data-aos-duration="1000">
            We craft a bright future
          </h2>
          <p>
            To become a reliable IT Consulting Partner for Fortune 500 Companies
            by 2030.
          </p>

          <div className="vision-all">
            <div className="vision1 row">
              <div className="vision col-12 col-md-6">
                <h5
                  style={{ color: "#497DFF" }}
                  data-aos="fade-left"
                  data-aos-duration="1000"
                >
                  Our Vision
                </h5>
                <p>
                  Vilva Networks envisions leading the IT consulting industry by
                  delivering innovative, scalable solutions and forging
                  strategic partnerships with Fortune 500 giants by 2030.
                </p>
              </div>

              <div className="vision col-12 col-md-6">
                <h5
                  style={{ color: "#497DFF" }}
                  data-aos="fade-left"
                  data-aos-duration="1000"
                >
                  Our Mission
                </h5>
                <p>
                  Our mission is to craft a brighter future, becoming the go-to
                  consultants for Fortune 500 companies by 2030.
                </p>
              </div>
            </div>

            <div className="vision2 row">
              <div className="vision col-12 col-md-6">
                <h5
                  style={{ color: "#497DFF" }}
                  data-aos="fade-left"
                  data-aos-duration="1000"
                >
                  Our Goal
                </h5>
                <p>
                  Our goal is to innovate and excel, establishing strong
                  alliances with Fortune 500 companies by 2030.
                </p>
              </div>

              <div className="vision col-12 col-md-6">
                <h5
                  style={{ color: "#497DFF" }}
                  data-aos="fade-left"
                  data-aos-duration="1000"
                >
                  Our Aim
                </h5>
                <p>
                  We aim to revolutionize IT consulting, becoming trusted
                  partners for Fortune 500 companies by 2030.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="client-bg">
        <div className="row container clientboxmove mx-auto">
          <div className="client-box col-12 col-md-4 p-5 pb-0">
            <p style={{ color: "#56FFBD", fontSize: "25px" }}>Our Client</p>
            <h6>We Work with Diverse Client Partnerships!</h6>
            <p style={{ color: "white" }}>
              We proudly collaborate with diverse clients across various
              industries, providing innovative solutions.
            </p>
          </div>
          <div className="col-12 col-md-8">
            <div className="client-logo">
              <ul className="horizontal-list d-flex align-items-center justify-content-evenly">
                <li
                  style={{ color: "white", whiteSpace: "nowrap" }}
            className="d-flex flex-wrap justify-content-center gap-2"
                >
                  <img
                    width="85px"
                    src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/client_logo%20(4).png"
                    alt="Arun Excello Logo"
                    className="img-fluid me-2"
                  />
                  Arun Excello
                </li>

                <li
                  style={{ color: "white", whiteSpace: "nowrap" }}
                   className="d-flex flex-wrap justify-content-center align-center gap-2"
                >
                  <img
                    width="85px"
                    src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/client_logo%20(1).png"
                    alt="Print Route Logo"
                    className="img-fluid me-2"
                  />
                  Print Route
                </li>

                <li
                  style={{ color: "white", whiteSpace: "nowrap" }}
                   className="d-flex flex-wrap justify-content-center align-center gap-4"
                >
                  <img
                    width="85px"
                    src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/client_logo%20(2).png"
                    alt="Print Fast Logo"
                    className="img-fluid me-2"
                  />
                  Print Fast
                </li>

                <li
                  style={{ color: "white", whiteSpace: "nowrap" }}
                   className="d-flex flex-wrap justify-content-center align-center gap-2"
                >
                  <img
                    width="85px"
                    src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/client_logo%20(3).png"
                    alt="Meesakar Logo"
                    className="img-fluid me-2"
                  />
                  Meesakar
                </li>
              </ul>
              <div className="client-bg-des d-none d-md-block">
                <p style={{ color: "white", fontFamily: "Montserrat" }}>
                  We have successfully partnered with a diverse range of
                  clients, showcasing our experience and commitment to
                  delivering exceptional results.
                </p>

                <button
                  type="button"
                  className="client-btn"
                  data-aos="fade-left"
                  data-aos-duration="1000"
                >
                  Read More
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="industries py-5 row container mx-auto">
        <div
          className="mobile-img col-12 col-md-5"
          data-aos="zoom-in"
          data-aos-duration="1500"
        ></div>
        <div className="industries-des col-12 col-md-7">
          <p style={{ color: "#497DFF", fontSize: "25px", fontWeight: "600" }}>
            Industries we serve
          </p>
          <p
            style={{
              fontSize: "56px",
              fontWeight: "700",
              lineHeight: "56px",
              color: "#040e56",
            }}
          >
            Why Us?
          </p>
          <p>
            At Vilva Business, we’re dedicated to shaping the future of
            technology for small and mid-sized businesses. Our commitment to
            providing secure, cost-effective platforms ensures that your
            business is not only protected but also empowered with cutting-edge
            solutions.
          </p>
          <ul className="tick-con">
            <li>
              <FaCheck className="check-bottom" /> Secure, Cost-Effective
              Solutions
            </li>
            <li>
              <FaCheck className="check-bottom" /> Tech-Enabled Growth
            </li>
            <li>
              <FaCheck className="check-bottom" /> Future-Ready Innovation
            </li>
            <li>
              <FaCheck className="check-bottom" /> Commitment to Success
            </li>
          </ul>
        </div>
      </div>

      <section>
<div className="testimonials mt-3 pb-5">
      <div className="test-head">
        <p data-aos="fade-left" data-aos-duration="1000">Testimonial</p>
        <h3 style={{ color: "white" }} data-aos="fade-right" data-aos-duration="1000">
          What Our Client Say
        </h3>
      </div>

      <div className="testi-arrows">
        <div className="swiper-button-next1">
        <i className="fa-solid fa-arrow-right" style={{ color: "#040E56" }}> <FontAwesomeIcon color={"#040E56"} icon={faArrowLeft} /></i>
        </div>
        <div className="swiper-button-prev1">
          <i className="fa-solid fa-arrow-right" style={{ color: "#040E56" }}>  <FontAwesomeIcon color={"#040E56"} icon={faArrowRight} /></i>
        </div>
      </div>

      <Swiper
  className="mySwiper pb-5 px-1"
  navigation={{
    nextEl: ".swiper-button-next1",
    prevEl: ".swiper-button-prev1",
  }}
  modules={[Navigation,Autoplay]}
  loop={true}
  autoplay={{
    delay: 3000, // 3000ms (3 seconds) delay between transitions
    disableOnInteraction: false, // Continue autoplay after user interactions
  }}
  breakpoints={{
    1024: {
      slidesPerView: 3.5,
      spaceBetween: 30,
    },
    700: {
      slidesPerView: 1,
      spaceBetween: 50,
    },
  }}
>
        <SwiperSlide>
          <div className="testi-container">
            <img className="py-3" width="35px" src={iconCar} alt="Icon" />
            <p>
              The services provided by Vilva networks are exceptional. They offer 24/7 service
              and ensure accountability and thorough monitoring. The technical team is highly
              interactive, addressing queries promptly and efficiently. Project outlines are
              well-discussed, leading to excellent development outcomes.
            </p>
            <div className="testi-profile d-flex items-center">
              <div className="pe-4">
                <img className="img-fluid vilva_test_monials"  src={sivaImage} alt="Sivasamy" />
              </div>
              <div>
                <p>Sivasamy</p>
                <p>Nalsshoppy</p>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="testi-container">
            <img className="py-3" width="35px" src={iconCar} alt="Icon" />
            <p>
              I give Vilva team a 5/5 rating and have already recommended them to family and
              friends. I will continue to recommend them to everyone. Their technical team is
              supportive, interactive, and always goes above and beyond.
            </p>
            <div className="testi-profile d-flex items-center">
              <div className="pe-4">
                <img className="img-fluid vilva_test_monials"  src={aadhyarImage} alt="Manikandan" />
              </div>
              <div>
                <p>Manikandan</p>
                <p>Adayar Handicrafts</p>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="testi-container">
            <img className="py-3" width="35px" src={iconCar} alt="Icon" />
            <p>
              Vilva Team is building my E-Commerce website and getting great support, understanding
              my requirements. I see good progress with timely updates. Satisfied with their
              amazing service.
            </p>
            <div className="testi-profile d-flex items-center">
              <div className="pe-4">
                <img className="img-fluid vilva_test_monials"  src={agImage} alt="Ananda" />
              </div>
              <div>
                <p>Ananda</p>
                <p>A&G</p>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="testi-container">
            <img className="py-3" width="35px" src={iconCar} alt="Icon" />
            <p>
              Vilva Business excels in hands-on activities, ensuring they are conducted properly.
              Despite encountering technical difficulties, they were promptly resolved.
            </p>
            <div className="testi-profile d-flex items-center">
              <div className="pe-4">
                <img className="img-fluid vilva_test_monials"  src={ramImage} alt="RamDhanapal" />
              </div>
              <div>
                <p>RamDhanapal</p>
                <p>Ayyawins</p>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="testi-container">
            <img className="py-3" width="35px" src={iconCar} alt="Icon" />
            <p>
              The development of the Thaithingal website has experienced significant growth over the
              last decade, thanks to Vilva Networks' commitment.
            </p>
            <div className="testi-profile d-flex items-center">
              <div className="pe-4">
                <img className="img-fluid vilva_test_monials"  src={thaithingalImage} alt="Manikandan" />
              </div>
              <div>
                <p>Manikandan</p>
                <p>Thaithingal</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
</section>

      <div class="intresting">
        <div class="keyboard-img">
            <div class="intresting-des pb-3">
                <p >Want Building Your Future Today! Your journey to success starts with the right partnership!</p>
                <h5>Begin Your Success Story!</h5>
                <button type="button" class="touch-btn" data-aos="fade-left"data-aos-duration="1000">Join Today!</button>
            </div>
        </div>
    </div>
    </div>
    <Footer/>

    </>
  );
};

export default Homepage;
