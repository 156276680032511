import React, { useState } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Helmet } from "react-helmet";

const About = () => {
    const [isVerified, setIsVerified] = useState(false);

    // Function to handle reCAPTCHA validation
    const validateRecaptcha = (token) => {
      if (token) {
        setIsVerified(true);  // reCAPTCHA validated
      } else {
        setIsVerified(false); // reCAPTCHA not validated
      }
    };
  
    // Handle form submission
    const handleSubmit = (e) => {
      e.preventDefault();
      if (isVerified) {
        alert('reCAPTCHA verified. Form is being submitted!');
        // Proceed with form submission logic
      } else {
        alert('Please complete the reCAPTCHA.');
      }
    };
  return (
    <div>
          <Helmet>
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta
        name="keywords"
        content="About Us, Vilva Business, Company Overview, Our Team, Business Philosophy"
      />
      <meta
        name="description"
        content="Learn more about Vilva Business, our mission, vision, and the team dedicated to delivering exceptional solutions. Discover how we drive innovation and growth for our clients."
      />
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      <title>About Us - Vilva Business</title>
    </Helmet>
        <Navbar/>
      <div className="about-us">
    <h1 data-aos="fade-right" data-aos-duration="1500">About Us</h1>
    <p>
        At Vilva Business, we are committed to delivering top-tier services and innovative products that empower <br /> 
        businesses to reach new heights. Our team of experts is dedicated to providing tailored solutions that meet the <br /> 
        unique needs of our clients, whether it's through our comprehensive service offerings or our cutting-edge products. <br /> 
        With a strong focus on quality and customer satisfaction, we strive to be your trusted partner in achieving success. <br /> 
        At Vilva Business, your growth is our priority.
    </p>
</div>

<div className="best-tech container mx-auto">
    <div className="best-row row">
        <div className="col-md-6 col-12">
            <div className="best-tech-img" data-aos="zoom-in" data-aos-duration="1500"></div>
        </div>
        <div className="col-md-6 col-12">
            <div className="best-tech-des">
                <h5>Welcome to Vilva Business</h5>
                <h2 data-aos="fade-right" data-aos-duration="1500">The best tech company</h2>
                <p>
                    We specialize in delivering innovative solutions that drive business success. Our expertise spans across services 
                    and products designed to meet the evolving needs of our clients. With a commitment to excellence, we ensure that 
                    every project is handled with precision and care, helping you stay ahead in today’s competitive market.
                </p>
                <button type="button" className="best-tech-btn" data-aos="fade-right" data-aos-duration="1500">Read More</button>
            </div>
        </div>
    </div>
    
    <div className="projects row container mx-auto">
        <div className="col-md-4 col-12">
            <div className="project-completed">
                <h3 data-aos="fade-right" data-aos-duration="1500">90+ Project Completed</h3>
                <p>
                    With over 500 successful projects, we excel in delivering exceptional results across diverse industries. Our 
                    expertise ensures top-quality solutions and client satisfaction every time.
                </p>
            </div>
        </div>
        <div className="col-md-4 col-12">
            <div className="project-completed">
                <h3>100+ World wide clients</h3>
                <p>
                    Serving over 100 clients globally, we deliver tailored solutions that meet diverse needs across various markets. 
                    Our international experience ensures high-quality service.
                </p>
            </div>
        </div>
        <div className="col-md-4 col-12">
            <div className="project-completed">
                <h3 data-aos="fade-right" data-aos-duration="1500">500+ Company Project</h3>
                <p>
                    We have successfully completed over 500 projects, demonstrating our expertise and commitment to excellence. 
                    Our extensive portfolio reflects our ability to tackle complex challenges.
                </p>
            </div>
        </div>
    </div>
</div>

<div className="history-all">
    <div className="history row container mx-auto">
        <div className="col-md-6 col-12">
            <div className="history-des">
                <h5>Why We do this?</h5>
                <h3 data-aos="fade-right" data-aos-duration="1500">Our History</h3>
                <p>
                    At Vilva Business, we are driven by a passion for innovation and excellence. Our journey began with a vision to 
                    transform industries through cutting-edge technology and unparalleled service. We continuously strive to push 
                    boundaries, embracing challenges and delivering solutions that exceed expectations. Our commitment to quality and 
                    client satisfaction has shaped our legacy and fuels our drive to excel. Every project we undertake is a testament 
                    to our dedication to making a lasting impact.
                </p>
                <button type="button" className="history-btn" data-aos="fade-right" data-aos-duration="1500">See More</button>
            </div>
        </div>
        <div className="col-md-6 col-12">
            <div className="history-img">
                <img className="img-fluid" src={'https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/vr.webp'} alt="" data-aos="zoom-in" data-aos-duration="1500" />
            </div>
        </div>
    </div>
</div>

<div className="our-client container mx-auto pb-5">
    <h3 data-aos="fade-right" data-aos-duration="1500">Our Trustable Clients</h3>
    <div className="client-img">
        {/* <img src={'path-to-your-image/client-logo.webp')} alt="" /> */}
        <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/client.png" alt="" srcset="" />
    </div>
    <p data-aos="fade-right" data-aos-duration="1500">Trusted by the world’s most ambitious teams.</p>
</div>

<div className="our-values pt-5">
    <div className="our-values-des container mx-auto">
        <h3>Our Values</h3>
        <div className="row py-5">
            <div className="col-md-4 col-12">
                <div className="innovation-des">
                    <div className="innovation-img">
                        <img src={('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/Icon.webp')} alt="" data-aos="zoom-in" data-aos-duration="1200" />
                    </div>
                    <h2>Innovation</h2>
                    <p>Building an enterprise doesn't need to be a nightmare or cost your thousands. Felix is purpose-built.</p>
                </div>
            </div>
            <div className="col-md-4 col-12">
                <div className="innovation-des">
                    <div className="innovation-img">
                        <img src={('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/Icon2.webp')} alt="" data-aos="zoom-in" data-aos-duration="1200" />
                    </div>
                    <h2>Growth</h2>
                    <p>Building an enterprise doesn't need to be a nightmare or cost your thousands. Felix is purpose-built.</p>
                </div>
            </div>
            <div className="col-md-4 col-12">
                <div className="innovation-des">
                    <div className="innovation-img">
                        <img src={('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/Icon3.webp')} alt="" data-aos="zoom-in" data-aos-duration="1200" />
                    </div>
                    <h2>Ownership</h2>
                    <p>Building an enterprise doesn't need to be a nightmare or cost your thousands. Felix is purpose-built.</p>
                </div>
            </div>
        </div>
        
        <div className="row py-5">
            <div className="col-md-4 col-12">
                <div className="innovation-des">
                    <div className="innovation-img">
                        <img src={('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/Icon4.webp')} alt="" data-aos="zoom-in" data-aos-duration="1200" />
                    </div>
                    <h2>Team Work</h2>
                    <p>Building an enterprise doesn't need to be a nightmare or cost your thousands. Felix is purpose-built.</p>
                </div>
            </div>
            <div className="col-md-4 col-12">
                <div className="innovation-des">
                    <div className="innovation-img">
                        <img src={('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/commiticon.png')} alt="" data-aos="zoom-in" data-aos-duration="1200" />
                    </div>
                    <h2>Commitment</h2>
                    <p>Building an enterprise doesn't need to be a nightmare or cost your thousands. Felix is purpose-built.</p>
                </div>
            </div>
            <div className="col-md-4 col-12">
                <div className="innovation-des">
                    <div className="innovation-img">
                        <img src={('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/Icon6.webp')} alt="" data-aos="zoom-in" data-aos-duration="1200" />
                    </div>
                    <h2>Positivity</h2>
                    <p>Building an enterprise doesn't need to be a nightmare or cost your thousands. Felix is purpose-built.</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div className="get-in-touch container mx-auto pt-5" data-aos="zoom-in" data-aos-duration="1500">
    <h2>Get in touch</h2>
    <form action="{{ url('data') }}" method="POST" onSubmit={() => validateRecaptcha()}>
        <input type="text" name="honeypot" id="honeypot" style={{ display: 'none' }} />
        <input type="hidden" name="source" value="7" />
        <div className="row">
            <div className="col-md-6 col-12">
                <label htmlFor="name">Full Name </label> <br />
                <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="  &nbsp; &nbsp;Your full name"
                    required />
            </div>
            <div className="col-md-6 col-12">
                <label htmlFor="email">Your email </label> <br />
                <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="  &nbsp; &nbsp;Your email address"
                    required />
            </div>
            <div className="col-md-6 col-12">
                <label htmlFor="number">Phone Number </label> <br />
                <input
                    type="text"
                    id="number"
                    name="number"
                    placeholder="  &nbsp; &nbsp;Your phone number"
                    required />
            </div>
            <div className="col-md-6 col-12">
                <label htmlFor="subject">Subject </label> <br />
                <input
                    type="text"
                    id="subject"
                    name="subject"
                    placeholder="  &nbsp; &nbsp;Subject"
                    required />
            </div>
        </div>
        <div className="row">
            <div className="col-md-12">
                <label htmlFor="message">Message</label> <br />
                <textarea
                    name="message"
                    id="message"
                    cols="30"
                    rows="5"
                    placeholder=" &nbsp;  &nbsp; Message"
                    required></textarea>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12 col-12">
                <div className="g-recaptcha" data-sitekey="Your site key"></div>
            </div>
            <div className="col-md-12 col-12">
                {/* <input type="submit" name="submit" value="Submit" className="send-msg-btn" /> */}
                <button type="button">Send Message</button>
            </div>
        </div>
    </form>
</div>
<Footer/>
    </div>
  )
}

export default About
