// import React, { useRef, useState } from "react";
import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from 'react-helmet';


import Navbar from './Navbar';
import Footer from './Footer';


// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Navigation, Scrollbar, Autoplay, Pagination } from 'swiper/modules';
import "./homepage.css";


const Ecomsaas = () => {

  
  const contentItems = [
    {
      id: "content1",
      title: "Smart Shipping & Payment",
      description:
        "Simplify your e-commerce process with seamless, built-in shipping and payment integrations. Effortlessly manage deliveries and transactions, giving your customers a smooth and reliable shopping experience.",
      imgSrc: "https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/gif1.gif",
    },
    {
      id: "content2",
      title: "CDN Integration",
      description:
        "Speed up your site with fast content delivery through CDN integration. Ensures quicker load times for an improved user experience, especially for customers in diverse geographic locations.",
      imgSrc: "https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/gif2.gif",
    },
    {
      id: "content3",
      title: "Google Product Feed Integration",
      description:
        "Unlock the power of Google with seamless integration across platforms like Google Shopping, Google Merchant Center, and Google Ads. Boost your product visibility, reach more customers, and drive higher sales with real-time data syncing and smart automation.",
      imgSrc: "https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/gif%20(2).gif",
    },
    {
      id: "content4",
      title: "Facebook Product Feed Sync",
      description:
        "Expand your brand’s reach with seamless Facebook integration. Automatically sync your product catalog to showcase your items on Facebook and Instagram, driving more engagement and sales.",
      imgSrc: "https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/gif%20(3).gif",
    },
    {
      id: "content5",
      title: "WhatsApp API Integration",
      description:
        "Connect with your customers instantly using official WhatsApp API integration. Enhance customer service, support, and engagement by providing real-time messaging directly through WhatsApp. With seamless integration, you can send order updates and notifications.",
      imgSrc: "https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/gif%20(4).gif",
    },
    {
      id: "content6",
      title: "Google Merchant Center",
      description:
        "Connect your store to Google Merchant Center for seamless product listing on Google Shopping. Easily manage and optimize your listings to reach more shoppers and drive sales with this powerful integration.",
      imgSrc: "https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/gif%20(5).gif",
    },
    {
      id: "content7",
      title: "Responsive Web Design",
      description:
        "Ensure your website looks and works flawlessly on any device. With mobile-responsive design, your store adapts to different screen sizes, providing an optimized shopping experience on smartphones, tablets, and desktops—keeping customers engaged no matter where they are.",
      imgSrc: "https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/gif%20(1).gif",
    },
  ];


  const [activeIndex, setActiveIndex] = useState(null);

  const faqData = [
    { question: "What is EcomSaaS?", answer: "EcomSaaS is a platform that provides SaaS solutions for e-commerce businesses." },
    { question: "How easy is it to set up?", answer: "It works by offering cloud-based tools that help businesses manage their online store." },
    { question: "Can I customize my store?", answer: "Yes, EcomSaaS allows for extensive customization to fit your brand's needs." },
    { question: "What support options are available?", answer: "We offer 24/7 support through live chat, email, and phone." },
    { question: "How does pricing work?", answer: "Pricing is based on subscription tiers, allowing flexibility as your business grows." },
    { question: "Can I scale my store as I grow?", answer: "Yes, EcomSaaS supports scalability to accommodate growing businesses." },
    { question: "Is EcomSaaS secure?", answer: "EcomSaaS uses the latest security protocols to ensure your data is protected." },
    { question: "Can I scale my store as I grow?", answer: "It works by offering cloud-based tools that help businesses manage their online store." }
  ];

  useEffect(() => {
    const headings = document.querySelectorAll(".new_heading");
    headings.forEach((heading) => {
      heading.addEventListener("click", () => {
        const contentId = heading.getAttribute("data-content");
        const contentElement = document.getElementById(contentId);
        if (contentElement) {
          contentElement.scrollIntoView({ behavior: "smooth", block: "start" });
        } else {
          console.error(`Content for ${contentId} not found.`);
        }
      });
    });
  
    return () => {
      headings.forEach((heading) => {
        heading.removeEventListener("click", handleHeadingClick);
      });
    };
  }, [contentItems]); // Trigger when contentItems or other dependencies change
  


  const [activeContent, setActiveContent] = useState(null);

  // Create refs for each content item
  const contentRefs = useRef({});

  const handleHeadingClick = (id) => {
    setActiveContent(id);
  
    if (contentRefs.current[id]) {
      contentRefs.current[id].scrollIntoView({ behavior: 'smooth' });
    } else {
      console.error(`Element with ID ${id} not found.`);
    }
  };



  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };






  return (
    <div>
       <Helmet>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="keywords"
          content="eCommerce SaaS, online store builder, eCommerce solutions, custom websites, business growth, online retail platform"
        />
        <meta
          name="description"
          content="Transform your business with our eCommerce SaaS platform. Create and manage your online store effortlessly with custom websites tailored to your brand. Drive growth and success in the digital marketplace."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <title>Ecom SaaS Platform - Build Your Online Store with Ease</title>
      </Helmet>
      <Navbar />

      <div className="container-fluid mt-5" style={{ overflow: "hidden" }}>
        <div className="row g-2 g-md-5 p-1 p-md-3">
          <div className="col-md-6 col-12 ecom_main_text">
            <div className="p-1 p-md-5 pb-1">
              <h1>
                Elevate Your Online Store <br /> with Ecom <span>saas</span>{" "}
              </h1>
              <p className="pt-3">
                Unlock the full potential of your e-commerce business and watch
                your sales soar Get Started Now with EcomSaaS where innovation
                meets simplicity.
              </p>
              <a href="" className="ecom-ban-btn">
                Get Started Now
              </a>
            </div>
          </div>
          <div className="col-md-6 col-12 p-0 p-md-4 pt-0 pt-md-4 ">
            <div className="ps-0 ps-md-5">
              {/* <img
                src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/arrow.svg"
                alt="Description of SVG"
                className="mt-5 gstar_img1"
                width="40"
                height="40"
              />
              <img
                className="img-fluid gstar_img2"
                src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/Gstar.webp"
                alt="Gstar Image"
              /> */}
              <img
                className="img-fluid"
                src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/ecombanner.svg"
                alt="Main Banner Image"
              />
            </div>
          </div>
        </div>
      </div>
      <section>
        <div className="partners_swiper-container pb-1">
          <Swiper
            className="container pt-5 swiper-fade-effect"
            modules={[Navigation, Autoplay]}
            autoplay={{
              delay: 0, // Continuous autoplay
              disableOnInteraction: false,
            }}
            speed={3000} // Adjust speed as needed
            loop={true}
            spaceBetween={0}
            slidesPerView={3}
            breakpoints={{
              768: {
                slidesPerView: 3,
                spaceBetween: 0,
              },
              1024: {
                slidesPerView: 6,
                spaceBetween: 0,
              },
            }}
          // scrollbar={{ draggable: false }}
          >
            <SwiperSlide>
              <div className="pay_method_logos">
                <a
                  href="https://business.phonepe.com/register?referral-code=RF2405271212318567147728"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/PhonePe-Logo.wine.webp"
                    className="img-fluid"
                    alt="PhonePe Logo"
                  />
                </a>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="pay_method_logos">
                <a
                  href="https://www.instamojo.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/instamojo-logo.webp"
                    className="img-fluid"
                    alt="Instamojo Logo"
                  />
                </a>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="pay_method_logos">
                <a
                  href="https://www.digitalocean.com/?refcode=08170100c08a&utm_campaign=Referral_Invite&utm_medium=Referral_Program&utm_source=CopyPaste"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/digitalocean-logo-vector%20(1).webp"
                    className="img-fluid"
                    alt="DigitalOcean Logo"
                  />
                </a>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="pay_method_logos">
                <a
                  href="https://www.cloudflare.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/Cloudflare-Logo.wine.webp"
                    className="img-fluid"
                    alt="Cloudflare Logo"
                  />
                </a>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="pay_method_logos">

                <a
                  href="https://app.shiprocket.in/register?rcode=REFzyuiju&utm_source=refer-n-earn&utm_medium=web&utm_campaign=refer-n-earn&utm_term=43763"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/shiprocket-logo-512.webp"
                    className="img-fluid"
                    alt="Shiprocket Logo"
                  />
                </a>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="pay_method_logos">
                <a
                  href="https://ship.nimbuspost.com/?ref=8741"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/nimbuspost.webp"
                    className="img-fluid"
                    alt="Nimbuspost Logo"
                  />
                </a>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="pay_method_logos">
                <a
                  href="https://aws.amazon.com/free/?gclid=CjwKCAjw6c63BhAiEiwAF0EH1OXeW-qVQLVHbnvVlBwaSUSVqVePmK_dJ0052ywokd3oVwgrW9eHJRoCAZ0QAvD_BwE&trk=14a4002d-4936-4343-8211-b5a150ca592b&sc_channel=ps&ef_id=CjwKCAjw6c63BhAiEiwAF0EH1OXeW-qVQLVHbnvVlBwaSUSVqVePmK_dJ0052ywokd3oVwgrW9eHJRoCAZ0QAvD_BwE:G:s&s_kwcid=AL!4422!3!453325184794!e!!g!!amazon%20web%20services!10712784856!111477279811&all-free-tier.sort-by=item.additionalFields.SortRank&all-free-tier.sort-order=asc&awsf.Free%20Tier%20Types=*all&awsf.Free%20Tier%20Categories=*all"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/Amazon_Web_Services-Logo.wine.webp"
                    width="100px"
                    className="img-fluid"
                    alt="Amazon Web Services Logo"
                  />
                </a>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="pay_method_logos">
                <a
                  href="https://www.briskpe.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/briskpe.webp"
                    width="100px"
                    className="img-fluid"
                    alt="Briskpe Logo"
                  />
                </a>
              </div>
            </SwiperSlide>
          </Swiper>

        </div>
      </section>

      <section>
        <div className="all_in_one container-fluid py-4">
          <div className="container">
            <div className="all_in_one_img container p-5">
              <img
                src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/world.webp"
                width="100%"
                alt=""
              />
            </div>
            <div className="all_in_one_text">
              <h2>All In One Platform</h2>
              <p>
                Experience the ultimate all-in-one platform that empowers you to
                effortlessly manage and grow your online business
              </p>
            </div>

            <div className="row px-0 px-md-5 positionchange">
              <div className="col-md-4">
                <div className="sidebar allinone_sidebar">
                  {["Smart Shipping & Payment", "CDN Integration", "Google Product Feed Integration",
                    "Facebook Product Feed Sync", "WhatsApp API Integration",
                    "Google Merchant Center", "Responsive Web Design"].map((item, index) => (
                      <h2
                        key={index}
                        className="new_heading"
                        data-content={`new_content${index + 1}`}
                        onClick={() => handleHeadingClick(`new_content${index + 1}`)} // Handle click
                      >
                        {index + 1}. {item}
                      </h2>
                    ))}
                </div>
              </div>
              <div className="col-md-8">
                <div className="new_content allinone_content">
                  {contentItems.map((item, index) => (
                    <div
                      id={item.id}
                      className="new_content-item"
                      key={index}
                      ref={(el) => (contentRefs.current[item.id] = el)} // Set ref for each content item
                    >
                      <img
                        width="100px"
                        className="img-fluid"
                        src={item.imgSrc}
                        alt={item.title}
                      />
                      <p>{item.title}</p>
                      <p>{item.description}</p>
                      <a href="">
                        Learn More{" "}
                        <FontAwesomeIcon icon={faArrowRight} style={{ color: "#040E56" }} />
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>


          </div>
        </div>

        <div className="e_com_store pt-5">
          <div className="e_com_store_text">
            <h3>Design Your E-Commerce Store</h3>
            <p>
              Bring your vision to life with a fully customizable e-commerce
              store. From layout to branding, design every detail to create a
              unique shopping experience that reflects your brand.
            </p>
          </div>

          <Swiper
            className="container pt-5 mx-auto mySwiper_store"
            modules={[Navigation, Scrollbar, Autoplay]}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            loop={true}
            scrollbar={{ draggable: true }}
            spaceBetween={0}
            slidesPerView={1}
            breakpoints={{
              768: { slidesPerView: 2 },
              1024: { slidesPerView: 3 },
            }}
          >
            <SwiperSlide className="p-2 e_com_products">
              <a href="https://zemdrop.in/" target="_blank" rel="noopener noreferrer">
                <img
                  className="img-fluid"
                  // src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/e_com_products%20(1).webp"
                  src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/Desktop%20-%204.png"

                  alt=""
                />
              </a>
              <center>
                <a href="https://zemdrop.in/" target="_blank" rel="noopener noreferrer" className="view_btn">
                  View Site <FontAwesomeIcon icon={faArrowRight} style={{ color: "#040E56" }} />
                </a>
              </center>
            </SwiperSlide>

            <SwiperSlide className="p-2 e_com_products">
              <a href="https://www.meesakar.in/" target="_blank" rel="noopener noreferrer">
                <img
                  className="img-fluid"
                  src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/Meesakar%20Web%20Ui.png"
                  alt=""
                />
              </a>
              <center>
                <a href="https://www.meesakar.in/" target="_blank" rel="noopener noreferrer" className="view_btn">
                  View Site <FontAwesomeIcon icon={faArrowRight} style={{ color: "#040E56" }} />
                </a>
              </center>
            </SwiperSlide>

            <SwiperSlide className="p-2 e_com_products">
              <a href="https://www.thaithingal.in/" target="_blank" rel="noopener noreferrer">
                <img
                  className="img-fluid"
                  src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/e_com_products%20(3).webp"
                  alt=""
                />
              </a>
              <center>
                <a href="https://www.thaithingal.in/" target="_blank" rel="noopener noreferrer" className="view_btn">
                  View Site <FontAwesomeIcon icon={faArrowRight} style={{ color: "#040E56" }} />
                </a>
              </center>
            </SwiperSlide>

            <SwiperSlide className="p-2 e_com_products">
              <a href="https://aandggiftclub.com/" target="_blank" rel="noopener noreferrer">
                <img
                  className="img-fluid"
                  src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/e_com_products%20(1).webp"
                  alt=""
                />
              </a>
              <center>
                <a href="https://aandggiftclub.com/" target="_blank" rel="noopener noreferrer" className="view_btn">
                  View Site <FontAwesomeIcon icon={faArrowRight} style={{ color: "#040E56" }} />
                </a>
              </center>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>

      <section>
        <div className="our_clients_text pt-5">
          <h3>Trusted by Thousands of Rapidly Growing Businesses Worldwide</h3>
        </div>
        <div className="our_clients_images pt-3 pb-5">
          <div className="partners_">
            <Swiper
              className="container  swiper-fade-effect"
              modules={[Navigation, Autoplay]}
              autoplay={{
                delay: 0, // Continuous autoplay
                disableOnInteraction: false,
              }}
              speed={3000} // Adjust speed as needed
              loop={true}
              spaceBetween={0}
              slidesPerView={3}
              breakpoints={{
                768: {
                  slidesPerView: 3,
                  spaceBetween: 0,
                },
                1024: {
                  slidesPerView: 6,
                  spaceBetween: 0,
                },
              }}
            // scrollbar={{ draggable: true }}
            >
              <SwiperSlide>
                <div className="pay_method_logos">
                  <a
                    href="https://www.thaithingal.in/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/ecom_products%20(3).webp"
                      width="100px"
                      className="img-fluid"
                      alt="Thai Thingal"
                    />
                  </a>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="pay_method_logos">
                  <a
                    href="https://www.magizhhandicrafts.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/ecom_products%20(7).webp"
                      width="100px"
                      className="img-fluid"
                      alt="Magizh Handicrafts"
                    />
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="pay_method_logos">
                  <a
                    href="https://www.meesakar.in/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/ecom_products%20(4).webp"
                      width="100px"
                      className="img-fluid"
                      alt="Meesakar"
                    />
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="pay_method_logos">
                  <a
                    href="https://www.tamiltshirts.in/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/ecom_products%20(6).webp"
                      width="100px"
                      className="img-fluid"
                      alt="Tamil T-Shirts"
                    />
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="pay_method_logos">
                  <a
                    href="https://aandggiftclub.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/ecom_products%20(1).webp"
                      width="100px"
                      className="img-fluid"
                      alt="A and G Gift Club"
                    />
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="pay_method_logos">
                  <a
                    href="https://pickmego.in/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/ecom_products%20(5).webp"
                      width="100px"
                      className="img-fluid"
                      alt="Pick Me Go"
                    />
                  </a>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="pay_method_logos">
                  <a
                    href="https://www.tamiltshirts.in/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/ecom_products%20(6).webp"
                      width="100px"
                      className="img-fluid"
                      alt="Tamil T-Shirts"
                    />
                  </a>
                </div>
              </SwiperSlide>

            </Swiper>



          </div>
        </div>
      </section>

      <section>
        <div className="business_section container mt-2 mx-auto row py-5">
          <h4>One Dashboard for Total Business Control</h4>
          <div className="col-md-6 business_section_text text-center mb-4 mb-md-0">
            <img
              className="img-fluid"
              src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/bussines_ban%20(1).webp"
              alt="For Entrepreneurs"
            />
            <p>For Entrepreneurs</p>
            <span>
              Launch your success with a stunning, custom website designed for
              entrepreneurs. Grow your business today!
            </span>
          </div>
          <div className="col-md-6 business_section_text text-center">
            <img
              className="img-fluid"
              src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/bussines_ban%20(2).webp"
              alt="For Content Creators"
            />
            <p>For Content Creators</p>
            <span>
              Showcase your creativity with a bespoke website designed for
              content creators. Stand out, captivate, and grow your audience
              effortlessly!
            </span>
          </div>
        </div>
      </section>

      <div>
        <Swiper
          className="container-fluid"
          modules={[Navigation, Autoplay]}
          autoplay={{
            delay: 0,
            disableOnInteraction: false,
          }}
          speed={5000}
          loop={true}
          centeredSlides={true}
          spaceBetween={30}
          slidesPerView={1 }
          breakpoints={{
            768: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 1.5,
              spaceBetween: 40,
            },
          }}
        >
          <SwiperSlide>
            <div className="row review_container p-3 p-md-5">
              <div className="col-12 col-md-4">
                <div className="review_image">
                  <img
                    className="img-fluid top-aligned"
                    src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/review_icon%20(2).webp"
                    alt=""
                  />
                  <div className="reviw_customer">
                    <img
                      className="img-fluid top-aligned"
                      src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/review_icon%20(2).webp"
                      alt=""
                    />
                  </div>
                  <img
                    className="img-fluid bottom-aligned"
                    src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/review_icon%20(1).webp"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-12 col-md-8 review_text">
                <div className="row">
                  <div className="col-2">
                    <img
                      className="img-fluid"
                      src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/%E2%80%9C.webp"
                      alt=""
                    />
                  </div>
                  <div className="col-10">
                    <p>
                      Absolutely thrilled with the website design! It’s not just
                      visually stunning, but also incredibly user-friendly and
                      perfectly captures my brand’s essence!
                    </p>
                    <div className="client_star">
                      <i
                        className="fa-solid fa-star"
                        style={{ color: "#fac000" }}
                      ></i>
                      <i
                        className="fa-solid fa-star"
                        style={{ color: "#fac000" }}
                      ></i>
                      <i
                        className="fa-solid fa-star"
                        style={{ color: "#fac000" }}
                      ></i>
                      <i
                        className="fa-solid fa-star"
                        style={{ color: "#fac000" }}
                      ></i>
                      <i
                        className="fa-solid fa-star"
                        style={{ color: "#fac000" }}
                      ></i>
                    </div>
                    <b>Client</b>
                    <p>Meesakar (CEO & Founder)</p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="row review_container p-3 p-md-5">
              <div className="col-12 col-md-4">
                <div className="review_image">
                  <img
                    className="img-fluid top-aligned"
                    src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/review_icon%20(2).webp"
                    alt=""
                  />
                  <div className="reviw_customer">
                    <img
                      className="img-fluid top-aligned"
                      src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/review_icon%20(2).webp"
                      alt=""
                    />
                  </div>
                  <img
                    className="img-fluid bottom-aligned"
                    src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/review_icon%20(1).webp"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-12 col-md-8 review_text">
                <div className="row">
                  <div className="col-2">
                    <img
                      className="img-fluid"
                      src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/%E2%80%9C.webp"
                      alt=""
                    />
                  </div>
                  <div className="col-10">
                    <p>
                      Absolutely thrilled with the website design! It’s not just
                      visually stunning, but also incredibly user-friendly and
                      perfectly captures my brand’s essence!
                    </p>
                    <div className="client_star">
                      <i
                        className="fa-solid fa-star"
                        style={{ color: "#fac000" }}
                      ></i>
                      <i
                        className="fa-solid fa-star"
                        style={{ color: "#fac000" }}
                      ></i>
                      <i
                        className="fa-solid fa-star"
                        style={{ color: "#fac000" }}
                      ></i>
                      <i
                        className="fa-solid fa-star"
                        style={{ color: "#fac000" }}
                      ></i>
                      <i
                        className="fa-solid fa-star"
                        style={{ color: "#fac000" }}
                      ></i>
                    </div>
                    <b>Client</b>
                    <p>Meesakar (CEO & Founder)</p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="row review_container p-3 p-md-5">
              <div className="col-12 col-md-4">
                <div className="review_image">
                  <img
                    className="img-fluid top-aligned"
                    src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/review_icon%20(2).webp"
                    alt=""
                  />
                  <div className="reviw_customer">
                    <img
                      className="img-fluid top-aligned"
                      src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/review_icon%20(2).webp"
                      alt=""
                    />
                  </div>
                  <img
                    className="img-fluid bottom-aligned"
                    src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/review_icon%20(1).webp"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-12 col-md-8 review_text">
                <div className="row">
                  <div className="col-2">
                    <img
                      className="img-fluid"
                      src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/%E2%80%9C.webp"
                      alt=""
                    />
                  </div>
                  <div className="col-10">
                    <p>
                      Absolutely thrilled with the website design! It’s not just
                      visually stunning, but also incredibly user-friendly and
                      perfectly captures my brand’s essence!
                    </p>
                    <div className="client_star">
                      <i
                        className="fa-solid fa-star"
                        style={{ color: "#fac000" }}
                      ></i>
                      <i
                        className="fa-solid fa-star"
                        style={{ color: "#fac000" }}
                      ></i>
                      <i
                        className="fa-solid fa-star"
                        style={{ color: "#fac000" }}
                      ></i>
                      <i
                        className="fa-solid fa-star"
                        style={{ color: "#fac000" }}
                      ></i>
                      <i
                        className="fa-solid fa-star"
                        style={{ color: "#fac000" }}
                      ></i>
                    </div>
                    <b>Client</b>
                    <p>Meesakar (CEO & Founder)</p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="row review_container p-3 p-md-5">
              <div className="col-12 col-md-4">
                <div className="review_image">
                  <img
                    className="img-fluid top-aligned"
                    src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/review_icon%20(2).webp"
                    alt=""
                  />
                  <div className="reviw_customer">
                    <img
                      className="img-fluid top-aligned"
                      src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/review_icon%20(2).webp"
                      alt=""
                    />
                  </div>
                  <img
                    className="img-fluid bottom-aligned"
                    src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/review_icon%20(1).webp"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-12 col-md-8 review_text">
                <div className="row">
                  <div className="col-2">
                    <img
                      className="img-fluid"
                      src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/%E2%80%9C.webp"
                      alt=""
                    />
                  </div>
                  <div className="col-10">
                    <p>
                      Absolutely thrilled with the website design! It’s not just
                      visually stunning, but also incredibly user-friendly and
                      perfectly captures my brand’s essence!
                    </p>
                    <div className="client_star">
                      <i
                        className="fa-solid fa-star"
                        style={{ color: "#fac000" }}
                      ></i>
                      <i
                        className="fa-solid fa-star"
                        style={{ color: "#fac000" }}
                      ></i>
                      <i
                        className="fa-solid fa-star"
                        style={{ color: "#fac000" }}
                      ></i>
                      <i
                        className="fa-solid fa-star"
                        style={{ color: "#fac000" }}
                      ></i>
                      <i
                        className="fa-solid fa-star"
                        style={{ color: "#fac000" }}
                      ></i>
                    </div>
                    <b>Client</b>
                    <p>Meesakar (CEO & Founder)</p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>

      {/* test */}

      <section>
        <div className="faq_section py-5 px-2 px-md-0">
          <h2>Frequently Asked Questions</h2>
          {faqData.map((faq, index) => (
            <div key={index} className="faq_questions">
              <div className="question_sec" onClick={() => toggleAnswer(index)}>
                <p>{index + 1}. {faq.question}</p>
                <FontAwesomeIcon
                  icon={activeIndex === index ? faChevronDown : faChevronUp}
                  className={activeIndex === index ? 'rotate' : ''}
                />
              </div>
              <div
                className=""
                style={{
                  height: activeIndex === index ? 'auto' : '0',
                  overflow: 'hidden',
                  transition: 'height 0.3s ease'
                }}
              >
                {activeIndex === index && <p>{faq.answer}</p>}
              </div>
            </div>
          ))}
        </div>
      </section>

      <section>
        <div className="freeedom_section p-3 p-md-5">
          <div className="py-5">
            <h4>More freedom. More sales. Less friction.</h4>
            <p>Ready to take the next step towards ecommerce your way?</p>
            <a href="">Let's Go</a>
            <span>© Copyright 2024 Vilva Business All Rights Reserved</span>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Ecomsaas;