import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import Pagesblog from "../blog/pagesblog/Pagesblog"
import { Helmet } from 'react-helmet';

const Corporatecard = () => {
  return (
    <div>
         <Helmet>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="keywords"
          content="corporate cards, expense cards, business expense management, corporate spending"
        />
        <meta
          name="description"
          content="Discover Vilva Business's corporate cards and expense cards for efficient business expense management. Streamline your corporate spending with our comprehensive card solutions."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <title>Corporate Cards & Expense Cards - Vilva Business</title>
      </Helmet>
      <Navbar/>
      <section 
  className="page-title bg-overlay-black-60 jarallax" 
  data-speed="0.6" 
  data-video-src="https://www.youtube.com/watch?v=QyhwSYhX09s"
>
  <div className="container">
    <div className="row">
      <div className="col-lg-12">
        <div className="page-title-name">
          <h1>Corporate Cards And Expense Cards</h1>
          <p>We know the secret of your success</p>
        </div>
        <ul className="page-breadcrumb">
          <li>
            <a href="/"><i className="fa fa-home"></i> Home</a>
            <i className="fa fa-angle-double-right"></i>
          </li>
          <li><span>Corporate Cards And Expense Cards</span></li>
        </ul>
      </div>
    </div>
  </div>
</section>

<div className="features-layout2">
  <div className="row mx-auto container">
    <div className="col-12 col-md-4">
      <div className="layout-col-2 mt-3">
        <div className="top-bg"></div>
        <div className="imgforcol-test">
          <img src="{{ asset('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/layout-1.png') }}" alt="" />
        </div>
        <h3>Simplified Expense Tracking</h3>
        <p>Corporate and expense cards streamline the process of tracking and managing business expenses by consolidating transactions into one system, making it easier to categorize, review, and report expenditures.</p>
      </div>
    </div>

    <div className="col-md-4 col-12">
      <div className="layout-col-2 mt-3">
        <div className="top-bg-c"></div>
        <div className="imgforcol">
          <img src="{{ asset('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/layout-2.png') }}" alt="" />
        </div>
        <h3>Enhanced Financial Control</h3>
        <p>These cards offer real-time visibility and control over spending with features such as customizable limits, detailed transaction reports, and automated reconciliation, helping businesses maintain budget discipline and financial oversight.</p>
      </div>
    </div>

    <div className="col-md-4 col-12">
      <div className="layout-col-2 mt-3">
        <div className="top-bg"></div>
        <div className="imgforcol-test">
          <img src="{{ asset('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/layout-3.png') }}" alt="" />
        </div>
        <h3>Boosted Operational Efficiency</h3>
        <p>By reducing the need for manual expense reporting and reimbursement processes, corporate and expense cards enhance operational efficiency, allowing employees to make business purchases quickly and ensuring timely payments without administrative delays.</p>
      </div>
    </div>
  </div>
</div>

<div className="start-project mt-5">
  <div className="row mx-auto">
    <div className="col-12 col-md-6 project">
      <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/project.png" alt="" className="img-fluid" />
    </div>
    <div className="col-12 col-md-6 project-des" style={{ color: "#001e60" }}>
      <div className="project-des-con">
        <h2>Start your project with Us today</h2>
        <p>Get started with our cutting-edge e-commerce solutions today and elevate your online business with tailored strategies and innovative technology. Transform your customer experience and drive growth effortlessly!</p>

        <div className="layout-btn">
          <button type="button">Start a Project&nbsp;&nbsp;&nbsp;<i className="ri-arrow-right-line"></i></button>
        </div>
      </div>
    </div>
  </div>
</div>
<Pagesblog/>

<Footer/>
    </div>
  )
}

export default Corporatecard
