import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import Pagesblog from "../blog/pagesblog/Pagesblog"
import { Helmet } from 'react-helmet';

const Magentosolution = () => {
  return (
    <div>
      <Helmet>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="keywords"
          content="Magento solutions, e-commerce, business solutions, online store, Magento support"
        />
        <meta
          name="description"
          content="Explore Magento Solutions by Vilva Business, offering comprehensive support and services to optimize your e-commerce platform for success."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <title>Magento Solutions - Vilva Business</title>
      </Helmet>
      <Navbar/>
      <div className="prod-main-cont">
  <div className="product-banner">
    <div className="banner-img">
      <div className="prod-des">
        <h2 data-aos="fade-down" data-aos-duration="1000">
          Magento Ecommerce Solutions
        </h2>
        <p style={{color:"white"}}>
          Magento's multi-store capability allows businesses to manage multiple online stores from a single administrative dashboard. This feature simplifies the management process, enabling businesses to run different stores with unique catalogs, pricing, and languages, all while maintaining centralized control and consistency. This is particularly beneficial for businesses with multiple brands or those operating in various regions.
        </p>
      </div>
    </div>
  </div>

  <div className="prod-head container mx-auto py-5">
    <div
      className="prod-head-img"
      data-aos="zoom-in"
      data-aos-duration="1500"
    ></div>
    <div className="prod-head-content container">
      <h2>
        {/* <span data-aos="fade-up-right" data-aos-duration="1200">
          Elevate Your Business
        </span>

        with
        <span data-aos="fade-up-right" data-aos-duration="1200">
          Advanced Magento E-Commerce Solutions
        </span> */}
        Elevate Your Business with Advanced Magento E-Commerce Solutions
      </h2>
      <p>
        Magento offers unparalleled customization options and scalability, allowing businesses to tailor their online store to their specific needs and grow seamlessly as their business expands. With a vast array of themes, extensions, and integrations, Magento ensures that your website can evolve with your business, providing a unique and optimized shopping experience for your customers.
      </p>
      <p>
        Magento is renowned for its robust performance capabilities, handling large volumes of traffic and extensive product catalogs without compromising on speed or reliability. Additionally, Magento's advanced security features protect your website from cyber threats, ensuring safe transactions and safeguarding customer data.
      </p>

      <p><b>01.</b> Highly Customizable and Scalable</p>
      <p><b>02.</b> Customized Online Shops</p>
      <p><b>03.</b> Multi-Store Management</p>
      <p><b>04.</b> Inventory Management</p>
      <p><b>05.</b> Robust Performance and Security</p>

      <p>
        Magento provides exceptional flexibility and growth potential, enabling businesses to design a highly customized online store that adapts to their unique requirements. With an extensive selection of themes, extensions, and integrations, Magento supports seamless scaling as your business expands. This platform ensures that your e-commerce site evolves in tandem with your business, delivering a tailored and optimized shopping experience for your customers.
      </p>
    </div>
  </div>

  <div className="client-testimonial container mx-auto py-5">
    <div className="client-testimonial-des">
      <h2 data-aos="fade-left" data-aos-duration="1200">
        What our client say about Magento Ecommerce Services.
      </h2>
    </div>
    <div className="client-testimonial">
      <div className="test-img">
        <div className="test-img-des">
          <p>
            "Magento has revolutionized my online store with its extensive customization and scalability. The platform’s flexibility and range of extensions have streamlined management and supported my business growth seamlessly."
          </p>
        </div>
      </div>
    </div>
  </div>

  <div className="news-letter container mx-auto my-5 pt-4">
    <div className="news-letter-img pt-5">
      <div className="row">
        <div className="col-md-6 col-12">
          <h3>Start your 7-day free trial</h3>
          <p>
            Experience seamless functionality with our platform. Effortlessly customize your workflow.
          </p>
          <div
            className="news-input"
            data-aos="fade-right"
            data-aos-duration="1200"
          >
            <input
              type="text"
              name="email"
              placeholder="Enter your email"
              required
            />
            <button type="button">Start</button>
          </div>
          <div className="radio-btn pt-5">
            <p>
              <i className="fa-solid fa-circle-dot"></i> &nbsp; Free 7-day trial
            </p>
            <p>
              <i className="fa-solid fa-circle-dot"></i> &nbsp; No credit card required
            </p>
            <p>
              <i className="fa-solid fa-circle-dot"></i> &nbsp; Cancel anytime
            </p>
          </div>
        </div>
        <div
          className="col-6 d-none d-md-block"
          data-aos="fade-left"
          data-aos-duration="1500"
        >
          <img
            className="news-img"
            // src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/Image%20(3).webp "
            alt=""
          />
        </div>
      </div>
    </div>
  </div>

  <div
    className="get-in-touch container mx-auto pt-5"
    data-aos="zoom-in"
    data-aos-duration="1500"
  >
    <h2>Get in touch</h2>
    <form action="#" method="POST">
      <div className="row">
        <div className="col-md-6 col-12">
          <label htmlFor="name">Full Name *</label>
          <br />
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Your full name"
            required
          />
        </div>
        <div className="col-md-6 col-12">
          <label htmlFor="email">Your email *</label>
          <br />
          <input
            type="email"
            id="email"
            name="email"
            placeholder="example@yourmail.com"
            required
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-md-6 col-12">
          <label htmlFor="company">Company *</label>
          <br />
          <input
            type="text"
            id="company"
            name="company"
            placeholder="your company name here"
            required
          />
        </div>
        <div className="col-md-6 col-12">
          <label htmlFor="subject">Subject *</label>
          <br />
          <input
            type="text"
            id="subject"
            name="subject"
            placeholder="How can we Help"
            required
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-md-12 col-12">
          <label htmlFor="message">Message *</label>
          <br />
          <textarea
            id="message"
            name="message"
            placeholder="Hello there, I would like to talk about how to..."
            required
          ></textarea>
        </div>
      </div>
      <button type="submit">Send Message</button>
    </form>
  </div>
</div>
<Pagesblog/>

<Footer/>
    </div>
  )
}

export default Magentosolution
