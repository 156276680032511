import React, { useState } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import Pagesblog from "../blog/pagesblog/Pagesblog"
import { Helmet } from "react-helmet";


const Aiml = () => {
    // const [isVerified, setIsVerified] = useState(false);

    // // Function to handle reCAPTCHA validation
    // const validateRecaptcha = (token) => {
    //   if (token) {
    //     setIsVerified(true);  // reCAPTCHA validated
    //   } else {
    //     setIsVerified(false); // reCAPTCHA not validated
    //   }
    // };
  
    // Handle form submission
    // const handleSubmit = (e) => {
    //   e.preventDefault();
    //   if (isVerified) {
    //     alert('reCAPTCHA verified. Form is being submitted!');
    //   } else {
    //     alert('Please complete the reCAPTCHA.');
    //   }
    // };
  return (
    <div>
        <Helmet>
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta
        name="keywords"
        content="Artificial Intelligence, Machine Learning, AI solutions, business software, data-driven growth"
      />
      <meta
        name="description"
        content="Explore cutting-edge AI and Machine Learning solutions by Vilva Business. Empower your organization with innovative tools for smarter decision-making and enhanced growth."
      />
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      <title>Artificial Intelligence & Machine Learning - Vilva Business</title>
    </Helmet>
      <Navbar/>
      <div className="product-banner">
        <div className="banner-img">
          <div className="prod-des">
            <h2 data-aos="fade-right" data-aos-duration="1500">AI/ML</h2>
            <p style={{color:'white'}}>
            We are dedicated to helping innovative businesses harness the power
              of artificial intelligence. Our comprehensive AI services include
              developing bespoke AI models, optimizing existing systems, and
              integrating cutting-edge technologies to streamline operations and
              drive strategic growth.
            </p>
          </div>
        </div>
      </div>

      <div className="service-head container mx-auto py-5">
        <div className="service-head-img" data-aos="zoom-in" data-aos-duration="1500"></div>
        <div className="service-head-content">
          <h2 data-aos="fade-right" data-aos-duration="1500">Features of Our AI/ML</h2>
          <p>
            We specialize in delivering customized machine learning solutions that
            cater to the unique needs of various industries. We offer advanced AI
            and machine learning services designed to propel your business into
            the future. Our team of experts leverages the latest in AI technology
            to provide tailored solutions that drive efficiency, enhance
            decision-making, and unlock new opportunities. From predictive
            analytics and automated processes to advanced data insights and
            personalized recommendations.
          </p>
          <p>
            We excel in providing bespoke AI and machine learning solutions
            tailored to meet the distinct demands of diverse industries. Our
            cutting-edge services harness the power of advanced AI technology to
            elevate your business capabilities. Our expert team specializes in
            crafting solutions that boost operational efficiency, improve
            strategic decisions, and reveal new growth avenues. Whether it's
            through predictive analytics, automated workflows, or insightful data
            analysis, we deliver transformative results that drive your business
            forward.
          </p>

          <p><b>01.</b> Cutting-Edge AI Solutions for Your Business</p>
          <p><b>02.</b> Tailored Machine Learning Solutions for Every Industry</p>
          <p><b>03.</b> Transformative AI Services</p>
          <p><b>04.</b> Retail and E-commerce</p>
          <p><b>05.</b> Fraud Detection</p>

          <p>
            Our expertise lies in crafting personalized AI and machine learning solutions that address the specific needs of your industry. We deliver sophisticated AI services that enhance business processes, optimize decision-making, and unlock new growth potential. Our team leverages the latest advancements in machine learning to provide solutions ranging from predictive modeling and process automation to actionable data insights and customized recommendations. Transform your operations and stay ahead of the competition with our innovative AI-driven solutions.
          </p>
        </div>
      </div>

      <div className="technology-we-use container">
        <div className="technology-des">
          <h3>What technology do we use for this service</h3>
          <p>
            For our AI and machine learning services, we utilize cutting-edge technologies such as TensorFlow, PyTorch, and Scikit-learn. These advanced tools enable us to build and deploy powerful models that drive actionable insights and automation. Our approach ensures scalable, efficient, and innovative solutions tailored to your business needs.
          </p>
        </div>
        <div className="technology-img">
          <img
            src={'https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/technology.webp'} // Update the path accordingly
            alt=""
            className="img-fluid"
            data-aos="fade-right"
            data-aos-duration="1500"
          />
        </div>
      </div>

      <div className="recent-project container mx-auto">
        <h2>Our Recent Projects</h2>

        <div className="row container mx-auto">
          <div className="col-md-6 col-12">
            <div className="recent-img">
              <img
                src={'https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/1.webp'} // Update the path accordingly
                alt=""
                className="img-fluid"
                data-aos="zoom-in"
                data-aos-duration="1500"
              />
              <div className="recent-img-des">
                <h4>Project Name</h4>
                <p>Description about the project</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="recent-img">
              <img
                src={'https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/2.webp'} // Update the path accordingly
                alt=""
                className="img-fluid"
                data-aos="zoom-in"
                data-aos-duration="1500"
              />
              <div className="recent-img-des">
                <h4>Project Name</h4>
                <p>Description about the project</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row container mx-auto">
          <div className="col-md-6 col-12">
            <div className="recent-img">
              <img
                src={'https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/3.webp'} // Update the path accordingly
                alt=""
                className="img-fluid"
                data-aos="zoom-in"
                data-aos-duration="1500"
              />
              <div className="recent-img-des">
                <h4>Project Name</h4>
                <p>Description about the project</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="recent-img">
              <img
                src={'https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/4.webp'} // Update the path accordingly
                alt=""
                className="img-fluid"
                data-aos="zoom-in"
                data-aos-duration="1500"
              />
              <div className="recent-img-des">
                <h4>Project Name</h4>
                <p>Description about the project</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="client-testimonial container mx-auto py-5">
        <div className="client-testimonial-des">
          <h2>What our clients say about our services</h2>
        </div>
        <div className="client-testimonial">
          <div className="test-img">
            <div className="test-img-des">
              <p>
                The AI/ML services have been a game-changer for our business. The tailored solutions and advanced technology have significantly improved our data analysis and decision-making processes.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="get-in-touch container mx-auto pt-5" data-aos="zoom-in" data-aos-duration="1500">
        <h2>Get in touch</h2>
        <form action="/data" method="POST" >
          <input type="text" name="honeypot" id="honeypot" style={{ display: 'none' }} />
          <div className="row">
            <div className="col-md-6 col-12">
              <label htmlFor="name">Full Name </label><br />
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Your full name"
                required
              />
            </div>
            <input type="hidden" name="source" value="4" />
            <div className="col-md-6 col-12">
              <label htmlFor="email">Your email </label><br />
              <input
                type="email"
                id="email"
                name="email"
                placeholder="example@yourmail.com"
                required
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-6 col-12">
              <label htmlFor="company"> Company </label><br />
              <input
                type="text"
                id="company"
                name="company"
                placeholder="Your company name here"
                required
              />
            </div>
            <div className="col-md-6 col-12">
              <label htmlFor="Subject">Subject </label><br />
              <input
                type="text"
                id="Subject"
                name="Subject"
                placeholder="How can we help"
                required
              />
            </div>
            <div className="col-md-6 col-12">
              <label htmlFor="phone">Mobile Number </label><br />
              <input
                type="text"
                id="phone"
                name="phone"
                placeholder="Your Mobile Number"
                required
              />
            </div>
            <div className="col-md-6 col-12">
              <label htmlFor="city">City</label><br />
              <input
                type="text"
                id="city"
                name="city"
                placeholder="Your City"
                required
              />
            </div>
            <div className="col-md-6 col-12">
              <label htmlFor="postcode">PostCode</label><br />
              <input
                type="text"
                id="postcode"
                name="postcode"
                placeholder="Your PostCode"
                required
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-12 col-12">
              <label htmlFor="message">Message </label><br />
              <textarea
                id="message"
                name="description"
                placeholder="Hello there, I would like to talk about how to..."
                required
              ></textarea>
            </div>
          </div>
          <div className="g-recaptcha" data-sitekey="6Lf9iEYqAAAAAGBkHMfKHyrXoDP3tyX_yZ8cL9b-"></div>
          <button type="submit">Send Message</button>
        </form>
      </div>
      <Pagesblog/>

      <Footer/>
    </div>
  );
};

export default Aiml;
