import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import Pagesblog from "../blog/pagesblog/Pagesblog"
import { Helmet } from 'react-helmet';

const Salesconversion = () => {
  return (
    <div>
       <Helmet>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="keywords"
          content="sales conversion marketing, lead generation, customer acquisition, sales optimization, Vilva Business"
        />
        <meta
          name="description"
          content="Discover Vilva Business's sales conversion marketing strategies for effective lead generation, customer acquisition, and sales optimization. Improve your business's conversion rates with our targeted marketing solutions."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <title>Sales Conversion Marketing - Vilva Business</title>
      </Helmet>
      <Navbar/>
        <div>
      <section
        className="page-title bg-overlay-black-60 jarallax"
        data-speed="0.6"
        style={{
          backgroundImage: 'url(http://staging.vilvabusiness.com/vbtheme/images/marketing/sales.png)',
          height: '80%',
          width: '100%',
          backgroundSize: 'cover',
          backgroundPosition: 'top',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="page-title-name">
                <h1>Sales Conversion Marketing</h1>
                <p>the effectiveness of your sales team at converting leads into new customers.</p>
              </div>
              <ul className="page-breadcrumb">
                <li>
                  <a href="/">
                    <i className="fa fa-home"></i> Home
                  </a>
                  <i className="fa fa-angle-double-right"></i>
                </li>
                <li>
                  <span>Sales Conversion Marketing</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <div className="features-layout2">
        <div className="row mx-auto container">
          <div className="col-12 col-md-4">
            <div className="layout-col-2 mt-3">
              <div className="top-bg"></div>
              <div className="imgforcol-test">
                {/* <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/layout-1.png" alt="" /> */}
              </div>
              <h3>Increased Revenue</h3>
              <p>
                Implement strategies designed to convert leads into paying customers, boosting your overall sales performance.
              </p>
            </div>
          </div>

          <div className="col-md-4 col-12">
            <div className="layout-col-2 mt-3">
              <div className="top-bg-c"></div>
              <div className="imgforcol">
                {/* <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/layout-2.png" alt="" /> */}
              </div>
              <h3>Optimized Sales Funnel</h3>
              <p>
                Streamline your sales process to address pain points and enhance lead nurturing, improving conversion rates.
              </p>
            </div>
          </div>

          <div className="col-md-4 col-12">
            <div className="layout-col-2 mt-3">
              <div className="top-bg"></div>
              <div className="imgforcol-test">
                {/* <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/layout-3.png" alt="" /> */}
              </div>
              <h3>Actionable Insights</h3>
              <p>
                Gain valuable insights into customer behavior and preferences, allowing for more targeted and effective marketing efforts.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="start-project mt-5">
        <div className="row mx-auto">
          <div className="col-12 col-md-6 project">
            <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/project.png" alt="" className="img-fluid" />
          </div>
          <div className="col-12 col-md-6 project-des" style={{ color: '#001e60' }}>
            <div className="project-des-con">
              <h2>Start your project with Us today</h2>
              <p>
                Get started with our cutting-edge e-commerce solutions today and elevate your online business with tailored strategies and innovative technology. Transform your customer experience and drive growth effortlessly!
              </p>
              <div className="layout-btn">
                <button type="button">
                  Start a Project &nbsp;&nbsp;&nbsp; <i className="ri-arrow-right-line"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Pagesblog/>

    <Footer/>
    </div>
  )
}

export default Salesconversion
