import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import Pagesblog from "../blog/pagesblog/Pagesblog"
import { Helmet } from "react-helmet";

const Appecom = () => {
  return (
    <div>
         <Helmet>
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta
        name="keywords"
        content="app for ecommerce, ecommerce app, online store app, digital retail app"
      />
      <meta
        name="description"
        content="Discover our ecommerce management app designed to streamline operations and enhance online sales. Explore how our app can empower your ecommerce business with efficiency and growth."
      />
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      <title>App for Ecommerce - Vilva Business</title>
    </Helmet>
      <Navbar/>
      <div className="prod-main-cont">
  <div className="product-banner">
    <div className="banner-img">
      <div className="prod-des">
        <h2 data-aos="fade-down" data-aos-duration="1000">Apps for ECommerce</h2>
        <p style={{color:'white'}}>
        An Android eCommerce app provides personalized shopping experiences, push notifications for promotions and new arrivals, and easy access to customer support, which can significantly increase user engagement and retention. Additionally, the app's convenience encourages repeat purchases and enhances brand loyalty.
        </p>
      </div>
    </div>
  </div>
  
  <div className="prod-head container mx-auto py-5">
    <div className="prod-head-img" data-aos="zoom-in" data-aos-duration="1500"></div>
    <div className="prod-head-content">
      <h2>
        <span data-aos="fade-up-right" data-aos-duration="1200">Transform Your Business</span> with 
        <span data-aos="fade-up-right" data-aos-duration="1200"> Innovative Android App Solutions</span>
      </h2>
      <p>
        The app offers a streamlined, user-friendly interface for browsing, searching, and purchasing products. Features like one-touch checkout, saved payment methods, and real-time inventory updates improve the shopping experience and reduce cart abandonment. Additionally, the app's convenience encourages repeat purchases and enhances brand loyalty.
      </p>
      <p>
        We offer comprehensive e-commerce website development services to help you build a robust online store. From custom design to seamless functionality and secure payment integration, our solutions are tailored to meet your business needs and drive online success. These solutions enhance user experience, improve functionality, and ensure your app is equipped with the latest technology. Whether you're aiming for superior performance or innovative features, Android app solutions offer the tools needed to bring your vision to life.
      </p>

      <p><b>01.</b> Boosted Customer Engagement</p>
      <p><b>02.</b> Enhanced Shopping Experience</p>
      <p><b>03.</b> Customizable and Flexible Development</p>
      <p><b>04.</b> Integration with Google Services</p>
      <p><b>05.</b> Increased Sales and Loyalty</p>

      <p>
        Our e-commerce website development services are designed to create a powerful online presence for your business. We provide end-to-end solutions, including custom design, user-friendly interfaces, and secure payment integrations. Our team ensures that your online store is not only visually appealing but also optimized for performance and scalability. With our expertise, we help you attract and convert customers, driving growth and success. Trust us to build a robust e-commerce platform that meets your unique business needs.
      </p>
    </div>
  </div>

  <div className="client-testimonial container mx-auto py-5">
    <div className="client-testimonial-des">
      <h2 data-aos="fade-left" data-aos-duration="1200">What our client say about our Android Ecommerce Services.</h2>
    </div>
    <div className="client-testimonial">
      <div className="test-img">
        <div className="test-img-des">
          <p>
            "The Android e-commerce solution transformed our mobile shopping experience. Its seamless integration and user-friendly features have significantly boosted customer engagement and sales."
          </p>
        </div>
      </div>
    </div>
  </div>

  <div className="news-letter container mx-auto my-5 pt-4">
    <div className="news-letter-img pt-5">
      <div className="row">
        <div className="col-md-6 col-12">
          <h3>Start your 7-day free trial</h3>
          <p>Experience seamless functionality with our platform. Effortlessly customize your workflow.</p>
          <div className="news-input" data-aos="fade-right" data-aos-duration="1200">
            <input
              type="text"
              name="email"
              placeholder="Enter your email "
              required
            />
            <button type="button">Start</button>
          </div>
          <div className="radio-btn pt-5">
            <p>
              <i className="fa-solid fa-circle-dot"></i> &nbsp;Free 7-day trial
            </p>
            <p>
              <i className="fa-solid fa-circle-dot"></i>&nbsp;No credit card required
            </p>
            <p>
              <i className="fa-solid fa-circle-dot"></i>&nbsp;Cancel anytime
            </p>
          </div>
        </div>
        <div className="col-6 d-none d-md-block" data-aos="fade-left" data-aos-duration="1500">
          <img className="news-img" src="{{ asset('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/Image (3).webp') }}" alt="" />
        </div>
      </div>
    </div>
  </div>

  <div className="get-in-touch container mx-auto pt-5" data-aos="zoom-in" data-aos-duration="1500">
    <h2>Get in touch</h2>
    <form action="#" method="POST">
      <div className="row">
        <div className="col-md-6 col-12">
          <label htmlFor="name">Full Name *</label> <br />
          <input
            type="text"
            id="name"
            name="name"
            placeholder="  &nbsp; &nbsp;Your full name"
            required
          />
        </div>
        <div className="col-md-6 col-12">
          <label htmlFor="email">Your email *</label> <br />
          <input
            type="email"
            id="email"
            name="email"
            placeholder=" &nbsp; &nbsp;example@yourmail.com"
            required
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-md-6 col-12">
          <label htmlFor="company">Company *</label> <br />
          <input
            type="text"
            id="company"
            name="company"
            placeholder="  &nbsp; &nbsp;your company name here"
            required
          />
        </div>
        <div className="col-md-6 col-12">
          <label htmlFor="subject">Subject *</label> <br />
          <input
            type="text"
            id="subject"
            name="subject"
            placeholder="  &nbsp; &nbsp;How can we Help"
            required
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-md-12 col-12">
          <label htmlFor="message">Message *</label> <br />
          <textarea
            id="message"
            name="message"
            placeholder="  &nbsp; &nbsp;Hello there, I would like to talk about how to..."
            required
          ></textarea>
        </div>
      </div>
      <button type="submit">Send Message</button>
    </form>
  </div>
</div>
<Pagesblog/>

<Footer/>
    </div>
  )
}

export default Appecom
