import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Helmet } from 'react-helmet';

const Returnandrefund = () => {
  return (
    <>
     <Helmet>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="keywords"
          content="SMS marketing, text message campaigns, mobile marketing, customer engagement, SMS solutions"
        />
        <meta
          name="description"
          content="Explore Vilva Business's SMS marketing services. Boost customer engagement and drive results with our targeted SMS marketing strategies."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <title>Return and Refund - Vilva Business</title>
      </Helmet>
    <Navbar/>
    <div>
       <div className="container mx-auto">
      <h1 style={{ color: '#040e56', textAlign: 'center', paddingTop: '90px' }}>
        Return and Refund Policy for Vilva Business
      </h1>
      <br />
      <br />
      <h3 style={{ color: '#040e56', textAlign: 'center' }}>Overview</h3>
      <br />
      <p>
        At Vilva Business, we strive to deliver high-quality IT services, including software development, e-commerce solutions, and SaaS platforms. We are committed to customer satisfaction and aim to address any issues that may arise with our services. This Return and Refund Policy outlines the conditions under which refunds or returns may be provided.
      </p>
      <br />
      <h3 style={{ color: '#040e56' }}>Services Covered</h3>
      <br />
      <p>This policy applies to all services provided by Vilva Business, including:</p>
      <p>Custom Software Development</p>
      <p>E-commerce Solutions</p>
      <p>SaaS Subscriptions and Licenses</p>
      <p>Other IT Services as agreed upon in the service contract</p>
      <br />
      <h3 style={{ color: '#040e56' }}>Eligibility for Refunds</h3>
      <br />
      <p>Refunds may be granted under the following conditions:</p>
      <p>
        <span style={{ fontWeight: 'bolder' }}>Service Non-Delivery: </span> If we fail to deliver the agreed service within the specified timeframe without valid reason, a full or partial refund may be issued.
      </p>
      <p>
        <span style={{ fontWeight: 'bolder' }}>Service Deficiencies: </span> If a service provided does not meet the agreed specifications or standards, you must notify us within 14 days of delivery. We will work to correct the issues, and if we are unable to do so, a refund may be considered.
      </p>
      <p>
        <span style={{ fontWeight: 'bolder' }}>Cancellation by Client: </span> For ongoing services or subscriptions, clients may cancel with prior notice as per the terms of the service agreement. Refund eligibility will be determined based on the terms outlined in the agreement.
      </p>
      <p>
        <span style={{ fontWeight: 'bolder' }}>Technical Failures: </span> In the case of significant technical failures caused by our services, refunds may be considered on a case-by-case basis.
      </p>
      <br />
      <h3 style={{ color: '#040e56' }}>Non-Refundable Circumstances</h3>
      <br />
      <p>Refunds will not be issued under the following conditions:</p>
      <p>
        <span style={{ fontWeight: 'bolder' }}>Change of Mind: </span> Refunds are not provided if you change your mind after services have commenced.
      </p>
      <p>
        <span style={{ fontWeight: 'bolder' }}>Client Delays: </span> Delays caused by the client, including lack of communication, delays in providing necessary information or approvals, or failure to comply with agreed terms.
      </p>
      <p>
        <span style={{ fontWeight: 'bolder' }}>Completed Services: </span> Once a service or project has been completed and delivered as per the agreement, no refunds will be issued.
      </p>
      <p>
        <span style={{ fontWeight: 'bolder' }}>Third-Party Costs:</span> Costs incurred from third-party services or products (e.g., hosting, domain registrations) are non-refundable.
      </p>
      <br />
      <h3 style={{ color: '#040e56' }}>Requesting a Refund</h3>
      <br />
      <p>To request a refund, please contact our support team with the following details:</p>
      <br />
      <p>Your Name and Contact Information</p>
      <p>Service or Project Details</p>
      <p>Reason for the Refund Request</p>
      <p>Any Supporting Documentation or Evidence</p>
      <br />
      <p>
        Email your refund requests to <a href="mailto:info@vilvabusiness.com">info@vilvabusiness.com</a> or contact us at Phone: <a href="tel:+919176569459">+91 91765 69459</a>.
      </p>
      <br />
      <h3 style={{ color: '#040e56' }}>Processing of Refunds</h3>
      <br />
      <p>
        Once your refund request is received, we will review and assess it based on the terms outlined above. You will be notified of the outcome within 14 business days. Approved refunds will be processed within 30 days to the original payment method unless otherwise agreed.
      </p>
      <br />
      <h3 style={{ color: '#040e56' }}>Changes to This Policy</h3>
      <br />
      <p>
        Vilva Business reserves the right to modify this Return and Refund Policy at any time. Changes will be effective immediately upon posting to our website. Continued use of our services after any changes to this policy constitutes acceptance of the new terms.
      </p>
      <br />
      <h3 style={{ color: '#040e56' }}>Contact Us</h3>
      <br />
      <p>If you have any questions or concerns about this Return and Refund Policy, please contact us at:</p>
      <h5 style={{ color: '#040e56' }}>Vilva Business</h5>
      <br />
      <p>
        <span>Email:</span>
        <a href="mailto:info@vilvabusiness.com">info@vilvabusiness.com</a>
      </p>
      <p>
        <span>Phone:</span>
        <a href="tel:+919176569459">+91 91765 69459</a>
      </p>
    </div>
    </div>
    <Footer/>
    </>
  )
}

export default Returnandrefund
