import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import Pagesblog from "../blog/pagesblog/Pagesblog"
import { Helmet } from 'react-helmet';

const Paymentgateway = () => {
  return (
    <div>
      <Helmet>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="keywords"
          content="payment gateway solutions, online payment processing, secure payment gateways"
        />
        <meta
          name="description"
          content="Discover secure and efficient payment gateway solutions with Vilva Business. We provide reliable services for online payment processing and integration of secure payment gateways. Contact us to enhance your payment options."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <title>Payment Gateway Solutions - Vilva Business</title>
      </Helmet>
      <Navbar/>
       <section className="page-title bg-overlay-black-60 jarallax" data-speed="0.6" data-video-src="https://www.youtube.com/watch?v=BePoG2ONkTw">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="page-title-name">
                <h1>Payment Gateway Solutions</h1>
                <p>We know the secret of your success</p>
              </div>
              <ul className="page-breadcrumb">
                <li><a href="/"><i className="fa fa-home"></i> Home</a> <i className="fa fa-angle-double-right"></i></li>
                <li><span>Payment Gateway Solutions</span></li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <div className="features-layout2">
        <div className="row mx-auto container">
          <div className="col-12 col-md-4">
            <div className="layout-col-2 mt-3">
              <div className="top-bg"></div>
              <div className="imgforcol-test">
                {/* <img src='https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/layout-1.png'alt="" /> */}
              </div>
              <h3>Seamless Integration and User Experience</h3>
              <p>Our payment gateway solutions offer smooth integration with your existing systems and provide a user-friendly interface, ensuring a hassle-free payment experience for your customers and minimizing cart abandonment.</p>
            </div>
          </div>
          <div className="col-md-4 col-12">
            <div className="layout-col-2 mt-3">
              <div className="top-bg-c"></div>
              <div className="imgforcol">
                {/* <img src='https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/layout-2.png'alt="" /> */}
              </div>
              <h3>Robust Security and Fraud Prevention</h3>
              <p>We prioritize security with advanced encryption and fraud detection tools, protecting sensitive customer data and reducing the risk of fraudulent transactions, which enhances trust and compliance with industry standards.</p>
            </div>
          </div>
          <div className="col-md-4 col-12">
            <div className="layout-col-2 mt-3">
              <div className="top-bg"></div>
              <div className="imgforcol-test">
                {/* <img src='https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/layout-3.png'alt="" /> */}
              </div>
              <h3>Global Reach and Flexibility</h3>
              <p>Our payment gateway supports multiple currencies and payment methods, enabling you to cater to a global audience and adapt to diverse payment preferences, which can drive international sales and broaden your market reach.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="start-project mt-5">
        <div className="row mx-auto">
          <div className="col-12 col-md-6 project">
            <img src='https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/project.png'alt="" className="img-fluid" />
          </div>
          <div className="col-12 col-md-6 project-des" style={{ color: '#001e60' }}>
            <div className="project-des-con">
              <h2>Start your project with Us today</h2>
              <p>Get started with our cutting-edge e-commerce solutions today and elevate your online business with tailored strategies and innovative technology. Transform your customer experience and drive growth effortlessly!</p>
              <div className="layout-btn">
                <button type="button">Start a Project &nbsp;&nbsp;&nbsp; <i className="ri-arrow-right-line"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Pagesblog/>

      <Footer/>
    </div>
  )
}

export default Paymentgateway
