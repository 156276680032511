import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import Pagesblog from "../blog/pagesblog/Pagesblog"
import { Helmet } from 'react-helmet';

const Leadgeneration = () => {
  return (
    <div>
       <Helmet>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="keywords"
          content="lead generation marketing, customer acquisition, sales leads, business growth, Vilva Business"
        />
        <meta
          name="description"
          content="Discover Vilva Business's lead generation marketing strategies for effective customer acquisition and sales lead generation. Enhance your business growth with our targeted marketing solutions."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <title>Lead Generation Marketing - Vilva Business</title>
      </Helmet>
      <Navbar/>
      <body>
  <section
    className="page-title bg-overlay-black-60 jarallax"
    data-speed="0.6"
    style={{
      backgroundImage: "url(http://staging.vilvabusiness.com/vbtheme/images/marketing/ls.png)",
      height: "80%",
      width: "100%",
      backgroundSize: "cover",
      backgroundPosition: "top",
      backgroundRepeat: "no-repeat",
    }}
  >
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="page-title-name">
            <h1>Lead Generation Marketing</h1>
            <p>
              Attracting prospects to your business and increasing their
              interest through nurturing, all with the end goal of converting
              them into a customer.
            </p>
          </div>
          <ul className="page-breadcrumb">
            <li>
              <a href="/">
                <i className="fa fa-home"></i> Home
              </a>{" "}
              <i className="fa fa-angle-double-right"></i>
            </li>
            <li>
              <span>Lead Generation Marketing</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>

  <div className="prod-des container">
    <div className="prod-head">
      <h5 style={{ color: "#4a12f0" }}>Lead Generation Marketing</h5>
      <h2 style={{ color: "#001e60" }}>Convert Interest into Opportunity</h2>
    </div>
    <div className="benifits mx-auto">
      <div className="col-md-3 col-12 ben-ai">
        <img
          src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/growsuite.png"
          alt=""
        />
        <h3 style={{ color: "#001e60" }}>Convert Interest into Opportunity</h3>
        <p>
          Our platform enables you to define and refine your target audience
          based on a range of parameters such as industry, job role, company
          size, geographical location, and behavioral traits. This granular
          approach allows you to identify and engage with prospects who are
          most likely to be interested in your products or services.
        </p>
      </div>
      <div className="col-md-3 col-12 ben-ai">
        <img
          src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/growsuiteimage.png"
          alt=""
        />
        <h3 style={{ color: "#001e60" }}>Automated Processes</h3>
        <p>
          Automation tools are essential for streamlining and optimizing the
          lead generation process. By leveraging automation, you can reduce
          manual effort, increase efficiency, and scale your lead generation
          efforts more effectively.
        </p>
      </div>
      <div className="col-md-3 col-12 ben-ai">
        <img
          src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/growsuiteimages.png"
          alt=""
        />
        <h3 style={{ color: "#001e60" }}>Increased Conversion Rates</h3>
        <p>
          Our platform incorporates advanced tools and techniques to boost lead
          quality. For example, through sophisticated lead scoring and
          qualification processes, you can assess and prioritize leads based on
          their likelihood to convert. This ensures that your sales team focuses
          on the most promising prospects.
        </p>
      </div>
    </div>
  </div>

  <div className="usecase-all">
    <div className="usecase-head">
      <h5 style={{ color: "#4a12f0" }}>Key Use Cases</h5>
      <h2 style={{ color: "#001e60" }}>Manage Every Endpoint in One Place</h2>
      <h5 style={{ color: "#28334a" }}>Target your audience to reach them</h5>
    </div>
    <div className="row container pt-5 mx-auto">
      <div className="col-md-6 col-12">
        <div className="useimg">
          <img
            src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/post.jpg"
            alt=""
            style={{ height: "100%", width: "90%" }}
          />
        </div>
      </div>
      <div className="col-12 col-md-6 mt-5">
        <div className="row" id="row1">
          <h3>Email Campaigns</h3>
          <p>Engage prospects with tailored email marketing.</p>
        </div>

        <div className="row" id="row3">
          <h3>Content Marketing</h3>
          <p>Attract leads through valuable content and resources.</p>
        </div>

        <div className="row" id="row">
          <h3>Webinars and Events</h3>
          <p>Generate leads by hosting educational and interactive events.</p>
        </div>

        <div className="row" id="row4">
          <h3>Social Media Ads</h3>
          <p>Drive leads through targeted social media advertising.</p>
        </div>
        <div className="row" id="row4">
          <h3>Landing Pages</h3>
          <p>Optimize landing pages to capture high-quality leads.</p>
        </div>
      </div>
    </div>
  </div>

  <div className="ready mt-5 pb-5 container">
    <div className="ready-des">
      <h3 style={{ color: "#001e60" }}>Ready to Get Started?</h3>
      <h5 style={{ color: "#28334a" }}>Experience device management, made easy!</h5>
      <div className="contact-us">
        <button type="button">
          Contact Us <i className="fa-solid fa-arrow-right"></i>
        </button>
      </div>
    </div>
  </div>
  <Pagesblog/>

</body>
<Footer/>

    </div>
  )
}

export default Leadgeneration
