import React from "react";
import { useState } from "react";
import "./contact.css";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { FaInstagram, FaFacebook, FaTwitter, FaLinkedin } from "react-icons/fa";
import { MdCall } from "react-icons/md";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSomeIcon } from "@fortawesome/free-solid-svg-icons"; // Replace 'faSomeIcon' with the specific icon you're using
import { faMapPin,faMobile,faEnvelope,faPhone,faLocationDot} from "@fortawesome/free-solid-svg-icons";
import { Helmet } from 'react-helmet';

const ContactPage = () => {
  const handleFocus = (e) => {
    e.target.parentNode.classList.add("focus");
    e.target.parentNode.classList.add("not-empty");
  };

  const handleBlur = (e) => {
    if (e.target.value === "") {
      e.target.parentNode.classList.remove("not-empty");
    }
    e.target.parentNode.classList.remove("focus");
  };

  const validateRecaptcha = () => {
    const response = window.grecaptcha.getResponse();
    if (response.length === 0) {
      alert("Please verify you are not a robot.");
      return false;
    }
    return true;
  };

  return (
    <div>
       <Helmet>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="keywords"
          content="contact us, business contact, customer support, reach us, Vilva Business contact"
        />
        <meta
          name="description"
          content="Contact Vilva Business for inquiries, customer support, or partnership opportunities. We are here to assist you with our comprehensive business services. Reach out to us for any queries or collaboration proposals."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <title>Contact Us - Vilva Business</title>
      </Helmet>
      <Navbar />
      <main>
        <section className="contact container mb-5 py-5 mt-5">
          <div className="container-contact d-block d-md-flex container">
            <div className="left me-0 me-md-5 mb-5 mb-md-0">
              <div className="form-wrapper">
                <div className="contact-heading">
                  <h1 style={{ color: "#040E56" }}>Let's work together</h1>
                  <p className="text">
                    Or reach us via:{" "}
                    <a href="mailto:info@vilvabusiness.com">
                      info@vilvabusiness.com
                    </a>
                  </p>
                </div>
                <form
                  action="/contact"
                  method="post"
                  className="contact-form"
                  onSubmit={validateRecaptcha}
                >
                  <input type="hidden" name="source" value="2" />

                  <input
                    type="text"
                    name="honeypot"
                    id="honeypot"
                    style={{ display: "none" }}
                  />
                  <div className="input-wrap">
                    <input
                      className="contact-input mb-3"
                      autoComplete="off"
                      type="text"
                      name="name"
                      id="name"
                      required
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="name">Name</label>
                    <i
                      className="icon fa-solid fa-address-card"
                      style={{ color: "#040E56" }}
                    ></i>
                  </div>
                  <div className="input-wrap w-100">
                    <input
                      className="contact-input mb-3"
                      autoComplete="off"
                      type="text"
                      name="email"
                      id="email"
                      required
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="email">Email</label>
                    <i
                      className="icon fa-solid fa-envelope"
                      style={{ color: "#040E56" }}
                    ></i>
                  </div>

                  <div className="input-wrap">
                    <input
                      type="tel"
                      id="phone"
                      name="phone"
                      className="contact-input mb-3"
                      required
                      pattern="[0-9]{10}"
                      title="Please enter a valid 10-digit mobile number"
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="phone">Phone</label>
                    <i
                      className="icon fa-solid fa-mobile"
                      style={{ color: "#040E56" }}
                    ></i>
                  </div>

                  <div className="input-wrap w-100">
                    <textarea
                      name="message"
                      autoComplete="off"
                      className="contact-input mb-3"
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    ></textarea>
                    <label htmlFor="message">Message</label>
                    <i
                      className="icon fa-solid fa-message"
                      style={{ color: "#040E56" }}
                    ></i>
                  </div>
                  <div
                    className="g-recaptcha"
                    data-sitekey="6Lf9iEYqAAAAAGBkHMfKHyrXoDP3tyX_yZ8cL9b-"
                  ></div>

                  <div>
                    <button className="contact-buttons w-50">Submit</button>
                  </div>
                </form>
              </div>
            </div>
            <div className="right ms-0 ms-md-5">
              <div className="info-wrapper">
                <h1 style={{ color: "#040E56" }}>Contact us</h1>
                <div className="conatct-details pb-5">
                  {/* <i className="fa-solid fa-phone me-3"></i> */}
                  {/* <MdCall className="" /> */}
                  <FontAwesomeIcon icon={faPhone} style={{ color: '#007E60', fontSize: '24px', marginRight: '10px' }} />

                  <br />
                  <br />
                  <p>
                    Speak directly with our experts by calling us at 9176569459.
                    Our lines are open from 9.30 to 7.30, Monday through Friday.
                  </p>
                </div>
                <div className="conatct-details">
                  {/* <i className="fa-solid fa-location-dot me-3"></i> */}
                  {/* <FontAwesomeIcon icon={faLocationDot} /> */}
                  <FontAwesomeIcon icon={faLocationDot} style={{ color: '#007E60', fontSize: '24px', marginRight: '10px' }} />
                   <br />
                  <br />
                  <p>
                    Visit us at our office located at: 9/11 Narayanaswany
                    Garden, 3rd Main Road Kodungaiyur, 600118 Chennai India. Our
                    doors are open from 9.30 to 7.30, and we’d be happy to meet
                    with you in person.
                  </p>
                </div>
                {/* <div className="icons-form">
                  <i className="fa-brands fa-instagram"></i>
                  <i className="fa-brands fa-facebook"></i>
                  <i className="fa-brands fa-x-twitter"></i>
                  <i className="fa-brands fa-linkedin"></i>
                </div> */}
                <div className="icons-form">
                  <FaInstagram className="i" />
                  <FaFacebook className="i" />
                  <FaTwitter className="i" />
                  <FaLinkedin className="i" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <section className="contact-4 page-section-ptb clearfix o-hidden">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 sm-mb-30">
              <blockquote className="blockquote quote ps-0">
                To Get in Communication with Your Dealer.
              </blockquote>
              <p>
                It would be great to hear from you! If you got any questions,
                please do not hesitate to send us a message. We are looking
                forward to hearing please do not hesitate to send us a message.
                It would be great to hear from you! If you got any questions,
                please do not hesitate to send us a message.
              </p>
              <p>
                We are looking forward to hearing please do not hesitate to send
                us a message. We are looking forward to hearing from you!
              </p>
            </div>
          </div>
          <div className="row mt-50">
            <div className="col-12">
              <img
                className="img-fluid mx-auto"
                src="/vbtheme/images/marketing/world.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <section className="page-section-ptb contact-2 gray-bg">
        <div className="container">
          <div className="row mb-30">
            <div className="col-12">
              <div className="office-1 p-5 white-bg">
                <h2 className="mb-30">India</h2>
                <div className="touch-in">
                  <div className="row">
                    <div className="col-lg-4 col-md-4 mb-4 mb-md-0">
                      <div className="media d-flex">
                        <div className="feature-icon media-icon me-4">
                          <span className="ti-map-alt theme-color"></span>
                          <FontAwesomeIcon icon={faMapPin}     style={{ color: '#007E60', fontSize: '44px', marginRight: '10px' }} 
 />
                        </div>
                        <div className="media-body">
                          <h5 className="text-back">ADDRESS</h5>
                          <p className="mb-0">Chennai, Tamil Nadu, India</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 mb-4 mb-md-0">
                      <div className="media d-flex">
                        <div className="feature-icon media-icon me-4">
                          {/* <span className="ti-mobile theme-color"></span> */}
                          <FontAwesomeIcon icon={faMobile}   style={{ color: '#007E60', fontSize: '44px', marginRight: '10px' }}  />
                        </div>
                        <div className="media-body">
                          <h5 className="text-back">PHONE</h5>
                          <a href="tel:919791136945">
                            <span style={{ color: "black" }}>
                              +91 91765 69459
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                      <div className="media d-flex">
                        <div className="feature-icon media-icon me-4">
                          {/* <span className="ti-email theme-color"></span> */}
                          <FontAwesomeIcon icon={faEnvelope}  style={{ color: '#007E60', fontSize: '44px', marginRight: '10px' }} />
                        </div>
                        <div className="media-body">
                          <h5 className="text-back">EMAIL</h5>
                          <p className="mb-0">info@vilvabusiness.com</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default ContactPage;
